import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';
import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';
import { FilialService } from 'src/app/filiais/shared/filial.service';
import { PrevisaoEntregaComponent } from 'src/app/tipo-entregas/previsao-entrega-modal/previsao-entrega.component';
import { TipoEntregaService } from 'src/app/tipo-entregas/shared/tipo-entrega.service';
import { ProdutoAvisoService } from '../../../produtos/produto-aviso/shared/produto-aviso.service';
import { ProdutoAviso } from '../../../produtos/produto-aviso/shared/produto_avisos';
import { Oferta } from '../../../produtos/shared/ofertas';
import { CarrinhoAnonimoService } from '../shared/carrinho-anonimo.service';
import { CarrinhoService } from '../shared/carrinho.service';
import { TipoProdutoCarrinho } from '../shared/tipo-produto-carrinho';
import { ProdutoDetalheService } from 'src/app/produtos/produto-detalhe/shared/produto-detalhe.service';
import { Produto } from 'src/app/produtos/shared/produto';
import { DepartamentoService } from 'src/app/shared/services/departamento.service';
import { Departamento } from 'src/app/produtos/produto-departamento/shared/departamentos';

@Component({
  selector: 'app-carrinho-adicionar',
  templateUrl: './carrinho-adicionar.component.html',
  styleUrls: ['./carrinho-adicionar.component.css'],
  providers: [ProdutoAvisoService, CookieService, ProdutoDetalheService],
})
export class CarrinhoAdicionarComponent implements OnInit {
  @Input() produtoId: number;
  @Input() disponivel: boolean = false;
  @Input() tipoProduto: TipoProdutoCarrinho = 'produto';

  @Input() quantidade: number = 1;
  @Input() quantidadeMaxima: number;

  @Input() emOferta: boolean = false;
  @Input() tipoOferta: number = 0;
  @Input() quantidadeMaximaOferta: number = 0;

  @Input() appMtOrigin: string;
  @Input() qtdVolumePrincipal = 0;
  @Input() unidadeSigla: string;
  @Input() enableBtnVolume = true;

  @Output() adsClick: EventEmitter<Produto> = new EventEmitter;

  public produtoAviso: ProdutoAviso = {} as ProdutoAviso;
  public oferta: Oferta = {} as Oferta;
  public online: boolean = false;
  public quantidadeMaximaPermitida: number;
  public produto: Produto;
  public departamento: Departamento;
  public secao: any;
  showLoading: boolean = false;

  constructor(
    private carrinhoService: CarrinhoService,
    private produtoAvisoService: ProdutoAvisoService,
    private cdService: CentroDistribuicaoService,
    private filialService: FilialService,
    private modalService: BsModalService,
    private tipoEntregaService: TipoEntregaService,
    private carrinhoAnonimoService: CarrinhoAnonimoService,
    private departamentoService: DepartamentoService,
    private produtoDetalheService: ProdutoDetalheService,
  ) {}

  public ngOnInit() {
    this.quantidadeMaximaPermitida = this.calculaQuantidadeMaximaPermitida();
  }

  public solicitarProduto() {
    const salvar$ = this.produtoAvisoService
      .salvar(this.cdService.id, this.produtoId)
      .subscribe((res) => {
        if (res.success === true) {
          alert('Solicitação enviada com sucesso.');
        } else if (res.error) {
          alert(res.error);
        }
        salvar$.unsubscribe();
      });
  }

  showAvailableWarn() {
    return this.carrinhoService.logged;
  }

  getActionLabel() {
    let label = 'Carrinho Adicionar';
    if (this.appMtOrigin) {
      label = `Carrinho Adicionar > ${this.appMtOrigin}`;
    }
    return label;
  }

  comprar() {
    this.adsClick.emit()
    if (this.errorQtMax()) {
      this.quantidade = this.quantidadeMaximaPermitida;
      this.carrinhoService.showMsgErrorQtMax();
    }

    if (this.filialService.alertaFilialSemEcommerce()) {
      return;
    }

    if (
      (
        !this.carrinhoService.possuiCarrinho &&
        !this.cdService.loadingModalSelectCd &&
        this.modalService.getModalsCount() === 0
      ) ||
      (
        !this.carrinhoService.possuiCarrinho &&
        !this.cdService.loadingModalSelectCd &&
        !this.carrinhoAnonimoService.permitirCarrinhoAnonimo
      )
    ) {
      this.cdService.loadingModalSelectCd = true;
      const modalRef = this.cdService.abrirSelecionarLoja();
      modalRef.content.onClose.subscribe(() => {
        this.cdService.loadingModalSelectCd = false;
        this.validateLoading();
        this.additem();
      });
    } else {
      this.validateLoading();
      this.additem();
    }
  }

  additem() {
    this.carrinhoService.addItem(
      this.cdService.id,
      this.produtoId,
      this.quantidade,
      this.tipoProduto
    );

    this.buscaProdutoDetalhe(this.cdService.id, this.produtoId);
  }

  private buscaProdutoDetalhe(cdId, produtoId) {
    this.produtoDetalheService.detalhe(cdId, produtoId).subscribe(
      (req: any) => {
        this.produto = req.data.produto;
        let cmId = req.data.produto.classificacao_mercadologica_id;
        this.departamento = this.departamentoService.findDepartamentoBySecaoId(cmId);
        this.secao = this.departamentoService.findSecaoBySecaoId(this.departamento, cmId);
        this.addCartGoogleAnalytics(produtoId);
      }
    );
  }

  private addCartGoogleAnalytics(produtoId) {
    if(this.filialService.detail.ga4) {
      let itens: Array<any> = [];

      let add = {
        item_id: produtoId,
        item_name: this.produto.descricao,
        item_category: this.departamento.descricao,
        item_category2: this.secao.descricao,
        price: null,
        discount: null,
        quantity: this.quantidade
      }

      if (this.produto.em_oferta) {
        add.price = this.produto.oferta.preco_oferta
        add.discount = this.produto.oferta.preco_antigo - this.produto.oferta.preco_oferta;
      }

      itens.push(add);

      gtag('event', 'add_to_cart', {
        currency: 'BRL',
        value: add.price ? (add.price * this.quantidade) : (this.produto.preco * this.quantidade),
        items: itens
      });
    }
  }

  private validateLoading() {
    this.showLoading = true;
    this.carrinhoService.afterAddCarrinho$.pipe(take(1)).subscribe(() => {
      this.showLoading = false;
      this.exibirAlertaPrevisaoEntrega();
    });
  }

  public exibirAlertaPrevisaoEntrega() {
    if (this.tipoEntregaService.disponibilidade.exibirAlerta) {
      let modalRef = this.modalService.show(PrevisaoEntregaComponent, {
        keyboard: true,
        class: 'custom-modal w400',
        ignoreBackdropClick: false,
      });
      this.tipoEntregaService.disponibilidade.exibirAlerta = false;
    }
  }

  private calculaQuantidadeMaximaPermitida() {
    return this.carrinhoService.calcQtMaxAdd(
      this.quantidadeMaxima,
      this.emOferta,
      this.tipoOferta,
      this.quantidadeMaximaOferta
    );
  }

  private errorQtMax() {
    if (!this.quantidadeMaximaPermitida) {
      return false;
    }

    return Number(this.quantidade) > this.quantidadeMaximaPermitida;
  }

  comprarVolume() {
    this.qtdVolumePrincipal;
    if ((this.quantidade + this.qtdVolumePrincipal <= this.quantidadeMaxima) || this.quantidadeMaxima === -1) {
      this.quantidade += this.qtdVolumePrincipal;
    } else {
      this.quantidade += this.quantidadeMaxima;

      alert('Quantidade superior ao máximo disponível');
    }
  }
}
