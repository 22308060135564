<ul [ngClass]="ulClass">
    <li [ngClass]="liClass" *ngFor="let pagina of paginas">
        <ng-container *ngIf="pagina.tipo == TipoPagina.INTERNA || pagina.tipo == TipoPagina.IFRAME; else externa">
            <a href="javascript:void(0)" routerLink="/institucional/paginas/{{ pagina.slug }}" [ngClass]="aClass">
                {{ pagina.subtitulo }}
            </a>
        </ng-container>
        <ng-template #externa>
            <a [href]="(pagina.link | match:'^(?:f|ht)tps?://':'i') ? pagina.link : 'https://' + pagina.link" [ngClass]="aClass" target='_blank' rel='noopener noreferrer'>
                {{ pagina.subtitulo }}
            </a>
        </ng-template>
    </li>
</ul>
