import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AplicativoService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.request = this.httpClient.get(`${environment.apiUrl}/build/app/configuracao/${environment.filialId}`);
    }
    getInfoAplicativo() {
        if (this.requestData && 'success' in this.requestData) {
            return of(this.requestData);
        }
        return this.request.pipe(map((res) => {
            this.requestData = res;
            return res;
        }));
    }
}
AplicativoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AplicativoService_Factory() { return new AplicativoService(i0.ɵɵinject(i1.HttpClient)); }, token: AplicativoService, providedIn: "root" });
