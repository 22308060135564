<div class="modal-minhas-listas-share">
    <i class="fa fa-close modal-minhas-listas-share__close" aria-hidden="true" (click)="close()"></i>
    <header class="title">
        <h4>{{title}}</h4>
    </header>
    <section class="modal-minhas-listas-share__section" id="copy">Olá, gostaria de compartilhar com você a lista {{list.nome}}.Acesse o link para mais detalhes: {{url + list.slug}}</section>
    <footer class="modal-minhas-listas-share__footer">
        <button type="button" (click)="shareWhatsApp()" class="btn btn-success button hidden-lg hidden-md">
            <i class="fa fa-whatsapp" aria-hidden="true"></i>
            <span> WhatsApp</span>
        </button>
        <button type="button" (click)="copyText()" class="btn btn-checkout button">
            <i class="fa fa-copy" aria-hidden="true"></i>
            <span id="copyBtnLabel"> Copiar</span>
        </button>
    </footer>
</div>