import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { BsModalService } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CepService } from '../../ceps/shared/cep.service';
import { AlterarLojaComponent } from '../alterar-loja/alterar-loja.component';
import { AlterarLojaService } from './alterar-loja.service';
import { Cd } from './cd';
import { MensagemAlteracaoCd } from './MensagemAlteracaoCd';

@Injectable({
  providedIn: 'root',
})
export class CentroDistribuicaoService {
  private _visibilidadeCd$: Subject<any> = new Subject();
  public visibilidadeCd$: Observable<
    any
  > = this._visibilidadeCd$.asObservable();

  private _selecionarLoja$: Subject<any> = new Subject();
  public selecionarLoja$: Observable<
    any
  > = this._selecionarLoja$.asObservable();

  private _alterarParaCd$: Subject<any> = new Subject();
  public alterarParaCd$: Observable<any> = this._alterarParaCd$.asObservable();

  private _cdSelecionado: Cd = {} as Cd;
  get cdSelecionado(): Cd {
    if (!('id' in this._cdSelecionado)) {
      this._cdSelecionado = this.lsService.get('loja');
    }

    return this._cdSelecionado;
  }

  get id() {
    return this.cdSelecionado.id;
  }

  get lojaJaSelecionada() {
    return this.lsService.keys().indexOf('cdSelecionado') > -1;
  }

  get cdConfirmado() {
    return (
      (this._possuiVarios && this.lsService.get('cdConfirmado')) ||
      !this._possuiVarios
    );
  }

  private _possuiVarios = false;
  get possuiVarios(): boolean {
    return this._possuiVarios;
  }

  private _loadingModalSelectCd: boolean = false;

  constructor(
    private modalService: BsModalService,
    private lsService: LocalStorageService,
    private alterarLojaService: AlterarLojaService,
    private httpClient: HttpClient,
    private cepService:CepService
  ) {
    this.alterarLojaService.depoisSelecionarLoja$.subscribe((infoCd) => {
      if (infoCd.lojaAlterada) {
        this._cdSelecionado = infoCd.lojaDestino;
        this._alterarParaCd$.next(infoCd);
      }

      this._selecionarLoja$.next(infoCd);
    });
  }

  get loadingModalSelectCd(): boolean {
    return this._loadingModalSelectCd;
  }

  set loadingModalSelectCd(value: boolean) {
    this._loadingModalSelectCd = value;
  }

  public abrirSelecionarLoja(ignoreBackdrop: boolean = false) {
    return this.modalService.show(AlterarLojaComponent, {
      keyboard: !ignoreBackdrop,
      ignoreBackdropClick: ignoreBackdrop,
      class: 'modal-loja-selector',
    });
  }

  public countCds() {
    const URI = `loja/centros_distribuicoes/filial/${environment.filialId}/possui_varios`;
    this.httpClient
      .get(`${environment.apiUrl}/${URI}`)
      .subscribe((res: any) => {
        this._possuiVarios = res.data;
        this._visibilidadeCd$.next(this._possuiVarios);
      });
  }

  public buscaInfoCd(cdId: number) {
    const URI = `loja/centros_distribuicoes/${cdId}`;
    return this.httpClient.get(`${environment.apiUrl}/${URI}`);
  }

  public buscaEAlteraCd(cdId: number) {
    const buscaCd$ = this.buscaInfoCd(cdId).subscribe((res: any) => {
      buscaCd$.unsubscribe();
      this.alterarLojaService.salvarLojaSelecionada(res.data);
    });
  }

  public buscaEAlteraCdPorPontoRetirada(tipoBusca:'cep'|'codigo_ibge',codigoBusca:number) {
    if(tipoBusca == 'cep'){
      this.cepService.validaCepCd(codigoBusca).subscribe((req: any) => {
        if (req.data.length === 1) {
          const cd = req.data[0];
          return this.alterarLojaService.salvarLojaSelecionada(cd);
        }

        return alert('Não possuímos CD para retirada no endereço selecionado.');
      });
    }else if(tipoBusca == 'codigo_ibge'){
      return alert('A busca por código IBGE ainda não se encontra disponível');
    }
  }

  public buscaEAlteraCdPorAreaEntrega(tipoBusca:'cep'|'codigo_ibge',codigoBusca:number) {
    if(tipoBusca == 'cep'){
      this.cepService.validaCepCd(codigoBusca).subscribe((req: any) => {
        if (req.data.length === 1) {
          const cd = req.data[0];
          return this.alterarLojaService.salvarLojaSelecionada(cd);
        }

        return alert('Não realizamos entrega no endereço selecionado.');
      });
    }else if(tipoBusca == 'codigo_ibge'){
      return alert('A busca por código IBGE ainda não se encontra disponível');
    }
  }

  public alterarParaCd(cd: Cd, montarCarrinho: boolean = true) {
    this.alterarLojaService.salvarLojaSelecionada(cd, montarCarrinho);
  }

  public buscaCdPadrao(cdId?: number) {
    let urlComCd = '';
    if (cdId) {
      urlComCd = `/atual/${cdId}`;
    }

    const URI = `centros_distribuicoes/filial/${environment.filialId}${urlComCd}/padrao`;
    return this.httpClient.get(`${environment.apiUrl}/loja/${URI}`);
  }

  public exibirSelecaoCdAoIniciar() {
    const URI = `centros_distribuicoes/exibir_selecao`;
    return this.httpClient
      .get(`${environment.apiUrl}/loja/${URI}`)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res.data && this._possuiVarios) {
          this.abrirSelecionarLoja(true);
        }
      });
  }

  public confirmarAlteracaoCd(msg = MensagemAlteracaoCd.MENSAGEM_PADRAO) {
    return this.alterarLojaService.confirmarAlteracaoCd(msg);
  }
}
