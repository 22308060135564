import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpProduto } from '../../shared/httpProduto';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProdutoDetalheService {
  constructor(private httpClient: HttpClient) {}

  detalhe(cdId: number, produtoId: Number) {
    const URI = `produtos/${produtoId}/filial/${environment.filialId}/centro_distribuicao/${cdId}/detalhes`;
    return this.httpClient.get(`${environment.apiUrl}/loja/${URI}`);
  }

  getAssociados(produtoId: number, cdId: number): Observable<HttpProduto> {
    const URI = `produtos/${produtoId}/filial/${environment.filialId}/centro_distribuicao/${cdId}/associados`;
    return this.httpClient.get<HttpProduto>(
      `${environment.apiUrl}/loja/${URI}`
    );
  }

  getComparacao(produtoId: number, cdId: number): Observable<HttpProduto> {
    const URI = `produtos/${produtoId}/filial/${environment.filialId}/centro_distribuicao/${cdId}/comparacao`;
    return this.httpClient.get<HttpProduto>(
      `${environment.apiUrl}/loja/${URI}`
    );
  }

  getSimilares(produtoId: number, cdId: number): Observable<HttpProduto> {
    const URI = `produtos/${produtoId}/filial/${environment.filialId}/centro_distribuicao/${cdId}/similares`;
    return this.httpClient.get<HttpProduto>(
      `${environment.apiUrl}/loja/${URI}`
    );
  }

  getCombos(produtoId: number, cdId: number): Observable<HttpProduto> {
    const URI = `produtos/${produtoId}/filial/${environment.filialId}/centro_distribuicao/${cdId}/combos_pertencente`;
    return this.httpClient.get<HttpProduto>(
      `${environment.apiUrl}/loja/${URI}`
    );
  }

  estatisticaVisualizacao(produtoId: number) {
    const URI = `estatistica/produto/${produtoId}/visualizacao`;
    const estatistica$ = this.httpClient
      .post<any>(`${environment.apiUrl}/loja/${URI}`, {})
      .subscribe(() => {
        estatistica$.unsubscribe();
      });
  }
}
