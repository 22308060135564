import {Injectable} from '@angular/core';
import {SolicitacaoCep} from "./solicitacao_ceps";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable()
export class SolicitacaoCepService {

    public url: string = '';
    public service: string = "/loja/solicitacao_ceps";
    public data: SolicitacaoCep[] = [];
    public detail: SolicitacaoCep = <SolicitacaoCep>{};

    constructor(private httpClient: HttpClient) {
        this.url = environment.apiUrl;
    }

    add(data: SolicitacaoCep) {
        return this.httpClient.post(this.url + this.service, data);
    }

}
