import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "angular-2-local-storage";
import { environment } from '../../../environments/environment';
import { Iframe } from "./iframe.interface";

@Injectable()
export class IframeService {

    private readonly URI = `loja/injecao_iframe/filial/${environment.filialId}/centro_distribuicao`;

    constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService) { }

    public getUrlIframe(cdId: number) {
        return this.httpClient.get(`${environment.apiUrl}/${this.URI}/${cdId}/informacoes`);
    }

    public setIframeStorage(iframeData) {
        this.localStorageService.set('iframe', iframeData);
    }

    public getIframeStorage(): Iframe {
        return this.localStorageService.get('iframe');
    }

    public removeIframeStorage() {
        this.localStorageService.remove('iframe');
    }

}