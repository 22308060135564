<div class="container-fluid hidden-xs hidden-sm vip-header">
  <div class="row">
      <div class="col-sm-12">
          <div class="container hidden-xs">
              <div class="row">
                  <div class="col-sm-3 header-logo">
                      <a *ngIf="!televendas" routerLink="/"><img [src]="logo" alt="Página inicial"></a>
                      <a *ngIf="televendas" routerLink="/"><img [src]="logo" alt="Página inicial"></a>
                  </div>
                  <div class="col-sm-9">
                      <form class="navbar-form search" (submit)="realizarBusca();">
                          <div class="form-group">
                              <input type="text" [(ngModel)]="q" [ngModelOptions]="{standalone: true}" class="form-control" [placeholder]="placeholder" (input)="quickSearch()" (focus)="showQuickSearch()" id="inputBuscaRapida">
                          </div>
                          <button type="submit" class="btn btn-search"><i class="fa fa-search" aria-hidden="true"></i> Buscar</button>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<hr />
