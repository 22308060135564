<div class="modal-loja">
  <div class="header">
    <div class="title">Compra iniciada em outra loja</div>
  </div>
  <div class="body">
    <div class="text"><p>A loja que você está acessando agora tem limite de <strong style="font-size: large;">{{ limite_carrinho.limiteCarrinho }} itens</strong> no carrinho, mas seu carrinho tem <strong style="font-size: large;">{{ limite_carrinho.totalItensLimite }} itens</strong>. Além disso, ao mudar de loja, pode haver alterações nos preços e disponibilidade dos produtos.</p></div>
    <div class="text"><p>O que deseja fazer?</p></div>
    <div class="hidden-xs">
      <div class="pull-left">
        <button class="btn btn-primary" (click)="limparCarrinho()">
          Limpar o carrinho e recomeçar <br>a compra na loja atual
        </button>
      </div>
      <div class="pull-right">
        <button class="btn btn-default" (click)="voltarLoja()">
          Voltar à outra loja e ajustar <br>o carrinho antes
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="visible-xs-block text-center">
      <div class="center-block">
        <button class="btn btn-primary" (click)="limparCarrinho()">
          Limpar o carrinho e recomeçar <br>a compra na loja atual
        </button>
      </div>
      <br>
      <div class="center-block">
        <button class="btn btn-default" (click)="voltarLoja()">
          Voltar à outra loja e ajustar <br>o carrinho antes
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>