import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, take } from 'rxjs/operators';
import { ManipulaMensagemErro } from 'src/app/shared/utils/manipula-mensagem-erro';
import { CepService } from '../ceps/shared/cep.service';
import { CorreioService } from '../shared/services/correio.service';
import { EnderecoService } from './shared/endereco.service';
import { Endereco } from './shared/enderecos';
import { States } from './shared/states';

@Component({
  selector: 'app-enderecos',
  templateUrl: './enderecos.component.html',
  styleUrls: ['./enderecos.component.css'],
  providers: [EnderecoService],
})
export class EnderecosComponent implements OnInit {
  addresses: Array<Endereco>;
  addressForm = new FormGroup({
    id: new FormControl(''),
    cep: new FormControl('', [Validators.required, Validators.minLength(9)]),
    titulo: new FormControl('', Validators.required),
    telefone: new FormControl(''),
    logradouro: new FormControl('', Validators.required),
    numero: new FormControl('', Validators.required),
    complemento: new FormControl(''),
    bairro: new FormControl('', Validators.required),
    cidade: new FormControl('', Validators.required),
    uf: new FormControl('', Validators.required),
    sigla: new FormControl(''),
    referencia: new FormControl('', Validators.required),
    principal: new FormControl(false),
  });
  states: Array<{ name: string; initials: string }> = States;
  inEdition: boolean = false;
  isNew: boolean = false;
  loading: boolean = false;
  private sentRequest: boolean = false;
  addressSelected: Endereco = null;
  editAddressSelected: boolean = false;
  confirmActionAddressSelected: boolean = false;

  @Input() showEdit: boolean = true;
  @Input() showRemove: boolean = true;
  @Input() idSelected: number;
  @Output() selected: EventEmitter<any> = new EventEmitter();

  constructor(
    private enderecoService: EnderecoService,
    private cepService: CepService,
    private correioService: CorreioService,
  ) {}

  ngOnInit() {
    this.carregarEnderecos();

    this.addressForm.get('cep').valueChanges
      .pipe(
        filter(text => (text && text.length === 9)),
        debounceTime(200)
        )
      .subscribe((value) => {
        if (!this.sentRequest && this.isNew) {
          this.validarCep(value);
        }
      });
  }

  public carregarEnderecos() {
    this.enderecoService.index().then((data) => {
      this.addresses = data;
      this.checkIdSelected(data);
    });
  }

  private checkIdSelected(addresses: Array<Endereco>) {
    this.addressSelected = addresses.find(address => address.id === this.idSelected);
    this.editAddressSelected = addresses.some(address => address.id === this.idSelected);
    this.emitEventOutput();

  }

  private buscaCepCorreios(cep) {
    this.correioService.consultarCEP(cep).then(() => {
      if (this.correioService.success === true) {
        this.sentRequest = true;
        this.addressForm.get('cep').disable({ emitEvent: false });
        this.validadeField('cidade', this.correioService.endereco.cidade);
        this.validadeField('uf', this.correioService.endereco.uf);
        this.validadeField('sigla', this.correioService.endereco.sigla);
        this.validadeField('bairro', this.correioService.endereco.bairro);
        this.validadeField('logradouro', this.correioService.endereco.logradouro);
        this.addressForm.addControl('latitude', new FormControl(this.correioService.endereco.latitude));
        this.addressForm.addControl('longitude', new FormControl(this.correioService.endereco.longitude));
      } else {
        this.addressForm.get('cep').setValue('', { emitEvent: false });
        alert(this.correioService.errorMessage);
      }
    });
  }

  private validadeField(fieldName, value) {
    if (!!value) {
      const field = this.addressForm.get(fieldName);
      field.setValue(value, { emitEvent: false });
      field.disable({ emitEvent: false });
    }
  }

  selectedAddress(address, confirm) {
    this.addressSelected = address;
    this.confirmActionAddressSelected = confirm;
    this.validarCep(address.cep);
  }

  saveAddress() {
    for (let inner in this.addressForm.controls) {
      this.addressForm.get(inner).markAsTouched();
      this.addressForm.get(inner).updateValueAndValidity();
    }

    if (!this.addressForm.valid) {
      return false;
    }

    const id = this.addressForm.get('id').value;
    const endereco = {
      id: this.addressForm.get('id').value,
      cep: this.addressForm.get('cep').value,
      titulo: this.addressForm.get('titulo').value,
      telefone: this.addressForm.get('telefone').value,
      logradouro: this.addressForm.get('logradouro').value,
      numero: this.addressForm.get('numero').value,
      complemento: this.addressForm.get('complemento').value,
      bairro: this.addressForm.get('bairro').value,
      cidade: this.addressForm.get('cidade').value,
      uf: this.addressForm.get('uf').value,
      sigla: this.addressForm.get('sigla').value,
      referencia: this.addressForm.get('referencia').value,
      principal: this.addressForm.get('principal').value || false,
      latitude: this.addressForm.get('latitude') ? this.addressForm.get('latitude').value : null,
      longitude: this.addressForm.get('longitude') ? this.addressForm.get('longitude').value : null,
    };

    if(!this.addressForm.get('latitude') || !this.addressForm.get('longitude')){
        delete endereco.latitude;
        delete endereco.longitude;
    }

    this.loading = true;
    if (id) {
      this.enderecoService.edit(endereco)
        .pipe(take(1))
        .subscribe(
          (res) => {
            this.carregarEnderecos();
            this.inEdition = false;
            this.isNew = false;
            this.loading = false;
            this.confirmActionAddressSelected = true;
            this.reloadAddressById(res.data);
            this.addressSelected = res.data;
            this.idSelected = res.data.id;
            this.emitEventOutput();
            this.removeEditionMode();
          }, (err) => {
            this.loading = false;
            alert(ManipulaMensagemErro.trataMensagem(err.error.error.inconsistencies));
          });
    } else {
      this.enderecoService.add(endereco)
        .pipe(take(1))
        .subscribe((res) => {
            this.carregarEnderecos();
          this.loading = false;
          this.inEdition = false;
          this.isNew = false;
          this.confirmActionAddressSelected = true;
          this.addresses.push(res.data);
          this.addressSelected = res.data;
          this.idSelected = res.data.id;
          this.emitEventOutput();
          this.removeEditionMode();
        }, (err) => {
          this.loading = false;
          alert(ManipulaMensagemErro.trataMensagem(err.error.error.inconsistencies));
        });
    }
  }

  private reloadAddressById(address) {
    let index = this.addresses.findIndex((end) => end.id === address.id);
    this.addresses[index] = address;
  }

  newAddress() {
    this.inEdition = true;
    this.isNew = true;
    this.validateFieldsEdit(false);
  }

  private validateFieldsEdit(disable) {
    const cep = this.addressForm.get('cep');
    const logradouro = this.addressForm.get('logradouro');
    const bairro = this.addressForm.get('bairro');
    const cidade = this.addressForm.get('cidade');
    const uf = this.addressForm.get('uf');

    if (disable) {
      cep.disable();
      logradouro.disable();
      bairro.disable();
      cidade.disable();
      uf.disable();
    } else {
      cep.enable();
      logradouro.enable();
      bairro.enable();
      cidade.enable();
      uf.enable();
    }
  }

  edit(address: Endereco) {
    this.inEdition = true;
    this.isNew = false;
    this.validateFieldsEdit(true);

    this.addressForm.setValue({
      id: address.id,
      cep: address.cep,
      titulo: address.titulo,
      telefone: address.telefone,
      logradouro: address.logradouro,
      numero: address.numero,
      complemento: address.complemento,
      bairro: address.bairro,
      cidade: address.cidade,
      uf: address.uf,
      sigla: address.sigla,
      referencia: address.referencia,
      principal: address.principal,
    });
  }

  remove(address) {
    if (window.confirm('Tem certeza que deseja excluir este endereço?')) {
      this.enderecoService.deleteItem(address.id).subscribe((data) => {
        this.carregarEnderecos();
      });
    }
  }

  removeEditionMode() {
    this.addressForm.reset();
    this.addressForm.enable();
    this.inEdition = false;
    this.sentRequest = false;
  }

  hasError(fieldName: string) {
    const field = this.addressForm.get(fieldName);
    return field.invalid && (field.dirty || field.touched);
  }

  vadateTypeError(fieldName: string, errorType: string) {
    return errorType in this.addressForm.get(fieldName).errors;
  }

  setSigla() {
    const uf = this.addressForm.get('uf').value;
    const sigla = this.enderecoService.getSiglaUf(uf);
    this.addressForm.get('sigla').setValue(sigla);
  }

  validarCep(cep: string) {
    this.cepService.validar(cep)
      .subscribe(
        (data: any) => {
          if (data.success && !this.isNew) {
            this.emitEventOutput();
          } else if (data.success && this.isNew) {
            this.addressSelected = null;
            this.buscaCepCorreios(cep);
          } else if (!data.success) {
            this.confirmActionAddressSelected = false;
            this.addressSelected = null;
            this.addressForm.get('cep').setValue('', { emitEvent: false });
            alert('CEP não atendido.');
          }
        });
  }

  emitEventOutput() {
    this.selected.emit({ addressSelected: this.addressSelected, confirmActionAddressSelected: this.confirmActionAddressSelected });
  }

  changeAddress() {
    this.addressSelected = null;
    this.inEdition = false;
    this.confirmActionAddressSelected = false;
    this.emitEventOutput();
    this.removeEditionMode();
    this.carregarEnderecos();
  }
}
