import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { CompraService } from 'src/app/pedidos/shared/compra.service';
import { Compra } from 'src/app/pedidos/shared/compras';

@Component({
  selector: 'app-checkout-descontos-nova-tentativa',
  templateUrl: './checkout-descontos-nova-tentativa.component.html',
  styleUrls: ['./checkout-descontos-nova-tentativa.component.css'],
  providers: [CompraService, CookieService]
})
export class CheckoutDescontosNovaTentativaComponent implements OnInit {

  @Input() public compra: Compra = <Compra>{};
  @Input() public tentativas: number;
  @Input() public mensagem: string = 'Não foi possível registrar o desconto na plataforma parceira. Deseja tentar novamente ou finalizar sem desconto?';

  public limiteTentativas: number = 3;

  constructor(
    private pedidoService: CompraService,
    private router: Router,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
  }

  public alterarAplicacaoDesconto(pedido) {
    return this.pedidoService.alterarAplicacaoDesconto(pedido);
  }

  public removerDesconto(pedido: Compra) {
    this.alterarAplicacaoDesconto(pedido)
      .then((data: any) => {
        this.fecharModal();
      });
  }

  atualizarPedido()
  {
    this.fecharModal();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/checkout/pagamento']);
  }

  fecharModal() {
    this.modalService._hideModal(1);
  }

}
