<div class="address--card-container" *ngIf="!inEdition && (!addressSelected || editAddressSelected) && !confirmActionAddressSelected">
  <ng-container *ngFor="let address of addresses">
    <div class="card card-default address--card__item">
      <div class="address--title">
        <h6>
          {{ address.titulo }}
          <span *ngIf="address.principal"> - (Principal) </span>
        </h6>
        <div>
          <i *ngIf="showRemove && !address.principal" aria-hidden="true" class="fa fa-trash pull-right"
            (click)="remove(address)"></i>
          <i *ngIf="showEdit" aria-hidden="true" class="fa fa-pencil pull-right" (click)="edit(address)"></i>
        </div>
      </div>
      <div class="address--body">
        <p>
          {{ address.endereco_formatado }}
        </p>
      </div>
      <div>
        <button type="button" class="btn address--button__selecionar btn-block" [ngClass]="{
            'btn-default': idSelected !== address.id,
            'btn-primary': idSelected === address.id
          }" (click)="selectedAddress(address, true)">
          <ng-container *ngIf="idSelected === address.id">
            <i class="fa fa-check" aria-hidden="true"></i>
            Selecionado
          </ng-container>
          <ng-container *ngIf="idSelected !== address.id">
            Selecionar
          </ng-container>
        </button>
      </div>
    </div>
  </ng-container>
  <div class="col-sm-12 text divider" *ngIf="(!addressSelected || editAddressSelected)">
    <button type="button" class="btn btn-default" (click)="newAddress()">
      Adicionar endereço
    </button>
  </div>
</div>

<div class="address--card-container" *ngIf="!inEdition && addressSelected && confirmActionAddressSelected">
  <div class="text divider">{{ addressSelected.endereco_formatado }}</div>
  <div class="text divider">
    <button class="btn btn-default" (click)="changeAddress()"> Trocar de endereço </button>
  </div>
</div>

<div class="address--form-container">
  <form (ngSubmit)="saveAddress()" [formGroup]="addressForm" *ngIf="inEdition">
    <h5 class="endereco--titulo">Endereço</h5>
    <div class="row">
      <div class="col-xs-6 col-md-6">
        <div class="form-group" [ngClass]="{
            'has-error': hasError('cep')
          }">
          <label for="cep">Digite seu CEP</label>
          <a href="http://www.buscacep.correios.com.br/" target="_blank" class="pull-right">Não sei meu CEP
            <i class="fa fa-external-link" aria-hidden="true" style="font-size: 10px"></i></a>
          <input type="text" class="form-control" formControlName="cep" id="cep" [mask]="'99999-999'" />
          <p class="help-block" *ngIf="hasError('cep')">
            <span *ngIf="vadateTypeError('cep', 'minlength'); else erroCampoObrigadorio">
              Formato inválido
            </span>
            <ng-template #erroCampoObrigadorio>
              <span>
                Este campo é obrigatório
              </span>
            </ng-template>
            <br />
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{
            'has-error': hasError('titulo')
          }">
          <label for="titulo">Nome do endereço</label>
          <input type="text" class="form-control" formControlName="titulo" id="titulo" required />
          <p class="help-block" *ngIf="hasError('titulo')">
            <span>Este campo é obrigatório<br /></span>
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="telefone">Telefone</label>
          <input type="text" class="form-control" formControlName="telefone" id="telefone" [mask]="'(99) 99999-9999'" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{
            'has-error': hasError('logradouro')
          }">
          <label for="logradouro">Endereço</label>
          <input type="text" class="form-control" formControlName="logradouro" id="logradouro" />
          <p class="help-block" *ngIf="hasError('logradouro')">
            <span>Este campo é obrigatório<br /></span>
          </p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" [ngClass]="{
            'has-error': hasError('numero')
          }">
          <label for="numero">Número</label>
          <input type="text" class="form-control" formControlName="numero" id="numero" pattern="[0-9]*" />
          <p class="help-block" *ngIf="hasError('numero')">
            <span>Este campo é obrigatório<br /></span>
          </p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="complemento">Complemento</label>
          <input type="text" class="form-control" formControlName="complemento" id="complemento" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{
            'has-error': hasError('bairro')
          }">
          <label for="bairro">Bairro</label>
          <input type="text" class="form-control" formControlName="bairro" id="bairro" />
          <p class="help-block" *ngIf="hasError('bairro')">
            <span>Este campo é obrigatório<br /></span>
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group" [ngClass]="{
            'has-error': hasError('cidade')
          }">
          <label for="cidade">Cidade</label>
          <input type="text" class="form-control" formControlName="cidade" id="cidade" />
          <p class="help-block" *ngIf="hasError('cidade')">
            <span>Este campo é obrigatório<br /></span>
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" [ngClass]="{
            'has-error': hasError('uf')
          }">
          <label for="estado">Estado</label>
          <select class="form-control" formControlName="uf" (change)="setSigla()">
            <option value=""></option>
            <option *ngFor="let state of states" [ngValue]="state.name">
              {{ state.name }}
            </option>
          </select>
          <p class="help-block" *ngIf="hasError('uf')">
            <span>Este campo é obrigatório<br /></span>
          </p>
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group" [ngClass]="{
            'has-error': hasError('referencia')
          }">
          <label for="referencia">Ponto de referência</label>
          <input type="text" class="form-control" formControlName="referencia" id="referencia" />
          <p class="help-block" *ngIf="hasError('referencia')">
            <span>Este campo é obrigatório<br /></span>
          </p>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isNew">
      <div class="col-md-12">
        <div class="checkbox">
          <label>
            <input type="checkbox" formControlName="principal" name="principal" />
            Definir como principal
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button type="reset" class="btn btn-default" (click)="removeEditionMode()">
          <i class="fa fa-times text-danger" aria-hidden="true"></i> Cancelar
        </button>
        <button type="submit" class="btn btn-success pull-right" [disabled]="loading">
          <span *ngIf="loading"> <i class="fa fa-spinner fa-spin" aria-hidden="true"> </i> Aguarde</span>
          <span *ngIf="!loading"> <i class="fa fa-check" aria-hidden="true"> </i> Salvar </span>
        </button>
      </div>
    </div>
  </form>
</div>
