<ng-template #tmpOpcaoEntrega>
  <div class="alterar-loja--opcao">
    <button type="button" (click)="setPrefReceberCompra(formaEntrega.ENTREGA)" class="btn"
      [ngClass]="{ selected: prefReceberCompra === formaEntrega.ENTREGA }">
      <i class="fa fa-truck fa-2x" aria-hidden="true"></i>
      <span class="mt10">
        Entrega
      </span>
    </button>
  </div>
</ng-template>

<ng-template #tmpOpcaoRetirada>
  <div class="alterar-loja--opcao">
    <button type="button" (click)="setPrefReceberCompra(formaEntrega.RETIRADA)" class="btn"
      [ngClass]="{ selected: prefReceberCompra === formaEntrega.RETIRADA }">
      <i class="fa vp-store-solid fa-2x" aria-hidden="true" [ngClass]="{
          'vp-store-solid--white': prefReceberCompra === formaEntrega.RETIRADA
        }"></i>
      <span class="mt10">
        Retirada
      </span>
    </button>
  </div>
</ng-template>

<ng-template #tmpListaEnderecos>
  <div class="text divider">Qual endereço para entrega?</div>
  <app-enderecos (selected)="selecionarEndereco($event)" [showRemove]="false" [idSelected]="entregaDestino?.id">
  </app-enderecos>
  <ng-container *ngIf="exibirSelecaoCd && cds.entrega?.length > 1">
    <ng-container *ngTemplateOutlet="tmpSelecionarEntrega;"></ng-container>
  </ng-container>

</ng-template>

<ng-template #tmpSelecionarEndereco>
  <div class="alterar-loja--confirmar">
    <ng-container *ngIf="isLogged()">
      <ng-container *ngTemplateOutlet="tmpListaEnderecos"></ng-container>
    </ng-container>

    <ng-container *ngIf="!isLogged()">
      <ng-container *ngTemplateOutlet="tmpSelecionarEntrega"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #tmpSelecionarEntrega>
  <div class="text divider">
    Em qual loja deseja realizar sua compra?
  </div>
  <div class="alterar-loja--opcoes mb10">
    <ng-container *ngFor="let cd of cds.entrega">
      <div class="card card-default alterar-loja--opcao-retirada">
        <h6 class="alterar-loja--opcao-retirada__title">
          {{ cd.nome_site }}
        </h6>
        <ng-container *ngTemplateOutlet="tmpDescricaoRetirada; context: { $implicit: cd }"></ng-container>
        <button type="button" class="btn address--button__selecionar btn-block" [ngClass]="{
            'btn-default':
              clientePreferences?.tipoEntrega !== formaEntrega.ENTREGA ||
              (cdAtual.id !== cd.id),
            'btn-primary':
              clientePreferences?.tipoEntrega === formaEntrega.ENTREGA &&
              cdAtual.id === cd.id
          }" (click)="salvarLojaSelecionada(cd)">
          <ng-container *ngIf="
              clientePreferences?.tipoEntrega === formaEntrega.ENTREGA &&
                cdAtual.id === cd.id;
              else elseSelecionar
            ">
            <i class="fa fa-check" aria-hidden="true"></i>
            Selecionado
          </ng-container>
          <ng-template #elseSelecionar>
            Selecionar
          </ng-template>
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #tmpSelecionarRetirada>
  <div class="text divider">
    Em qual loja deseja realizar a retirada de sua compra?
  </div>
  <div class="alterar-loja--opcoes mb10">
    <ng-container *ngFor="let cd of cds.retirada">
      <div class="card card-default alterar-loja--opcao-retirada">
        <h6 class="alterar-loja--opcao-retirada__title">
          {{ cd.nome_site }}
        </h6>
        <ng-container *ngTemplateOutlet="tmpDescricaoRetirada; context: { $implicit: cd }"></ng-container>
        <button type="button" class="btn address--button__selecionar btn-block" [ngClass]="{
            'btn-default':
              clientePreferences?.tipoEntrega !== formaEntrega.RETIRADA ||
              (cdAtual.id !== cd.id),
            'btn-primary':
              clientePreferences?.tipoEntrega === formaEntrega.RETIRADA &&
              cdAtual.id === cd.id
          }" (click)="selecionarRetirada(cd)">
          <ng-container *ngIf="
              clientePreferences?.tipoEntrega === formaEntrega.RETIRADA &&
                cdAtual.id === cd.id;
              else elseSelecionar
            ">
            <i class="fa fa-check" aria-hidden="true"></i>
            Selecionado
          </ng-container>
          <ng-template #elseSelecionar>
            Selecionar
          </ng-template>
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>

<div class="modal-loja">
  <div *ngIf="validateShowMultipleOptions()">
    <div *ngIf="!isLogged()" class="title">Bem-vindo</div>
    <div *ngIf="isLogged()" class="{{ hiddenTitle ? 'text divider' : 'title' }} ">
      Como deseja receber sua compra?
    </div>
    <ng-container *ngIf="!hiddenClose">
      <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </ng-container>
  </div>

  <div *ngIf="!isLogged()">
    <div class="header">
      <div class="info">Informe seu CEP</div>
      <div class="text">Para acessar uma de nossas lojas, informe o CEP do endereço de entrega:</div>
    </div>
    <app-ceps [customLabel]="true" (cepChange)="validarCep($event)"></app-ceps>
  </div>

  <ng-container *ngIf="!isLogged() && cepCompleto() && !loading">

    <ng-container *ngIf="permiteCadastro && !exibirErroCepNaoEncontrado">
      <ng-container *ngIf="cds.retirada.length > 0 || cds.entrega.length > 0">
        <div class="text divider">
          Como deseja receber sua compra?
        </div>
        <div class="alterar-loja--opcoes mb10">
          <ng-container *ngIf="cds.entrega.length > 0 && exibirOpcaoEntrega">
            <ng-container *ngTemplateOutlet="tmpOpcaoEntrega"></ng-container>
          </ng-container>

          <ng-container *ngIf="cds.retirada.length > 0">
            <ng-container *ngTemplateOutlet="tmpOpcaoRetirada"></ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="(!permiteCadastro || (cds.retirada.length === 0 && cds.entrega.length === 0)) && !exibirErroCepNaoEncontrado">
      <app-solicitacao-ceps [cep]="cep"></app-solicitacao-ceps>
    </ng-container>
    <ng-container *ngIf="exibirErroCepNaoEncontrado">
     <div class="text-center text-danger"> {{msgErroCepNaoEncontrado}}</div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isLogged()">
    <ng-container *ngIf="validateShowMultipleOptions()">
      <div class="alterar-loja--opcoes mb10">
        <ng-container *ngIf="exibirOpcaoEntrega">
          <ng-container *ngTemplateOutlet="tmpOpcaoEntrega"></ng-container>
        </ng-container>

        <ng-container *ngIf="cds.retirada.length > 0">
          <ng-container *ngTemplateOutlet="tmpOpcaoRetirada"></ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-container>
    <ng-container *ngIf="prefReceberCompra === formaEntrega.RETIRADA && cds.retirada.length > 0">
      <ng-container *ngTemplateOutlet="tmpSelecionarRetirada"></ng-container>
    </ng-container>

    <ng-container *ngIf="prefReceberCompra === formaEntrega.ENTREGA">
      <ng-container *ngTemplateOutlet="tmpSelecionarEndereco"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #tmpDescricaoRetirada let-cd>
  <ng-container *ngIf="cd.tipo_exibicao_retirada === tipoExibicaoRetirada.ENDERECO;">
    <ng-container *ngTemplateOutlet="
        tmpDescricaoRetiradaEndereco;
        context: { $implicit: cd.endereco }
      "></ng-container>
  </ng-container>
  <ng-container *ngIf="cd.tipo_exibicao_retirada === tipoExibicaoRetirada.OBSERVACAO">
    <ng-container *ngTemplateOutlet="
      tmpDescricaoRetiradaObeservacao;
        context: { $implicit: cd }
      "></ng-container>
  </ng-container>
</ng-template>

<ng-template #tmpDescricaoRetiradaEndereco let-endereco>
  <p class="alterar-loja--opcao-retirada__body">
    {{ endereco.logradouro }}, {{ endereco.numero }},
    <ng-container *ngIf="endereco.complemento">
      {{ endereco.complemento }} -
    </ng-container>
    {{ endereco.bairro }} - {{ endereco.cidade }} -
    {{ endereco.estado }}
  </p>
</ng-template>

<ng-template #tmpDescricaoRetiradaObeservacao let-cd>
  <p class="alterar-loja--opcao-retirada__body">
    {{ cd.observacao_retirada }}
  </p>
</ng-template>
