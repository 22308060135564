import { Environment } from 'src/environments/model';
import { environment } from 'src/environments/environment';
import { BsModalService } from 'ngx-bootstrap';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { ClienteService } from 'src/app/clientes/shared/cliente.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { DocumentValidation } from 'src/app/shared/utils/document-validation';

@Component({
  styleUrls: ['./login-celular-modal.component.css'],
  templateUrl: './login-celular-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LoginCelularModalComponent implements OnInit {
  public modalLevel: number = 1;
  public user: any = {};
  public closeEvent$: Subject<any> = new Subject();
  public validacaoBot = 'login-cliente';
  public bloquearBotaoLogin = false;
  public env: Environment;
  public cpf: any = {};
  public telefoneCelular: any = {};
  public loading = false;

  public constructor(
    private modalService: BsModalService,
    private clienteService: ClienteService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {}

  @ViewChild('captchaRef', { static: true }) captchaRef;

  ngOnInit(): void {
    this.env = environment;
  }

  public closeButton() {
    this.closeEvent$.next(true);
    this.close();
  }

  public close() {
    this.modalService._hideModal(this.modalLevel);
  }

  public validarCPF() {
    if (this.user.cpf && this.user.cpf.length == 14) {
      let cpfValidado = DocumentValidation.validarCpf(this.user.cpf);
      if (cpfValidado && this.user.celular.length == 15) {
        this.bloquearBotaoLogin = false;
      } else {
        this.bloquearBotaoLogin = true;
      }
      return cpfValidado;
    }
    return true;
  }

  public validarCelular() {
    if (this.user.celular && this.user.celular.length == 15 && DocumentValidation.validarCpf(this.user.cpf)) {
      this.bloquearBotaoLogin = false;
    } else {
      this.bloquearBotaoLogin = true;
    }
  }

  async solicitarSmsPin() {
    this.loading = true;
    this.cpf.value = this.user.cpf.replace(/\D/g, '');
    this.telefoneCelular.value = this.user.celular.replace(/\D/g, '');

    await this.clienteService.loginViaCelular(this.cpf.value, this.telefoneCelular.value)
      .then((resp: any) => {
        this.localStorageService.set('cpf', this.cpf.value);
        this.localStorageService.set('telefoneCelular', this.telefoneCelular.value);
        this.localStorageService.set('expire_at', resp.data.expire_at);
        this.localStorageService.set('current_time', resp.data.current_time);
        this.router.navigate(['/clientes/login/celular']);
      }).catch((err: any) => {
        let erro = false;
        let nao_cadastrado_erro = "Telefone celular não cadastrado para este CPF.";
        let nao_cadastrado_msg = "Telefone celular não cadastrado! \n\nPara fazer login com o celular é necessário Cadastrá-lo. Por favor, entre no site utilizando seu CPF e Senha para cadastrar seu número de telefone.";
        if (err.error.error == nao_cadastrado_erro) {
          alert(nao_cadastrado_msg);
          erro = true;
        }

        let nao_validado_erro = "Telefone celular não validado.";
        let nao_validado_msg = "Número de celular não validado! \n\nPara fazer o login com o número de celular é necessário validá-lo. Por favor, entre no site utilizando seu CPF e Senha para validar seu número.";
        if (err.error.error == nao_validado_erro) {
          alert(nao_validado_msg);
          erro = true;
        }

        if (!erro) {
          alert("Login por número de celular indisponível no momento! Por favor acesse utilizando seu email ou CPF e sua senha");
        }

      }).finally(() => {
        this.close();
        this.loading = false;
      });
  }
}
