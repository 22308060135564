<div [ngSwitch]="tipoOperacao" id="cashback-tipo">
  <ng-template ngSwitchDefault>
    <i class="fa fa-info-circle" aria-hidden="true"></i>
    <span *ngIf="label">Sem informações</span>
  </ng-template>
  <ng-template [ngSwitchCase]="1">
    <i class="fa fa-arrow-circle-down text-success" aria-hidden="true" title="Crédito"></i>
    <span *ngIf="label">Crédito</span>
  </ng-template>
  <ng-template [ngSwitchCase]="2">
    <i class="fa fa-arrow-circle-up text-danger" aria-hidden="true" title="Débito"></i>
    <span *ngIf="label">Débito</span>
  </ng-template>
  <ng-template [ngSwitchCase]="3">
    <i class="fa fa-plus-circle text-success" aria-hidden="true" title="Compra Cancelada"></i>
    <span *ngIf="label">Compra Cancelada</span>
  </ng-template>
  <ng-template [ngSwitchCase]="4">
    <i class="fa fa-minus-circle text-warning" aria-hidden="true" title="Saldo Vencido"></i>
    <span *ngIf="label">Saldo Vencido</span>
  </ng-template>
  <ng-template [ngSwitchCase]="'C'">
    <i class="fa fa-arrow-circle-down text-success" aria-hidden="true" title="Crédito"></i>
    <span *ngIf="label">Crédito</span>
  </ng-template>
  <ng-template [ngSwitchCase]="'R'">
    <i class="fa fa-arrow-circle-up text-danger" aria-hidden="true" title="Débito"></i>
    <span *ngIf="label">Débito</span>
  </ng-template>
</div>
