import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class CorreioService {

    public url = '';
    public service = '/loja/correios';
    public endereco: any;
    public success = false;
    public errorMessage = 'Informações sobre o CEP não encontradas';

    constructor(private httpClient: HttpClient) {
        this.url = environment.apiIntegracaoUrl;
    }

    consultarCEP(id: string) {
        return this.httpClient.get(this.url + this.service + '/consultar/cep/' + id)
            .toPromise()
            .then((data: {endereco, success}) => {
                this.endereco = data.endereco;
                this.success = data.success;
            });
    }

}
