import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, EventEmitter, OnDestroy, OnInit, } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
export class ObserveVisibilityDirective {
    constructor(element) {
        this.element = element;
        this.debounceTime = 200;
        this.threshold = 1;
        this.visible = new EventEmitter();
        this.subject$ = new Subject();
    }
    ngOnInit() {
        this.createObserver();
    }
    ngAfterViewInit() {
        this.startObservingElements();
    }
    ngOnDestroy() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = undefined;
        }
        this.subject$.next();
        this.subject$.complete();
    }
    isVisible(element) {
        return new Promise((resolve) => {
            const observer = new IntersectionObserver(([entry]) => {
                resolve(entry.intersectionRatio === 1);
                observer.disconnect();
            });
            observer.observe(element);
        });
    }
    createObserver() {
        const options = {
            rootMargin: '0px',
            threshold: this.threshold,
        };
        const isIntersecting = (entry) => entry.isIntersecting || entry.intersectionRatio > 0;
        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (isIntersecting(entry)) {
                    this.subject$.next({ entry, observer });
                }
            });
        }, options);
    }
    startObservingElements() {
        if (!this.observer) {
            return;
        }
        this.observer.observe(this.element.nativeElement);
        this.subject$
            .pipe(delay(this.debounceTime), filter((value) => value !== undefined))
            .subscribe({
            next: ({ entry, observer }) => {
                this.handleVisibility(entry, observer);
            },
        });
    }
    handleVisibility(entry, observer) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const target = entry.target;
            const isStillVisible = yield this.isVisible(target);
            if (isStillVisible) {
                this.visible.emit(target);
                observer.unobserve(target);
            }
        });
    }
}
