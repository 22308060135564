import { Injectable } from '@angular/core';
import { Produto } from '../../shared/produto';
import { Paginacao } from '../../../partials/paginacao/shared/paginacao';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TabsBarService } from '../../../partials/tabs-bar/shared/tabs-bar.service';
import { environment } from 'src/environments/environment';
import { map, take } from 'rxjs/operators';

@Injectable()
export class ProdutoBuscaService {
  constructor(
    private httpClient: HttpClient,
    private tabsBarService: TabsBarService
  ) { }

  private querySource = new Subject<string>();
  public queryStream$ = this.querySource.asObservable();

  private visibilitySource = new Subject<boolean>();
  public visibilityStream$ = this.visibilitySource.asObservable();

  public service: string = '/buscas';
  public produtos: Produto[] = [];
  public paginacao: Paginacao = <Paginacao>{};
  public sugestoes: any = [];
  public termo: string;
  public digitado: string;
  public marcas: any = [];
  private _visibleSearch: boolean = true;
  private ultimaBuscaId: string;

  public set visibleSearch(val: boolean) {
    this._visibleSearch = val;
    this.visibilitySource.next(val);
  }

  buscaRapidaProdutos(cdId, termo) {
    termo = this.replaceTermoBusca(termo);
    const URI = `buscas/produtos/filial/${environment.filialId}/centro_distribuicao/${cdId}/termo/${termo}/rapida`;
    return this.httpClient
      .get<any>(`${environment.apiUrl}/loja/${URI}`,{
        headers: { Application: 'site' },
      })
      .pipe(this.registraUltimoBusca());
  }

  buscaProdutos(cdId, termo, query?) {
    termo = this.replaceTermoBusca(termo);
    const URI = `buscas/produtos/filial/${environment.filialId}/centro_distribuicao/${cdId}/termo/${termo}`;
    return this.httpClient
      .get<any>(`${environment.apiUrl}/loja/${URI}${query}`,{
        headers: { Application: 'site' },
      })
      .pipe(this.registraUltimoBusca());
  }

  private replaceTermoBusca(query) {
    return query.replace(/[\\\/\s%]/g, '+');
  }

  private registraUltimoBusca() {
    return map((res: any) => {
      if ('data' in res) {
        this.ultimaBuscaId = res.data.busca_id;
      }
      return res;
    });
  }

  buscaSugestao(termo: string) {
    termo = this.replaceTermoBusca(termo);
    const URI = `buscas/semelhantes/filial/${environment.filialId}/termo/${termo}`;
    return this.httpClient.get<any>(`${environment.apiUrl}/loja/${URI}`);
  }

  getProdutos(params?: any) {
    return this.httpClient
      .get(this.service + '/index/' + environment.filialId, { params })
      .toPromise()
      .then((data: any) => {
        this.produtos = data.produtos;
        this.marcas = data.marcas;
        this.tabsBarService.setMarcas(data.marcas);
        this.termo = data.termo;
        this.digitado = data.digitado;
        this.paginacao = data.paging;
        if (data.sugestoes) {
          this.sugestoes = data.sugestoes;
        }
      });
  }

  pushSource(q: string) {
    this.querySource.next(q);
  }

  salvarProdutoEncontrado(produtoId) {
    if (this.ultimaBuscaId) {
      this.httpClient
        .post(
          `${environment.apiUrl}/loja/buscas/${this.ultimaBuscaId}/encontrada/produto/${produtoId}`,
          {}
        )
        .pipe(take(1))
        .subscribe();
    }
  }

  limparUltimaBusca() {
    this.ultimaBuscaId = null;
  }
}
