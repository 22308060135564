/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./minhas-listas-share.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./minhas-listas-share.component";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "../../../filiais/shared/filial.service";
var styles_MinhasListasShareComponent = [i0.styles];
var RenderType_MinhasListasShareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MinhasListasShareComponent, data: {} });
export { RenderType_MinhasListasShareComponent as RenderType_MinhasListasShareComponent };
export function View_MinhasListasShareComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "modal-minhas-listas-share"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-close modal-minhas-listas-share__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "header", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "section", [["class", "modal-minhas-listas-share__section"], ["id", "copy"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["Ol\u00E1, gostaria de compartilhar com voc\u00EA a lista ", ".Acesse o link para mais detalhes: ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 8, "footer", [["class", "modal-minhas-listas-share__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "btn btn-success button hidden-lg hidden-md"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.shareWhatsApp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-whatsapp"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" WhatsApp"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["class", "btn btn-checkout button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyText() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-copy"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["id", "copyBtnLabel"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Copiar"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.list.nome; var currVal_2 = (_co.url + _co.list.slug); _ck(_v, 6, 0, currVal_1, currVal_2); }); }
export function View_MinhasListasShareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_MinhasListasShareComponent_0, RenderType_MinhasListasShareComponent)), i1.ɵdid(1, 114688, null, 0, i2.MinhasListasShareComponent, [i3.BsModalService, i4.FilialService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MinhasListasShareComponentNgFactory = i1.ɵccf("ng-component", i2.MinhasListasShareComponent, View_MinhasListasShareComponent_Host_0, {}, {}, []);
export { MinhasListasShareComponentNgFactory as MinhasListasShareComponentNgFactory };
