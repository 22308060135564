import { Produto } from './../../../produtos/shared/produto';
import { MinhasListasService } from './../../../shared/services/minhas-listas.service';
import { BsModalService } from 'ngx-bootstrap';
import { MinhasListas } from './../../interfaces/minhas-listas';
import { Component, OnInit } from '@angular/core';
import { MinhasListasEditComponent } from '../minhas-listas-edit/minhas-listas-edit.component';
import { FilialService } from 'src/app/filiais/shared/filial.service';
import { ProdutoDetalheService } from 'src/app/produtos/produto-detalhe/shared/produto-detalhe.service';
import { Departamento } from 'src/app/produtos/produto-departamento/shared/departamentos';
import { DepartamentoService } from 'src/app/shared/services/departamento.service';
import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';

@Component({
  styleUrls: ['./minhas-listas-product.component.css'],
  templateUrl: './minhas-listas-product.component.html',
  providers: [
    ProdutoDetalheService
  ]
})
export class MinhasListasProductComponent implements OnInit {
  public list: MinhasListas = <MinhasListas>{};
  public lists: MinhasListas[] = [];
  public product: Produto = <Produto>{};
  public loading: boolean = false;
  public productMessage: string = '';
  public departamento: Departamento;
  public secao: any;

  public constructor(
    private modalService: BsModalService,
    private minhasListasService: MinhasListasService,
    private filialService: FilialService,
    private cdService: CentroDistribuicaoService,
    private produtoDetalheService: ProdutoDetalheService,
    private departamentoService: DepartamentoService
  ) {}

  public ngOnInit() {
    this.start();
  }

  private start() {
    this.minhasListasService.getAll().then((response: any) => {
      this.lists = [{}, ...response.minhaListas];
      let filteredList = this.lists.filter(list => {
        return list && list.id === this.minhasListasService.selectedId;
      });
      if (filteredList.length) {
        this.list = filteredList[0];
        this.checkProduct();
      }
    });
  }

  public checkProduct() {
    this.productMessage = '';
    // if ('quantidade_por_produto' in this.list && this.list.quantidade_por_produto.hasOwnProperty(this.product.id)) {
    //     this.productMessage = 'Produto já existe na lista com quantidade ' + this.list.quantidade_por_produto[this.product.id];
    // }
  }

  public up() {
    this.product.quantidade += 1;
  }

  public down() {
    if (this.product.quantidade > 1) {
      this.product.quantidade -= 1;
    }
  }

  public edit() {
    if (this.product.quantidade < 1) {
      this.product.quantidade = 1;
    }
  }

  public save() {
    this.loading = true;
    this.minhasListasService
      .addProduct({
        minha_lista_id: this.list.id,
        produto_id: this.product.produto_id,
        quantidade: this.product.quantidade
      })
      .then((response: any) => {
        this.close();
        this.loading = false;
        this.minhasListasService.selectedId = this.list.id;
        alert(response.message);
        this.buscaProdutoDetalhe(this.cdService.id, this.product.produto_id);
      })
      .catch(error => {
        this.loading = false;
        if (error.message) {
          alert(error.message);
        }
      });
  }

  private buscaProdutoDetalhe(cdId, produtoId) {
    this.produtoDetalheService.detalhe(cdId, produtoId).subscribe(
      (req: any) => {
        this.product = req.data.produto;
        let cmId = req.data.produto.classificacao_mercadologica_id;
        this.departamento = this.departamentoService.findDepartamentoBySecaoId(cmId);
        this.secao = this.departamentoService.findSecaoBySecaoId(this.departamento, cmId);
        this.addToWishlistGoogleAnalytics();
      }
    );
  }

  private addToWishlistGoogleAnalytics() {
    if(this.filialService.detail.ga4) {
      if(this.filialService.detail.ga4) {
        let itens: Array<any> = [];

        let add = {
          item_id: this.product.produto_id,
          item_name: this.product.descricao,
          item_category: this.departamento.descricao,
          item_category2: this.secao.descricao,
          price: this.product.preco,
          quantity: this.product.quantidade
        }
        itens.push(add);

        gtag('event', 'add_to_wishlist', {
          currency: 'BRL',
          value: this.product.preco_venda,
          items: itens
        });
    }
    }
  }

  public close() {
    this.modalService._hideModal(1);
  }

  public showCreateModal(): any {
    let modalRef = this.modalService.show(MinhasListasEditComponent, {
      keyboard: true,
      class: 'custom-modal',
      ignoreBackdropClick: false
    });

    modalRef.content.title = 'Adicionar Lista';
    modalRef.content.new = true;
    modalRef.content.modalLevel = 2;
    modalRef.content.onHide = res => this.hideModal(res);
  }

  private hideModal(res) {
    if (res && res.minhaLista) {
      this.minhasListasService.selectedId = res.minhaLista.id;
      this.start();
    }
  }
}
