import { Component, OnInit } from '@angular/core';
import { FilialService } from '../filiais/shared/filial.service';
import { BannerService } from '../partials/banners/shared/banner.service';
import { TabsBarService } from '../partials/tabs-bar/shared/tabs-bar.service';

@Component({
    selector: 'app-not-found-page',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent implements OnInit {

    constructor(
        private bannerService: BannerService,
        private filialService: FilialService,
        private tabsBarService: TabsBarService
    ) {}

    public ngOnInit() {
        this.filialService.setTitle('Página não encontrada');
        this.bannerService.show_banners = false;
        window.dispatchEvent(new Event('atualizarBanners'));

        this.tabsBarService.hide();
        this.tabsBarService.filter = false;
        window.dispatchEvent(new Event('atualizarTabsBar'));
    }
}
