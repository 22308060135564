import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { CarrinhoAnonimoService } from 'src/app/partials/carrinho/shared/carrinho-anonimo.service';

@Injectable()
export class AuthCarrinhoAnonimoGuard implements CanActivate {

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private carrinhoAnonimoService: CarrinhoAnonimoService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLoggedIn = !(this.localStorageService.get('token') == null);
    if (isLoggedIn || this.carrinhoAnonimoService.permitirCarrinhoAnonimo) {
      return true;
    } else {
      this.router.navigate(['/clientes/login'], { queryParams: { redirectUrl: state.url } });
    }
  }
}
