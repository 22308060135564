import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpProduto } from '../../shared/httpProduto';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProdutoOfertaTagsService {
  constructor(private httpClient: HttpClient) {}

  ofertaTags(cdId: number, tag: string, query?): Observable<HttpProduto> {
    if (tag === 'oferta-exclusiva') {
      return this.ofertaExclusiva(cdId);
    }

    const URI = `ofertas/tag/${tag}/produtos/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
    return this.httpClient.get<HttpProduto>(
      `${environment.apiUrl}/loja/${URI}${query}`
    );
  }

  ofertaExclusiva(cdId: number) {
    const URI = `ofertas_exclusivas/produtos/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
    return this.httpClient.get<HttpProduto>(
      `${environment.apiUrl}/loja/${URI}`
    );
  }
}
