/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lista-paginas-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../shared/pipes/match.pipe";
import * as i5 from "./lista-paginas-link.component";
var styles_ListaPaginasLinkComponent = [i0.styles];
var RenderType_ListaPaginasLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListaPaginasLinkComponent, data: {} });
export { RenderType_ListaPaginasLinkComponent as RenderType_ListaPaginasLinkComponent };
function View_ListaPaginasLinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["href", "javascript:void(0)"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(4, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.aClass; _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "/institucional/paginas/", _v.parent.context.$implicit.slug, ""); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.subtitulo; _ck(_v, 5, 0, currVal_4); }); }
function View_ListaPaginasLinkComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["rel", "noopener noreferrer"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵppd(3, 3), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.aClass; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (i1.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.link, "^(?:f|ht)tps?://", "i")) ? _v.parent.context.$implicit.link : ("https://" + _v.parent.context.$implicit.link)); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.subtitulo; _ck(_v, 4, 0, currVal_2); }); }
function View_ListaPaginasLinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListaPaginasLinkComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["externa", 2]], null, 0, null, View_ListaPaginasLinkComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.liClass; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.tipo == _co.TipoPagina.INTERNA) || (_v.context.$implicit.tipo == _co.TipoPagina.IFRAME)); var currVal_2 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_ListaPaginasLinkComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.MatchPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ul", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListaPaginasLinkComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ulClass; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.paginas; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ListaPaginasLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lista-paginas-link", [], null, null, null, View_ListaPaginasLinkComponent_0, RenderType_ListaPaginasLinkComponent)), i1.ɵdid(1, 49152, null, 0, i5.ListaPaginasLinkComponent, [], null, null)], null, null); }
var ListaPaginasLinkComponentNgFactory = i1.ɵccf("app-lista-paginas-link", i5.ListaPaginasLinkComponent, View_ListaPaginasLinkComponent_Host_0, { paginas: "paginas", ulClass: "ulClass", liClass: "liClass", aClass: "aClass" }, {}, []);
export { ListaPaginasLinkComponentNgFactory as ListaPaginasLinkComponentNgFactory };
