import { CarrinhoService } from './../../partials/carrinho/shared/carrinho.service';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Compra } from '../../pedidos/shared/compras';
import { CompraService } from '../../pedidos/shared/compra.service';
import { ClienteService } from '../../clientes/shared/cliente.service';
import { CookieService } from 'ngx-cookie-service';
import { BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-minha-conta-pedido-solicitar-cancelamento',
  templateUrl: './minha-conta-pedido-solicitar-cancelamento.component.html',
  styleUrls: ['./minha-conta-pedido-solicitar-cancelamento.component.css'],
  providers: [CompraService, CookieService]
})
export class MinhaContaPedidoSolicitarCancelamentoComponent {
  @Input() public compra: Compra = <Compra>{};
  @Input() public finalizacao: boolean = false;

  constructor(
    private pedidoService: CompraService,
    private clienteService: ClienteService,
    private router: Router,
    private cookieService: CookieService,
    private carrinhoService: CarrinhoService,
    private modalService: BsModalService
  ) {}

  public cancelarCompra(pedido) {
    if (this.finalizacao) {
      return this.pedidoService.cancelarCompraFinalizacao(pedido);
    }
    return this.pedidoService.deleteItem(pedido);
  }

  public cancelar(pedido: Compra) {
    this.cancelarCompra(pedido)
      .toPromise()
      .then((data: any) => {
        this.fecharModal();

        if (pedido.concluir_compra !== 0 || pedido.compra_status_id === 16) {
          this.clienteService.setPedidoEmProcesso(<Compra>{});
          this.cookieService.delete('pedido');
        }
        window.dispatchEvent(new Event('compraCancelada'));
        this.carrinhoService.limparInfoCarrinho();
        this.router.navigate(['/minha-conta']);
        alert(data.message);
      });
  }

  fecharModal() {
    this.modalService._hideModal(1);
  }
}
