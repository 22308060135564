/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./offline.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./offline.component";
var styles_OfflineComponent = [i0.styles];
var RenderType_OfflineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OfflineComponent, data: {} });
export { RenderType_OfflineComponent as RenderType_OfflineComponent };
export function View_OfflineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "app-offline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "error-template"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Voc\u00EA est\u00E1 offline!"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reconecte-se para continuar navegando"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "Logo"], ["class", "logo"], ["src", "assets/img/header-logo.png"]], null, null, null, null, null))], null, null); }
export function View_OfflineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-offline", [], null, null, null, View_OfflineComponent_0, RenderType_OfflineComponent)), i1.ɵdid(1, 49152, null, 0, i2.OfflineComponent, [], null, null)], null, null); }
var OfflineComponentNgFactory = i1.ɵccf("app-offline", i2.OfflineComponent, View_OfflineComponent_Host_0, {}, {}, []);
export { OfflineComponentNgFactory as OfflineComponentNgFactory };
