import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';

@Component({
  templateUrl: './observacao-produto.component.html',
  styleUrls: ['./observacao-produto.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ObservacaoProdutoComponent {

  @Input() item = { observacao: '' };
  @Input() observacao: string;
  public onHide: Function;

  constructor(private modalService: BsModalService) {}

  fechar(fromConfirmar = false) {
    if (!fromConfirmar) {
      this.item.observacao = this.observacao;
    }
    this.onHide(this.item);
    this.modalService._hideModal(1);
  }
}
