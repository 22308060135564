import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-escolher-carrinho-cliente',
  templateUrl: './escolher-carrinho-cliente.component.html',
  styleUrls: ['./escolher-carrinho-cliente.component.css']
})
export class EscolherCarrinhoClienteComponent {

  public onHide: Function;

  constructor(private modalService: BsModalService) {}

  confirmar(escolhaBotao) {
    this.onHide(escolhaBotao);
    this.modalService._hideModal(1);
  }

}
