<div class="modal-header">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="fecharModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title text-info" id="solicitarCancelamentoLabel">
    Solicitar cancelamento: {{ compra.id }}
  </h4>
</div>
<form id="formCancelar" (submit)="cancelar(compra)">
  <div class="modal-body">
    <span class="text-muted">Informe suas observações para o atendimento</span>
    <div class="form-group mt20">
      <label for="observacoes_do_cliente">Observações</label>
      <textarea id="observacoes_do_cliente" required rows="5" placeholder="" [(ngModel)]="compra.observacoes_do_cliente" class="form-control" name="observacoes_do_cliente"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" id="closeModalButton" (click)="fecharModal()">
      Cancelar
    </button>
    <button type="submit" class="btn btn-primary">
      Enviar solicitação
    </button>
  </div>
</form>
