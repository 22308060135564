<div class="modal-minhas-listas-product">
    <i class="fa fa-close modal-minhas-listas-product__close" aria-hidden="true" (click)="close()"></i>
    <section>
        <div class="product">{{product.descricao}}</div>
        <form class="modal-minhas-listas-product__form" (ngSubmit)="save()" #listForm="ngForm">
            <div class="form-group input-group">
                <label class="control-label">Selecione a lista</label>
                <select class="form-control" [(ngModel)]="list" (ngModelChange)="checkProduct()" id="lista" name="lista" required #lista="ngModel">
                    <option *ngFor="let list of lists" [ngValue]="list">{{list.nome}}</option>
                </select>
            </div>
            <div class="product-exists" *ngIf="productMessage">{{productMessage}}</div>
            <div class="new-list" (click)="showCreateModal()">Cadastrar nova lista</div>
            <div class="modal-minhas-listas-product__footer">
                <div class="spin">
                    <i (click)="down()" class="fa fa-minus-circle text-primary" aria-hidden="true"></i>
                    <input type="number" min="1" name="quantidade" required #quantidade="ngModel" [(ngModel)]="product.quantidade" (keyup.enter)="edit()" (blur)="edit()">
                    <i (click)="up()" class="fa fa-plus-circle text-primary" aria-hidden="true"></i>
                </div>
                <button type="submit" class="btn btn-checkout button" [disabled]="!list.id || loading">
                    <i *ngIf="loading" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    <span *ngIf="loading"> AGUARDE</span>
                    <span *ngIf="!loading">SALVAR</span>
                </button>
            </div>
        </form>
    </section>
</div>