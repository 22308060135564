<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="error-template">
        <h1 class="text-center">
          Oops!
        </h1>
        <h2 class="text-center">
          404 Página não encontrada
        </h2>
        <div class="error-details text-center">
          Esta página não existe ou não está disponível.
        </div>
        <div class="error-actions">
          <a routerLink="/" class="btn btn-primary btn-lg"
            ><span class="glyphicon glyphicon-home"></span> Voltar ao site </a
          ><a
            routerLink="/institucional/paginas/fale-conosco"
            class="btn btn-default btn-lg"
          >
            <span class="glyphicon glyphicon-envelope"></span> Fale conosco
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
