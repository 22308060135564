import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { Angulartics2 } from 'angulartics2';
import { BsModalService } from 'ngx-bootstrap';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AlterarLojaService } from 'src/app/centro-distribuicao/shared/alterar-loja.service';
import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';
import { LimiteCdService } from 'src/app/centro-distribuicao/shared/limite-cd.services';
import { MensagemAlteracaoCd } from 'src/app/centro-distribuicao/shared/MensagemAlteracaoCd';
import { EscolherCarrinhoClienteComponent } from 'src/app/checkout/modals/escolher-carrinho-cliente-component/escolher-carrinho-cliente.component';
import { ClienteService } from 'src/app/clientes/shared/cliente.service';
import { LoginModalComponent } from 'src/app/login-modal/login-modal.component';
import { CompraService } from 'src/app/pedidos/shared/compra.service';
import { environment } from 'src/environments/environment';
import { CarrinhoAnonimoService } from './carrinho-anonimo.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular-2-local-storage";
import * as i3 from "angulartics2";
import * as i4 from "../../../clientes/shared/cliente.service";
import * as i5 from "../../../centro-distribuicao/shared/centro-distribuicao.service";
import * as i6 from "../../../centro-distribuicao/shared/limite-cd.services";
import * as i7 from "@angular/router";
import * as i8 from "ngx-bootstrap/modal";
import * as i9 from "../../../pedidos/shared/compra.service";
import * as i10 from "../../../centro-distribuicao/shared/alterar-loja.service";
import * as i11 from "./carrinho-anonimo.service";
export class CarrinhoService {
    constructor(httpClient, localStorageService, angulartics2, clienteService, cdService, limiteCdService, router, modalService, compraService, alterarLojaService, carrinhoAnonimoService) {
        this.httpClient = httpClient;
        this.localStorageService = localStorageService;
        this.angulartics2 = angulartics2;
        this.clienteService = clienteService;
        this.cdService = cdService;
        this.limiteCdService = limiteCdService;
        this.router = router;
        this.modalService = modalService;
        this.compraService = compraService;
        this.alterarLojaService = alterarLojaService;
        this.carrinhoAnonimoService = carrinhoAnonimoService;
        this.TIPO_OFERTA_COMBO = 4;
        this._totalItens = 0;
        this._totalPreco = 0;
        this._possuiCarrinho = false;
        this._afterAddCarrinho = new Subject();
        this.afterAddCarrinho$ = this._afterAddCarrinho.asObservable();
        this.carrinho$ = new Subject();
        this.afterLogin();
        this.alterarParaCd();
        this.selecionarLoja();
        this.alterarParaCdViaParametro();
    }
    get total_itens() {
        return this._totalItens;
    }
    get total_preco() {
        return this._totalPreco;
    }
    get possuiCarrinho() {
        return this._possuiCarrinho;
    }
    get carrinhoId() {
        return this._carrinhoId;
    }
    get logged() {
        return this.clienteService.logged();
    }
    get msgSubstituirProdutos() {
        return 'Alguns produtos estão indisponíveis e não puderam ser adicionados ao carrinho. Deseja substituí-los?';
    }
    afterLogin() {
        let cdOriginal = this.localStorageService.get('cdOriginal');
        let cdPadrao = this.localStorageService.get('cdSelecionado');
        let verificouCd = false;
        this.clienteService.afterLogin$.subscribe(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.carrinhoAnonimoService.permitirCarrinhoAnonimo) {
                yield this.verificarExisteCarrinhoAnterior();
            }
            else {
                this.carrinhoAnonimoService.setCarrinhoAnonimoVerificado(true);
                this.setStorageCarrinhoAnonimoVerificado(true);
                this.buscaCdPadrao();
            }
            verificouCd = true;
        }));
        if (cdOriginal && cdOriginal != cdPadrao && !verificouCd) {
            this.buscaCdPadrao();
        }
    }
    buscaCdPadrao() {
        const cdAlteradoPeloUsuario = this.cdService && this.cdService.cdConfirmado;
        let cdId = null;
        let cdSelecionado = this.cdService.cdSelecionado;
        if (cdAlteradoPeloUsuario) {
            cdId = this.cdService.id;
        }
        this.cdService.buscaCdPadrao(cdId).subscribe((res) => {
            this.verificarLimiteCDs(res, cdSelecionado);
        });
    }
    verificarLimiteCDs(res, cdOriginal) {
        const cdAlterado = this.cdService && this.cdService.cdConfirmado;
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${res.data.id}`;
        let totalItensLimite = 0;
        let possuiCarrinhoComLimite = false;
        return this.httpClient.get(`${environment.apiUrl}/loja/${URI}`)
            .subscribe((resp) => {
            this.localStorageService.set('cdOriginal', resp.data && 'centro_distribuicao_id' in resp.data
                ? resp.data.centro_distribuicao_id
                : res.data.id);
            totalItensLimite = resp.data ? resp.data.quantidade : 0;
            possuiCarrinhoComLimite = resp.data && 'carrinho_id' in resp.data;
            this._carrinhoId = resp.data && 'carrinho_id' in resp.data ? resp.data.carrinho_id : null;
            if (cdOriginal.limite_itens_carrinho && totalItensLimite > cdOriginal.limite_itens_carrinho) {
                let limiteCarrinho = cdOriginal.limite_itens_carrinho;
                let cdAtual = res.data.id;
                let cdPrimario = cdOriginal.id;
                this.localStorageService.set('limite_carrinho', JSON.stringify({
                    totalItensLimite,
                    limiteCarrinho,
                    cdAtual,
                    cdPrimario
                }));
                this.limiteCdService.abrirLimiteCd(true);
            }
            else {
                if (this.alterarLojaService.cdSelecionadoViaParametro) {
                    return;
                }
                const alterarCarrinho = cdAlterado && res.data.id !== this.cdService.id && this.confimarAlteracaoCdLogin();
                if (alterarCarrinho) {
                    this.alteraCarrinhoParaCd(res.data.id, this.cdService.id);
                }
                else {
                    this.cdService.alterarParaCd(res.data, false);
                }
            }
        });
    }
    confimarAlteracaoCdLogin() {
        return this.cdService.confirmarAlteracaoCd(MensagemAlteracaoCd.MENSAGEM_LOGIN);
    }
    alterarParaCd() {
        this.cdService.alterarParaCd$.subscribe(info => {
            if (info.lojaAlterada && info.montarCarrinho) {
                const idLojaOrigem = info.lojaOrigem.id;
                const idLojaDestino = info.lojaDestino.id;
                return this.alteraCarrinhoParaCd(idLojaOrigem, idLojaDestino);
            }
        });
    }
    selecionarLoja() {
        this.cdService.selecionarLoja$.subscribe(() => {
            const carrinhoAnonimoVerificado = this.localStorageService.get('carrinhoAnonimoVerificado');
            if (this.carrinhoAnonimoService.permitirCarrinhoAnonimo || carrinhoAnonimoVerificado) {
                this._afterAddCarrinho.next({ success: true });
            }
            this.atualizaCarrinhoCookie();
        });
    }
    eventTrack(label) {
        this.angulartics2.eventTrack.next({
            action: 'AddToCart',
            properties: {
                label
            }
        });
    }
    openLogin() {
        if (!!this.localStorageService.get('operador')) {
            this.router.navigate(['/televendas']);
        }
        else {
            if (this.modalService.getModalsCount() === 0) {
                const modalRef = this.modalService.show(LoginModalComponent, {
                    keyboard: true,
                    class: 'custom-modal w280',
                    ignoreBackdropClick: false
                });
                modalRef.content.goToHome = false;
            }
        }
    }
    remover() {
        return window.confirm('Tem certeza que deseja excluir este item?');
    }
    listarBrindes() {
        const params = new HttpParams().set('cd', String(this.cdService.id));
        return this.httpClient
            .get(`/campanha-brindes/listar/${environment.filialId}`, {
            params: params
        });
    }
    addCarrinhoCookie(tipoAdd, itemId, quantidade, tipoProduto) {
        this.localStorageService.set('carrinho', JSON.stringify({
            tipoAdd,
            itemId,
            quantidade,
            tipoProduto
        }));
    }
    removeCarrinhoCookie() {
        this.localStorageService.remove('carrinho');
    }
    atualizaCarrinhoCookie() {
        setTimeout(() => {
            const carrinhoCookie = this.localStorageService.get('carrinho');
            if (carrinhoCookie) {
                const carrinho = JSON.parse(carrinhoCookie);
                if (carrinho.tipoAdd === 'receita') {
                    return this.addListaIngredientes(this.cdService.id, carrinho.itemId);
                }
                return this.addItem(this.cdService.id, carrinho.itemId, carrinho.quantidade, carrinho.tipoProduto);
            }
        }, 500);
    }
    buscarCarrinho(cdId) {
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
        return this.httpClient.get(`${environment.apiUrl}/loja/${URI}`).pipe(map(res => {
            this._totalItens = res.data ? res.data.quantidade : 0;
            this._totalPreco = res.data ? res.data.preco : 0;
            this._possuiCarrinho = res.data && 'carrinho_id' in res.data;
            this.carrinhoAnonimoService.carrinhoIniciado = this._possuiCarrinho;
            this._carrinhoId = res.data && 'carrinho_id' in res.data ? res.data.carrinho_id : null;
            this.carrinho$.next(res);
            return res;
        }));
    }
    buscarCarrinhoItens(cdId, params) {
        const query = !!params ? this.convertParams(params) : '';
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdId}/itens`;
        return this.httpClient.get(`${environment.apiUrl}/loja/${URI}${query}`);
    }
    removeProdutosDesativados(cdId) {
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdId}/verifica_itens_desativados`;
        this.httpClient.post(`${environment.apiUrl}/loja/${URI}`, {}).toPromise()
            .then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (res.data.success) {
                return alert(res.data.mensagem);
            }
        }))
            .catch((err) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (typeof err.error.error.carrinho_id !== 'undefined') {
                this.cdService.buscaEAlteraCd(err.error.error.centro_distribuicao_id);
            }
        }));
    }
    convertParams(params) {
        if (!params || Object.keys(params).length === 0) {
            return '';
        }
        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');
        return `?${queryString}`;
    }
    getIdTipoProduto(tipo) {
        if (typeof tipo !== 'string') {
            return tipo;
        }
        let idTipoProduto = 1;
        if (tipo === 'combo') {
            idTipoProduto = 2;
        }
        else if (tipo === 'oferta-atacarejo') {
            idTipoProduto = 3;
        }
        return idTipoProduto;
    }
    adicionarProduto(cdId, itemId, qtd, tipoProduto) {
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdId}/atualizar_item`;
        const addItem$ = this.httpClient
            .post(`${environment.apiUrl}/loja/${URI}`, {
            tipo: this.getIdTipoProduto(tipoProduto),
            mercadoria_id: itemId,
            quantidade: qtd
        })
            .subscribe(res => {
            this.eventTrack('Produto');
            addItem$.unsubscribe();
            this._afterAddCarrinho.next(res);
        }, err => {
            if ('error' in err) {
                if (typeof err.error.error.message !== 'undefined') {
                    window.alert(err.error.error.message);
                }
                else {
                    window.alert('Você já possui um carrinho iniciado em outra loja');
                }
                if (typeof err.error.error.carrinho_id !== 'undefined') {
                    this.cdService.buscaEAlteraCd(err.error.error.centro_distribuicao_id);
                }
            }
            this._afterAddCarrinho.next({ success: false });
        });
    }
    adicionarMultiplosProdutos(cdId, itens) {
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdId}/atualizar_varios`;
        return this.httpClient
            .post(`${environment.apiUrl}/loja/${URI}`, itens)
            .pipe(map(res => {
            this.eventTrack('Vários itens');
            this._afterAddCarrinho.next({ success: true, data: res.data });
            return res;
        }), catchError(err => {
            return throwError(err.error.error);
        }));
    }
    adicionarListaIngredientes(cdId, listaId) {
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdId}/incluir_lista_ingredientes`;
        const addItem$ = this.httpClient
            .post(`${environment.apiUrl}/loja/${URI}`, {
            lista_ingrediente_id: listaId,
        })
            .subscribe((res) => {
            this.eventTrack('Lista ingredientes');
            addItem$.unsubscribe();
            alert('Produtos adicionados ao carrinho.');
            this._afterAddCarrinho.next(res);
        }, (err) => {
            if ('error' in err) {
                window.alert(err.error.error);
            }
            this._afterAddCarrinho.next({ success: false });
        });
    }
    adicionarCompraId(cdId, compraId) {
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdId}/incluir_compra_anterior`;
        return this.httpClient
            .post(`${environment.apiUrl}/loja/${URI}`, {
            compra_id: compraId
        })
            .pipe(map(res => {
            this.eventTrack('Compra');
            this._afterAddCarrinho.next(res);
            return res;
        }), catchError(err => {
            return throwError(err.error.error);
        }));
    }
    adicionarMinhaLista(cdId, listaId) {
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdId}/incluir_minha_lista`;
        return this.httpClient
            .post(`${environment.apiUrl}/loja/${URI}`, {
            minha_lista_id: listaId
        })
            .pipe(map(res => {
            this._afterAddCarrinho.next({ success: true });
            return res;
        }), catchError(err => {
            return throwError(err.error.error);
        }));
    }
    permiteAdicionar() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const possuiPedido = yield this.possuiPedido();
            return this.logged && !possuiPedido && this.cdService.lojaJaSelecionada;
        });
    }
    buscaPedidoEmProcesso() {
        return this.clienteService.getPedidoEmProcesso().toPromise();
    }
    possuiPedido() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.logged) {
                return false;
            }
            const resPedido = yield this.buscaPedidoEmProcesso();
            const possuiPedido = 'pedido' in resPedido && !!resPedido.pedido;
            if (possuiPedido) {
                alert('Sua compra está em processo de finalização.');
                this.router.navigate(['/checkout/pagamento']);
            }
            return possuiPedido;
        });
    }
    validaAddCarrinho() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const permiteAdicionar = yield this.permiteAdicionar();
            if (permiteAdicionar &&
                (this._possuiCarrinho || this.cdService.cdConfirmado)) {
                return true;
            }
            if (!this._possuiCarrinho && !this.cdService.cdConfirmado) {
                this.cdService.abrirSelecionarLoja();
                return false;
            }
            if (!this.logged && !this.carrinhoAnonimoService.permitirCarrinhoAnonimo) {
                this.openLogin();
                return false;
            }
            if (!this.logged && this.carrinhoAnonimoService.permitirCarrinhoAnonimo) {
                return true;
            }
            return false;
        });
    }
    addListaIngredientes(cdId, listaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const validaAddCarrinho = yield this.validaAddCarrinho();
            if (validaAddCarrinho) {
                return this.adicionarListaIngredientes(cdId, listaId);
            }
            this.addCarrinhoCookie('receita', listaId);
        });
    }
    addMinhaLista() {
        return this.validaAddCarrinho();
    }
    addItem(cdId, itemId, quantidade, tipoProduto) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const validaAddCarrinho = yield this.validaAddCarrinho();
            if (validaAddCarrinho) {
                this.removeCarrinhoCookie();
                return this.adicionarProduto(cdId, itemId, quantidade, tipoProduto);
            }
            this.addCarrinhoCookie('produto', itemId, quantidade, tipoProduto);
        });
    }
    addMultiplosItens(cdId, itens) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const validaAddCarrinho = yield this.validaAddCarrinho();
            if (validaAddCarrinho) {
                this.removeCarrinhoCookie();
                return this.adicionarMultiplosProdutos(cdId, itens).toPromise();
            }
            return Promise.reject();
        });
    }
    addCompraId(cdId, compraId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const validaAddCarrinho = yield this.validaAddCarrinho();
            if (validaAddCarrinho) {
                return this.adicionarCompraId(cdId, compraId).toPromise();
            }
            return Promise.reject();
        });
    }
    apagarItem(cdId, itemId) {
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdId}/remover_item/${itemId}`;
        const apagarItem$ = this.httpClient
            .delete(`${environment.apiUrl}/loja/${URI}`)
            .subscribe(res => {
            this._afterAddCarrinho.next(res);
            apagarItem$.unsubscribe();
        });
    }
    apagarCarrinnho(carrinhoId) {
        const URI = `carrinhos/${carrinhoId}`;
        return this.httpClient
            .delete(`${environment.apiUrl}/loja/${URI}`)
            .pipe(map(res => {
            this._possuiCarrinho = false;
            this.carrinhoAnonimoService.carrinhoIniciado = false;
            return res;
        }));
    }
    alterarCarrinhoPedidoEmProcesso(subscriber) {
        if (!this.logged && this.carrinhoAnonimoService.permitirCarrinhoAnonimo) {
            subscriber.next();
            subscriber.unsubscribe();
            return;
        }
        this.buscaPedidoEmProcesso().then((res) => {
            if (res.pedido && 'id' in res.pedido) {
                return this.compraService.cancelar(res.pedido.id).then(() => {
                    this.compraService.continuarComprando(res.pedido).subscribe(() => {
                        subscriber.next();
                        subscriber.unsubscribe();
                    });
                });
            }
            else {
                subscriber.next();
                subscriber.unsubscribe();
            }
        });
    }
    alteraCarrinhoParaCd(cdIdOrigem, cdIdDetino) {
        const observer = new Observable(subscriber => {
            this.alterarCarrinhoPedidoEmProcesso(subscriber);
        });
        observer.subscribe(() => {
            const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdIdOrigem}/alterar_centro_distribuicao`;
            const alteraCarrinhoParaCd$ = this.httpClient
                .post(`${environment.apiUrl}/loja/${URI}`, {
                novo_cd_id: cdIdDetino
            })
                .subscribe(() => {
                alteraCarrinhoParaCd$.unsubscribe();
                this.atualizaCarrinhoCookie();
                this._afterAddCarrinho.next({
                    success: true,
                    atualizaPedido: true
                });
                this.alterarLojaService.alterouCarrinhoParaCd.next();
            });
        });
    }
    limparInfoCarrinho() {
        this._totalItens = 0;
        this._totalPreco = 0;
    }
    showMsgErrorQtMax() {
        alert('Quantidade superior ao máximo disponível.');
    }
    calcQtMaxAdd(qtMaxProduct, offer, tipoOferta, qtMaxOffer) {
        let qtMax = Number(qtMaxProduct);
        if (offer && tipoOferta !== this.TIPO_OFERTA_COMBO && Number(qtMaxOffer) <= qtMax) {
            qtMax = qtMaxOffer;
        }
        return Number(qtMax);
    }
    atualizarObservacao(cdId, itemId, tipoProduto, quantidade, observacao) {
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdId}/atualizar_item`;
        this.httpClient
            .post(`${environment.apiUrl}/loja/${URI}`, {
            mercadoria_id: itemId,
            tipo: this.getIdTipoProduto(tipoProduto),
            quantidade,
            observacao
        })
            .subscribe(() => { }, err => {
            if ('error' in err) {
                if (typeof err.error.error.message !== 'undefined') {
                    window.alert(err.error.error.message);
                }
                else {
                    window.alert('Você já possui um carrinho iniciado em outra loja');
                }
                if (typeof err.error.error.carrinho_id !== 'undefined') {
                    this.cdService.buscaEAlteraCd(err.error.error.centro_distribuicao_id);
                }
            }
        });
    }
    escolherCarrinhoCliente() {
        if (this.modalService.getModalsCount() === 0) {
            const modalRef = this.modalService.show(EscolherCarrinhoClienteComponent, {
                keyboard: false,
                class: 'custom-modal w350',
                ignoreBackdropClick: true,
            });
            modalRef.content.onHide = res => this.carrinhoAnonimoService.emitirEventoEscolhaCarrinhoCliente(res);
        }
    }
    verificarExisteCarrinhoAnterior() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = `${environment.apiUrl}/loja/carrinhos/filial/${environment.filialId}/centro_distribuicao/${this.cdService.id}/situacao_carrinho_sessao_cliente`;
            this.httpClient.get(url).toPromise()
                .then((resultado) => {
                this.verificarSituacaoCarrinhoAnonimoAposLogin(resultado.data);
            });
        });
    }
    verificarSituacaoCarrinhoAnonimoAposLogin({ sincronizar, possui_carrinho_cliente, bebida_alcoolica_menor_idade_removida }) {
        if (sincronizar) {
            this.setStorageCarrinhoAnonimoVerificado(true);
            this.sincronizarCarrinhoAnonimoCliente();
        }
        else if (possui_carrinho_cliente) {
            setTimeout(() => {
                this.carrinhoAnonimoService.emitirEventoSincronizarCarrinhoAnonimoCliente(this.carrinhoAnonimoService.possuiCarrinhoCliente);
            }, 1000);
        }
        else {
            this.setStorageCarrinhoAnonimoVerificado(true);
            this.buscaCdPadrao();
        }
        this.carrinhoAnonimoService.verificarBebidaAlcoolica(bebida_alcoolica_menor_idade_removida);
    }
    setStorageCarrinhoAnonimoVerificado(value) {
        this.localStorageService.set('carrinhoAnonimoVerificado', value);
    }
    sincronizarCarrinhoAnonimoCliente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.carrinhoAnonimoService.setCarrinhoAnonimoVerificado(true);
            const url = `${environment.apiUrl}/loja/carrinhos/filial/${environment.filialId}/centro_distribuicao/${this.cdService.id}/sincroniza_carrinho_sessao_cliente`;
            this.httpClient.post(url, {}).toPromise()
                .then((resultado) => {
                if (resultado.data.sincronizado) {
                    this.carrinhoAnonimoService.emitirEventoSincronizarCarrinhoAnonimoCliente(this.carrinhoAnonimoService.sincronizar);
                    this._afterAddCarrinho.next({ success: true, atualizaPedido: true });
                    this.carrinhoAnonimoService.verificarCompraExcluida(resultado.data.pedido_cancelado);
                    this.buscaCdPadrao();
                }
                else {
                    this.carrinhoAnonimoService.mostrarMensagem('Não foi possível vincular o carrinho ao seu login');
                    this.carrinhoAnonimoService.redirecionarHome();
                }
            });
        });
    }
    manterCarrinhoExistente() {
        this.carrinhoAnonimoService.setCarrinhoAnonimoVerificado(true);
        const url = `${environment.apiUrl}/loja/carrinhos/filial/${environment.filialId}/mantem_apenas_carrinho_cliente_logado`;
        this.httpClient.post(url, {}).toPromise()
            .then((resultado) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.alterarLojaService.salvarLojaSelecionada(resultado.data.cd);
            yield this.compraService.validarProcessoCompra();
            this.carrinhoAnonimoService.emitirEventoSincronizarCarrinhoAnonimoCliente(this.carrinhoAnonimoService.sincronizar);
            this._afterAddCarrinho.next({ success: true, atualizaPedido: true });
        }))
            .catch(() => {
            this.carrinhoAnonimoService.mostrarMensagem('Não foi possível processar sua requisição');
            this.carrinhoAnonimoService.redirecionarHome();
        });
    }
    alterarParaCdViaParametro() {
        this.alterarLojaService.alterarCarrinhoParaCd$.subscribe(({ antigoCdId, novoCdId }) => {
            this.alteraCarrinhoParaCd(antigoCdId, novoCdId);
        });
    }
}
CarrinhoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CarrinhoService_Factory() { return new CarrinhoService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.Angulartics2), i0.ɵɵinject(i4.ClienteService), i0.ɵɵinject(i5.CentroDistribuicaoService), i0.ɵɵinject(i6.LimiteCdService), i0.ɵɵinject(i7.Router), i0.ɵɵinject(i8.BsModalService), i0.ɵɵinject(i9.CompraService), i0.ɵɵinject(i10.AlterarLojaService), i0.ɵɵinject(i11.CarrinhoAnonimoService)); }, token: CarrinhoService, providedIn: "root" });
