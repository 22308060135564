import { Environment } from './../../environments/model';
import { environment } from 'src/environments/environment';
import { RecuperarSenhaModalComponent } from './../recuperar-senha-modal/recuperar-senha-modal.component';
import { Router } from '@angular/router';
import { ClienteService } from './../clientes/shared/cliente.service';
import { BsModalService } from 'ngx-bootstrap';
import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
import { CriarContaModalComponent } from '../criar-conta-modal/criar-conta-modal.component';
import { CriarSenhaMarketplaceModalComponent } from '../criar-senha-marketplace-modal/criar-senha-marketplace-modal.component';
import { Subject } from 'rxjs';
import { FilialService } from '../filiais/shared/filial.service';
import { ParametroService } from '../parametros/shared/parametro.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginCelularModalComponent } from './login-celular-modal/login-celular-modal.component';

const BAD_REQUEST = 400;
const UNAUTHORIZED = 401;

@Component({
  styleUrls: ['./login-modal.component.css'],
  templateUrl: './login-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LoginModalComponent implements OnInit {
  public modalLevel = 1;
  public user: any = {};
  public loading = false;
  public goToHome = true;
  public bloquearBotaoLogin = false;
  public env: Environment;
  public validacaoBot = 'login-cliente';
  public pinAtivo = false;

  public closeEvent$: Subject<any> = new Subject();

  public constructor(
    private modalService: BsModalService,
    private clienteService: ClienteService,
    private router: Router,
    private filialService: FilialService,
    private parametrosService: ParametroService
  ) {}

  @ViewChild('captchaRef', { static: true }) captchaRef;

  public ngOnInit() {
    this.env = environment;
    this.checkPinAtivo();
  }
  public closeButton() {
    this.closeEvent$.next(true);
    this.close();
  }

  private checkPinAtivo() {
    this.parametrosService.buscarValidacaoPin().then(({pin_ativo}) => {
      this.pinAtivo = pin_ativo;
    });
  }

  public close() {
    this.modalService._hideModal(this.modalLevel);
  }

  public add(captcha?: any) {
    if ((!captcha && this.env.showCaptcha) || this.validacaoBot !== 'login-cliente') {
      this.bloquearBotaoLogin = true;
      return;
    }

    this.login(captcha);
  }

  public openLoginMobileModal() {
    this.close();
    this.modalService.show(LoginCelularModalComponent, {
      keyboard: true,
      class: 'custom-modal w280',
      ignoreBackdropClick: false,
    });
  }

  public openCreateAccountModal() {
    this.close();
    const modal = this.modalService.show(CriarContaModalComponent, {
      keyboard: true,
      class: 'custom-modal w280',
      ignoreBackdropClick: false,
    });
    modal.content.closeEvent$.subscribe(() => this.closeEvent$.next(true));
  }

  public openForgotPasswordModal() {
    this.close();
    const modal = this.modalService.show(RecuperarSenhaModalComponent, {
      keyboard: true,
      class: 'custom-modal',
      ignoreBackdropClick: false,
    });
    modal.content.closeEvent$.subscribe(() => this.closeEvent$.next(true));
  }

  public openRegisterPasswordModal(marketplace: string) {
    this.close();
    const modal = this.modalService.show(CriarSenhaMarketplaceModalComponent, {
      keyboard: true,
      class: 'custom-modal',
      ignoreBackdropClick: false,
    });
    modal.content.marketplace = marketplace;
    modal.content.closeEvent$.subscribe(() => this.closeEvent$.next(true));
  }

  public login(captcha?: any) {
    this.loading = true;
    if (captcha) {
      this.user.captchaToken = captcha;
    }
    this.clienteService
      .login(this.user)
      .then((success) => {
        if (success) {
          this.user = {};
          window.dispatchEvent(new Event('atualizarCliente'));
          setTimeout(() => {
            window.dispatchEvent(new Event('atualizarCarrinhoCliente'));
          }, 2000);

          // Atualizar produtos da home
          window.dispatchEvent(new Event('atualizarProdutosHome'));
          this.close();
          if (this.goToHome) {
               this.parametrosService.buscarValidacaoPin().then(({pin_ativo}) => {
                    if(pin_ativo){
                        this.clienteService.consultaSolicitacaoSms().then(({telefone_celular_validado}) => {
                            if (!telefone_celular_validado) {
                                this.router.navigate(['/clientes/verificar-celular']);
                            }else {
                                this.router.navigate(['/']);
                            }
                        }).catch((err) => {
                            if(err instanceof HttpErrorResponse){
                                this.router.navigate(['/clientes/verificar-celular']);
                            }
                        });;
                    }
                    else this.router.navigate(['/']);
                })

          }
        } else {
          this.clearInfoLogin();
        }
        this.loading = false;
        this.loginGoogleAnalytics();
      })
      .catch((data) => {
        this.loading = false;
        this.clienteService.logout();
        this.clearInfoLogin();
        if (data.status === UNAUTHORIZED) {
          this.openRegisterPasswordModal(data.error.error.marketplace);
        } else {
          alert('Login inválido. Verifique o usuário e a senha e tente novamente.');
        }
      });
  }
  private loginGoogleAnalytics() {
    if(this.filialService.detail.ga4) {
      gtag('event', 'login', {
        method: 'Site'
      });
    }
  }

  public validaLogin() {
    if (!this.env.showCaptcha) {
      return;
    }

    this.captchaRef.reset();
    this.captchaRef.execute();
  }

  private clearInfoLogin() {
    this.user.password = '';
  }

  public onChangeLogin() {
    const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!EMAIL_REGEX.test(this.user.usuario)) {
      const login = this.user.usuario.replace(/\W/g, '');
      if (login.length === 11) {
        this.user.usuario = this.replaceCpf(login);
      } else if (login.length === 14) {
        this.user.usuario = this.replaceCnpj(login);
      }
    }
  }

  private replaceCpf(value) {
    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
  }

  private replaceCnpj(value) {
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5');
  }
}
