<div class="container" style="position: relative" *ngIf="show_banners">
  <div class="row">
    <ngu-carousel
      #carousel
      [inputs]="carouselConfig"
      [dataSource]="banners"
      (mouseenter)="pauseTimeout()"
      (mouseleave)="resumeTimeout()"
    >
      <div *nguCarouselDef="let banner" class="item">
        <div class="tile">
          <ng-container [ngSwitch]="banner.link_externo">
            <ng-container *ngSwitchCase="'s'">
              <a
                [href]="banner.link"
                target="{{ banner.nova_janela == false ? '_self' : '_blank' }}"
                class="banner-link"
                (click)="countCLick(banner.id)"
              >
                <img
                  class="img-responsive"
                  (error)="onError(banner.banner_imagens, i)"
                  [src]="getUrl(imagem)"
                  alt="{{ banner.descricao }}"
                  [ngClass]="'banner-size-' + imagem.largura"
                  *ngFor="let imagem of banner.banner_imagens; let i = index"
                />
              </a>
            </ng-container>
            <ng-container *ngSwitchCase="'n'">
              <a
                [routerLink]="banner.link_banner"
                target="{{ banner.nova_janela == false ? '_self' : '_blank' }}"
                class="banner-link"
                (click)="countCLick(banner.id)"
              >
                <img
                  class="img-responsive"
                  (error)="onError(banner.banner_imagens, i)"
                  [src]="getUrl(imagem)"
                  alt="{{ banner.descricao }}"
                  [ngClass]="'banner-size-' + imagem.largura"
                  *ngFor="let imagem of banner.banner_imagens; let i = index"
                />
              </a>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <a
                href="javascript:void(0)"
                class="banner-link"
                (click)="countCLick(banner.id)"
              >
                <img
                  class="img-responsive"
                  (error)="onError(banner.banner_imagens, i)"
                  [src]="getUrl(imagem)"
                  alt="{{ banner.descricao }}"
                  [ngClass]="'banner-size-' + imagem.largura"
                  *ngFor="let imagem of banner.banner_imagens; let i = index"
                />
              </a>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <ul class="point" NguCarouselPoint>
        <li
          *ngFor="let j of carousel.pointNumbers; let i = index"
          [class.active]="j == carousel.activePoint"
          (click)="carousel.moveTo(i)"
        ></li>
      </ul>

      <button NguCarouselPrev class="leftRs">&lt;</button>
      <button NguCarouselNext class="rightRs">&gt;</button>
    </ngu-carousel>
  </div>
</div>
