import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpProduto } from 'src/app/produtos/shared/httpProduto';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class CombosService {
  private _combos: Array<any> = [];
  public get combos(): Array<any> {
    return this._combos;
  }

  constructor(private httpClient: HttpClient, private router: Router,) {}

  public menu() {
    return this.httpClient
      .get('/menus/combos/' + environment.filialId)
      .toPromise()
      .then((data: any) => {
        this._combos = data.combos;
        window.dispatchEvent(new Event('atualizarCombos'));
      });
  }

  buscarCombos(cdId: number) {
    const URI = `classificacoes_mercadologicas/departamentos/arvore/combos/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
    return this.httpClient.get<any>(`${environment.apiUrl}/loja/${URI}`);
  }

  buscaCombosDepartamento(cdId: number, cmId: number) {
    const URI = `classificacoes_mercadologicas/departamentos/${cmId}/combos/filial/${environment.filialId}/centro_distribuicao/${cdId}/ativos`;
    return this.httpClient.get<HttpProduto>(
      `${environment.apiUrl}/loja/${URI}`
    );
  }

  buscarCombo(cdId: number, ofertaId: number) {
    const URI = `combos/${ofertaId}/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
    return this.httpClient.get<HttpProduto>(
      `${environment.apiUrl}/loja/${URI}`
    ).pipe(
      map((res: any) => {
        if (!res.data) {
          this.router.navigate(['/404']);
        }
        return { data: [res.data] };
      })
    );
  }
}
