import { Injectable } from '@angular/core';
import { TabsBar } from './tabs-bar';
import { Subject } from 'rxjs';

@Injectable()
export class TabsBarService {
  public set filter(filtrar: boolean) {
    this._filtro$.next(filtrar);
  }

  private links: Array<TabsBar> = [];
  private marcas: any;

  public alteraMarcas$: Subject<any> = new Subject();
  public alteraLinks$: Subject<any> = new Subject();
  public visibilidade$: Subject<any> = new Subject();

  private _filtro$: Subject<any> = new Subject();
  public filtro$ = this._filtro$.asObservable();

  public addLink(titulo, url?) {
    this.links.push({
      titulo,
      url
    });
    this.alteraLinks$.next(this.links);
  }

  public setLinks(links: Array<TabsBar>) {
    this.links = links;
    this.alteraLinks$.next(this.links);
  }

  public show() {
    this.visibilidade$.next(true);
  }

  public hide() {
    this.visibilidade$.next(false);
  }

  public setMarcas(marcas: Array<any>) {
    this.marcas = marcas;
    this.alteraMarcas$.next(this.marcas);
  }

  public showFilter() {
    this._filtro$.next(true);
  }

  public hideFilter() {
    this._filtro$.next(false);
  }
}
