import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { ProdutoDetalheService } from 'src/app/produtos/produto-detalhe/shared/produto-detalhe.service';
import { HttpProduto } from 'src/app/produtos/shared/httpProduto';

import { Produto } from '../../../produtos/shared/produto';
import { MinhasListas } from './../../interfaces/minhas-listas';

@Component({
  styleUrls: ['./minhas-listas-replace-options.component.css'],
  templateUrl: './minhas-listas-replace-options.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [ProdutoDetalheService]
})
export class MinhasListasReplaceOptionsComponent implements OnInit {
  public list: MinhasListas = <MinhasListas>{};
  public title: string = 'Substituir Produtos - Opções';
  public link: string;
  public modalLevel: number = 1;
  public onHide: Function = res => {};
  public products: Produto[] = [];

  public cdId: number;
  public produtoId: number;

  compare$: Observable<HttpProduto>;
  similares$: Observable<HttpProduto>;

  public constructor(
    private modalService: BsModalService,
    private produtoDetalheService: ProdutoDetalheService
  ) {}

  public ngOnInit() {
    const interval = setInterval(() => {
      if (this.produtoId && this.cdId) {
        clearInterval(interval);
        this.compare$ = this.produtoDetalheService.getComparacao(
          this.produtoId,
          this.cdId
        );
        this.similares$ = this.produtoDetalheService.getSimilares(
          this.produtoId,
          this.cdId
        );
      }
    }, 300);
  }

  public select(produto: Produto) {
    this.close(produto);
  }

  public close(product?: any) {
    this.modalService._hideModal(this.modalLevel);
    this.onHide(product);
  }
}
