<div class="produtos-indisponiveis-lista" *ngIf="products.length">
  <header class="title">
    <h4>{{ title }}</h4>
    <p class="subtitle">{{ legend }}</p>
  </header>
  <div class="row vip-products">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="row">
        <ng-container *ngFor="let product of products; let i = index">
          <ng-container *ngIf="!product.novoProduto?.produto_id">
            <div
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 product {{
                product.infoProduto.mercadoria?.oferta?.classe_oferta
              }}"
            >
              <div class="thumbnail ">
                <div class="border-promotion">
                  <app-produto-imagem
                    [produto]="product.infoProduto.mercadoria"
                    [tamanho]="144"
                    [classe]="'center-block image-product'"
                    preventLink="true"
                  ></app-produto-imagem>

                  <div class="product-not-available drill-price drill-price-md text-success"></div>

                  <div class="clearfix">&nbsp;</div>

                  <div class="caption">
                    <p class="text-success description center-block text-center hidden-xs">
                      <a title="{{ product.infoProduto.mercadoria.descricao }}">
                        {{ product.infoProduto.mercadoria.descricao | slice: 0:50 }}
                      </a>
                    </p>
                    <p class="text-success description center-block text-center visible-xs">
                      <a title="{{ product.infoProduto.mercadoria.descricao }}" >
                        {{ product.infoProduto.mercadoria.descricao | slice: 0:35 }}
                      </a>
                    </p>
                    <p class="text-success description center-block text-center" >
                      {{ product.infoProduto.mensagem }}
                    </p>
                    <div class="replace-btn">
                      <button
                        *ngIf="showButtonByProduct(product)"
                        type="button"
                        (click)="buyProduct(product)"
                        class="btn btn-primary button"
                      >
                        <span>COMPRAR</span>
                      </button>
                      <button
                        type="button"
                        (click)="openProductOptionsModal(product, i)"
                        class="btn btn-primary button"
                      >
                        <span>SUBSTITUIR</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="product.novoProduto?.produto_id">
            <div
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6 product {{
                product.novoProduto.oferta?.classe_oferta
              }}"
            >
              <div class="thumbnail ">
                <div class="border-promotion">
                  <i
                    class="fa fa-undo undo"
                    (click)="undo(i)"
                    aria-hidden="true"
                  ></i>

                  <app-tag-promotion
                    [oferta]="product.novoProduto?.oferta"
                  ></app-tag-promotion>

                  <app-produto-imagem
                    [produto]="product.novoProduto"
                    [tamanho]="144"
                    [classe]="'center-block image-product'"
                    preventLink="true"
                  ></app-produto-imagem>

                  <app-tag-preco
                    [produto]="product.novoProduto"
                    *ngIf="product.novoProduto.disponivel"
                  ></app-tag-preco>

                  <div class="clearfix">&nbsp;</div>

                  <div class="caption">
                    <p class="text-success description center-block text-center hidden-xs" >
                      <a title="{{ product.novoProduto.descricao }}">
                        {{ product.novoProduto.descricao | slice: 0:50 }}
                      </a>
                    </p>
                    <p class="text-success description center-block text-center visible-xs">
                      <a title="{{ product.novoProduto.descricao }}">
                        {{ product.novoProduto.descricao | slice: 0:35 }}
                      </a>
                    </p>

                    <div class="spin">
                      <i
                        (click)="down(product.novoProduto)"
                        class="fa fa-minus-circle text-primary"
                        aria-hidden="true"
                      ></i>
                      <input
                        type="number"
                        min="1"
                        [(ngModel)]="product.novoProduto.quantidade"
                        (keyup.enter)="edit(product.novoProduto)"
                        (blur)="edit(product.novoProduto)"
                      />
                      <i
                        (click)="up(product.novoProduto)"
                        class="fa fa-plus-circle text-primary"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <footer>
    <button
      type="button"
      (click)="saveReplace()"
      [disabled]="loading"
      class="btn btn-checkout button"
    >
      <i class="fa fa-spinner fa-spin" *ngIf="loading" aria-hidden="true"></i>
      <span *ngIf="loading"> AGUARDE</span>
      <span *ngIf="!loading">FINALIZAR</span>
    </button>
  </footer>
</div>
