import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ParametroService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.valorMinimoCompra = 0;
        this.tipoEntregaPadrao = null;
        this.formaFaturamento = { valorFormaFaturamento: '', valorFaturamentoPadrao: '' };
    }
    buscarValorMinimoPermitido() {
        const URI = 'compras/valor_minimo_permitido';
        return this.httpClient
            .get(`${environment.apiUrl}/loja/${URI}`)
            .toPromise()
            .then((res) => (this.valorMinimoCompra = res.data));
    }
    buscarTipoEntregaPadrao() {
        const URI = 'compras/entrega/tipo_entrega_padrao';
        return this.httpClient
            .get(`${environment.apiUrl}/loja/${URI}`)
            .toPromise()
            .then((res) => {
            this.tipoEntregaPadrao = !isNaN(parseInt(res.data)) ? parseInt(res.data) : null;
        });
    }
    buscarFormaFaturamento() {
        const URI = 'compras/checkout/forma_faturamento';
        return this.httpClient
            .get(`${environment.apiUrl}/loja/${URI}`)
            .toPromise()
            .then((res) => {
            this.formaFaturamento = res.data;
        });
    }
    buscarValidacaoPin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const URI = 'clientes/pin_ativo';
            const response = yield this.httpClient.get(`${environment.apiUrl}/loja/${URI}`)
                .toPromise();
            return response.data;
        });
    }
}
ParametroService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParametroService_Factory() { return new ParametroService(i0.ɵɵinject(i1.HttpClient)); }, token: ParametroService, providedIn: "root" });
