import { Injectable } from '@angular/core';
import { Paginacao } from './paginacao';

@Injectable()
export class PaginacaoService {

    public show_paginacao: boolean = true;
    public limit: number = 7;
    public paginacao: Paginacao = <Paginacao> {};

}
