import { Injectable } from '@angular/core';
import { Lista } from './listas';
import { Produto } from '../../../produtos/shared/produto';
import { HttpClient } from '@angular/common/http';
import { HttpLista } from './httpLista';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ListaService {
  public service: string = '/listas';

  public listas: Lista[] = [];
  public produtos: Produto[] = [];
  public lista: Lista = <Lista>{};
  public dados: any;

  constructor(private httpClient: HttpClient) {}

  index() {
    return this.httpClient
      .get(this.service + '/index/' + environment.filialId)
      .toPromise()
      .then((data: any) => {
        this.listas = data.listas;
        window.dispatchEvent(new Event('atualizarListas'));
      });
  }

  view(slug: string) {
    return this.httpClient
      .get(this.service + '/view/' + environment.filialId + '/' + slug)
      .toPromise()
      .then((data: any) => {
        this.lista = data.lista;
        this.produtos = data.produtos;
        this.dados = data.dados;
      });
  }

  comprar(lista: Lista) {
    return this.httpClient.post(
      this.service + '/comprar/' + environment.filialId + '/' + lista.slug,
      lista
    );
  }

  private convertParams(params) {
    if (!params || Object.keys(params).length === 0) {
      return '';
    }

    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    return `?${queryString}`;
  }

  receitaItens(
    cdId: number,
    receitaId: number,
    queryParams?
  ): Observable<HttpLista> {
    const query = this.convertParams(queryParams);
    const URI = `listas/ingredientes/${receitaId}/filial/${environment.filialId}/centro_distribuicao/${cdId}/itens`;
    return this.httpClient.get<HttpLista>(
      `${environment.apiUrl}/loja/${URI}${query}`
    );
  }
}
