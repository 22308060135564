import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-modal-centro-distribuicao-list',
  templateUrl: './modal-centro-distribuicao-list.component.html'
})
export class ModalCentroDistribuicaoListComponent {
  @Input() cds = [];

  constructor(private modalService: BsModalService ){}

  closeModal() {
    this.modalService._hideModal(1);
  }
}
