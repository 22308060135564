import * as tslib_1 from "tslib";
import { environment } from 'src/environments/environment';
import { BsModalService } from 'ngx-bootstrap';
import { OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ClienteService } from 'src/app/clientes/shared/cliente.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { DocumentValidation } from 'src/app/shared/utils/document-validation';
export class LoginCelularModalComponent {
    constructor(modalService, clienteService, router, localStorageService) {
        this.modalService = modalService;
        this.clienteService = clienteService;
        this.router = router;
        this.localStorageService = localStorageService;
        this.modalLevel = 1;
        this.user = {};
        this.closeEvent$ = new Subject();
        this.validacaoBot = 'login-cliente';
        this.bloquearBotaoLogin = false;
        this.cpf = {};
        this.telefoneCelular = {};
        this.loading = false;
    }
    ngOnInit() {
        this.env = environment;
    }
    closeButton() {
        this.closeEvent$.next(true);
        this.close();
    }
    close() {
        this.modalService._hideModal(this.modalLevel);
    }
    validarCPF() {
        if (this.user.cpf && this.user.cpf.length == 14) {
            let cpfValidado = DocumentValidation.validarCpf(this.user.cpf);
            if (cpfValidado && this.user.celular.length == 15) {
                this.bloquearBotaoLogin = false;
            }
            else {
                this.bloquearBotaoLogin = true;
            }
            return cpfValidado;
        }
        return true;
    }
    validarCelular() {
        if (this.user.celular && this.user.celular.length == 15 && DocumentValidation.validarCpf(this.user.cpf)) {
            this.bloquearBotaoLogin = false;
        }
        else {
            this.bloquearBotaoLogin = true;
        }
    }
    solicitarSmsPin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.cpf.value = this.user.cpf.replace(/\D/g, '');
            this.telefoneCelular.value = this.user.celular.replace(/\D/g, '');
            yield this.clienteService.loginViaCelular(this.cpf.value, this.telefoneCelular.value)
                .then((resp) => {
                this.localStorageService.set('cpf', this.cpf.value);
                this.localStorageService.set('telefoneCelular', this.telefoneCelular.value);
                this.localStorageService.set('expire_at', resp.data.expire_at);
                this.localStorageService.set('current_time', resp.data.current_time);
                this.router.navigate(['/clientes/login/celular']);
            }).catch((err) => {
                let erro = false;
                let nao_cadastrado_erro = "Telefone celular não cadastrado para este CPF.";
                let nao_cadastrado_msg = "Telefone celular não cadastrado! \n\nPara fazer login com o celular é necessário Cadastrá-lo. Por favor, entre no site utilizando seu CPF e Senha para cadastrar seu número de telefone.";
                if (err.error.error == nao_cadastrado_erro) {
                    alert(nao_cadastrado_msg);
                    erro = true;
                }
                let nao_validado_erro = "Telefone celular não validado.";
                let nao_validado_msg = "Número de celular não validado! \n\nPara fazer o login com o número de celular é necessário validá-lo. Por favor, entre no site utilizando seu CPF e Senha para validar seu número.";
                if (err.error.error == nao_validado_erro) {
                    alert(nao_validado_msg);
                    erro = true;
                }
                if (!erro) {
                    alert("Login por número de celular indisponível no momento! Por favor acesse utilizando seu email ou CPF e sua senha");
                }
            }).finally(() => {
                this.close();
                this.loading = false;
            });
        });
    }
}
