import { Component, Input } from '@angular/core';
import { Pagina, TipoPagina } from '../../institucional/shared/paginas';

@Component({
  selector: 'app-lista-paginas-link',
  templateUrl: './lista-paginas-link.component.html',
  styleUrls: ['./lista-paginas-link.component.scss']
})
export class ListaPaginasLinkComponent {

  @Input() paginas: Pagina[];
  @Input() ulClass: string;
  @Input() liClass: string;
  @Input() aClass: string;
  TipoPagina = TipoPagina;

}
