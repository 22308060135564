import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { IApiResponse } from "../interfaces/api-response";

@Injectable({
  providedIn: 'root'
})
export class ProdutosPatrocinadosService {
    private chamadaRealizada = false;
    adsAtivo$ = new BehaviorSubject<boolean>(false);
    private request = this.httpClient.get<IApiResponse<{ ativo: boolean }>>(
      `${environment.apiUrl}/ads/ativo`
    );

    constructor(private httpClient: HttpClient) {
      if (!this.chamadaRealizada) {
        this.chamadaRealizada = true;
        this.request.subscribe((response) => {
          if (response.success) {
            this.adsAtivo$.next(response.data.ativo)
          }
        });
      }
    }  
}