<div
  class="alert alert-warning"
  role="alert"
  *ngIf="solicitacao.erro || !solicitacao.sucesso"
>
  <p><strong>Ainda não entregamos neste endereço.</strong></p>
  <p>
    Nos informe seu e-mail para que possamos entrar em contato assim que a
    entrega estiver disponível em sua região.
  </p>
  <div class="alterar-loja__informe-email">
    <div class="form-group">
      <label for="cep-email">Digite seu E-mail</label>
      <input
        type="email"
        id="cep-email"
        placeholder="Digite seu e-mail"
        class="form-control"
        [(ngModel)]="email"
        name="email"
      />
    </div>
    <p class="text-right">
      <button
        type="button"
        class="btn btn-primary"
        (click)="enviarSolicitacao()"
      >
        Confirmar
      </button>
    </p>
  </div>
</div>
<div class="alert alert-success" role="alert" *ngIf="solicitacao.sucesso">
  <p><strong>Obrigado!!!</strong></p>
  <p>Solicitação enviada com sucesso.</p>
</div>
<div class="alert alert-danger" role="alert" *ngIf="solicitacao.erro">
  <p><strong>E-mail informado já cadastrado!!!</strong></p>
  <p>Não foi possível enviar sua solicitação.</p>
</div>
