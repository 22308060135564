import { Meta } from '@angular/platform-browser';
import { AplicativoService } from './aplicativo.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "./aplicativo.service";
export class SmartBannerService {
    constructor(meta, w, aplicativoService) {
        this.meta = meta;
        this.w = w;
        this.aplicativoService = aplicativoService;
    }
    open() {
        if (this.allowOpenSmartBanner()) {
            this.getInfoBanner().subscribe((res) => {
                if (this.hasInfoToOpenStore(res.data)) {
                    this.setInfoSmartBanner(res.data);
                    this.openSmartBanner();
                }
            });
        }
    }
    getInfoBanner() {
        return this.aplicativoService.getInfoAplicativo();
    }
    allowOpenSmartBanner() {
        return this.w.document.cookie.indexOf('smartbanner_exited=1') === -1;
    }
    hasInfoToOpenStore(info) {
        return info && (info.apple_id || info.package);
    }
    openSmartBanner() {
        const w = this.w;
        if ('smartbanner' in w) {
            w.smartbanner.constructor();
            w.smartbanner.publish();
            w.document.getElementsByTagName('html')[0].style.cssText = '';
        }
    }
    setInfoSmartBanner(info) {
        this.meta.addTags([
            {
                name: 'smartbanner:title',
                content: info.titulo
            },
            {
                name: 'smartbanner:author',
                content: 'VipCommerce Sistemas'
            },
            {
                name: 'smartbanner:price-suffix-apple',
                content: ' - Na App Store'
            },
            {
                name: 'smartbanner:price-suffix-google',
                content: ' - No Google Play'
            },
            {
                name: 'smartbanner:icon-apple',
                content: '/assets/icons/android-icon-72x72.png'
            },
            {
                name: 'smartbanner:icon-google',
                content: '/assets/icons/apple-icon-72x72.png'
            },
            {
                name: 'smartbanner:button-url-apple',
                content: `https://apps.apple.com/br/app/${info.apple_id}`
            },
            {
                name: 'smartbanner:button-url-google',
                content: `http://play.google.com/store/apps/details?id=${info.package}`
            },
            {
                name: 'smartbanner:enabled-platforms',
                content: 'android,ios'
            },
            { name: 'smartbanner:price', content: 'Grátis' },
            { name: 'smartbanner:button', content: 'Abrir' },
            { name: 'smartbanner:close-label', content: 'Fechar' }
        ], true);
    }
}
SmartBannerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SmartBannerService_Factory() { return new SmartBannerService(i0.ɵɵinject(i1.Meta), i0.ɵɵinject("Window"), i0.ɵɵinject(i2.AplicativoService)); }, token: SmartBannerService, providedIn: "root" });
