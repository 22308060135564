import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProdutoAvisoService {
  constructor(private httpClient: HttpClient) {}

  salvar(cdId: number, produtoId: number) {
    const URI = `avisos/produto/${produtoId}/filial/${environment.filialId}/centro_distribuicao/${cdId}/quando_chegar`;
    return this.httpClient.post<any>(`${environment.apiUrl}/loja/${URI}`, {});
  }
}
