import { Directive, Input, HostListener, AfterViewInit } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import * as masker from 'vanilla-masker';

@Directive({
  selector: '[mask]',
})
export class MaskDirective implements AfterViewInit {

  @Input() mask: any = '';

  constructor(public control: NgControl) { }

  public ngAfterViewInit() {
    setTimeout(() => {
      const value: any = this.control.value || '';
      this.setMask(value);
    }, 1);
  }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    let value = event.target.value;
    this.setMask(value);
  }

  private setMask(value: any): void {
    if (masker.hasOwnProperty(this.mask)) {
      value = masker[this.mask](value);
    } else {
      if (value.length > this.mask.length) {
        value = value.substring(0, this.mask.length);
      } else {
        value = masker.toPattern(value, this.mask);
      }
    }
    this.control.control.setValue(value);

  }
}
