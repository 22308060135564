import { Injectable } from '@angular/core';
import { Produto } from 'src/app/produtos/shared/produto';
import { ProdutosPatrocinadosService } from './produtos-patrocinados.service';

@Injectable({
    providedIn: 'root',
})
export class CampanhaPatrocinadoService {
    private adsAtivo: boolean;

    constructor(
        private produtosPatrocinadosService: ProdutosPatrocinadosService
    ) {
        this.produtosPatrocinadosService.adsAtivo$.subscribe((adsAtivo) => {
            this.adsAtivo = adsAtivo;
        });
    }

    public clique(produto: Produto) {
        if (
            this.adsAtivo &&
            produto.anunciado &&
            produto.urls_eventos_ads &&
            produto.urls_eventos_ads.url_click
        ) {
            const url = produto.urls_eventos_ads.url_click;
            navigator.sendBeacon(url);
        }
    }

    public impressao(produtos: Produto[]) {
        if (this.adsAtivo)
            produtos.forEach((produto) => {
                if (
                    produto.anunciado &&
                    produto.urls_eventos_ads &&
                    produto.urls_eventos_ads.url_impressao
                ) {
                    const url = produto.urls_eventos_ads.url_impressao;
                    navigator.sendBeacon(url);
                }
            });
    }

    public visualizarProduto(produto: Produto) {
        if (
            this.adsAtivo &&
            produto.anunciado &&
            produto.urls_eventos_ads &&
            produto.urls_eventos_ads.url_visualizacao
        ) {
            const url = produto.urls_eventos_ads.url_visualizacao;
            navigator.sendBeacon(url);
        }
    }
}
