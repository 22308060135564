<div class="app-offline">
  <div class="row">
    <div class="col-md-12">
      <div class="error-template">
        <h1 class="text-center">Oops</h1>
        <h2 class="text-center">Você está offline!</h2>
        <h3 class="text-center">Reconecte-se para continuar navegando</h3>
        <img class="logo" src="assets/img/header-logo.png" alt="Logo" />
      </div>
    </div>
  </div>
</div>
