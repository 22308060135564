import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
export class CompraService {
    constructor(httpClient, cookieService, router) {
        this.httpClient = httpClient;
        this.cookieService = cookieService;
        this.router = router;
        this.service = '/compras';
        this.pedidos = [];
        this.pedido = {};
        this.message = '';
        this.auth_url = '';
        this.paginacao = {};
        this.STATUS_AGUARDANDO_ATENDIMENTO = 9;
        this.STATUS_AGUARDANDO_SEPARACAO = 12;
        this.STATUS_PROCESSO_COMPRA = 16;
        this.STATUS_EM_ENTREGA = 27;
        this.STATUS_AGUARDANDO_CHECKOUT = 30;
        this.STATUS_SOLICITACAO_DE_CANCELAMENTO = 31;
    }
    index(tipo, params) {
        return this.httpClient
            .get(this.service + '/index/' + environment.filialId + '/' + tipo, { params })
            .toPromise()
            .then((data) => {
            this.pedidos = data.pedidos;
            this.paginacao = data.paging;
        });
    }
    view(id) {
        return this.httpClient
            .get(this.service + '/view/' + environment.filialId + '/' + id)
            .toPromise()
            .then((data) => {
            this.pedido = data.pedido;
            this.instrucoes = data.instrucoes;
        });
    }
    add(data, cdId) {
        let meliuz = null;
        try {
            meliuz = JSON.parse(this.cookieService.get('Meliuz'));
        }
        catch (e) { }
        let uri = `${this.service}/add/${environment.filialId}/${cdId}`;
        if (meliuz != null && data.tipo_compra == 'N') {
            return this.httpClient.post(uri + '/' + meliuz.trans_id, data);
        }
        return this.httpClient.post(uri, data);
    }
    edit(data) {
        return this.httpClient.post(this.service + '/edit/' + environment.filialId + '/' + data.id, data);
    }
    deleteItem(data) {
        return this.httpClient.post(this.service + '/delete/' + environment.filialId + '/' + data.id, data);
    }
    cancelarCompraFinalizacao(data) {
        return this.httpClient
            .post(this.service + '/cancelar-compra-finalizacao/' + environment.filialId + '/' + data.id, data);
    }
    last(type) {
        return this.httpClient
            .get(this.service + '/last/' + environment.filialId + '/' + type)
            .toPromise()
            .then((data) => (this.pedido = data.pedido));
    }
    processo() {
        return this.httpClient
            .get(this.service + '/processo/' + environment.filialId)
            .toPromise()
            .then((data) => {
            this.pedido = data.pedido;
            return data;
        });
    }
    finalizarProcesso(body) {
        return this.httpClient
            .post(this.service + '/processo/' + environment.filialId, body)
            .toPromise()
            .then((data) => {
            this.pedido = data.pedido;
            this.message = data.message;
            this.auth_url = data.auth_url;
            this.error = data.error;
        });
    }
    finalizarPagamento(id, pg) {
        return this.httpClient
            .post(this.service + '/retorno-pagamento/' + environment.filialId, {
            compra_id: id,
            pagamento_key: pg
        })
            .toPromise()
            .then((data) => {
            this.status = data.status;
            this.message = data.message;
            this.transacao = data.transacao;
        });
    }
    cancelarPagamento(id, pg) {
        const data = { compra_id: id, id: pg };
        return this.httpClient.post(this.service + '/cancelar-pagamento/' + environment.filialId + '/' + id, data);
    }
    verificarPagamento(id, pg) {
        const data = { compra_id: id, id: pg };
        return this.httpClient.post(this.service + '/verificar-pagamento/' + environment.filialId + '/' + id, data);
    }
    comprarNovamente(pedido) {
        return this.httpClient.post(this.service + '/comprar-novamente/' + environment.filialId + '/' + pedido.id, pedido);
    }
    continuarComprando(pedido) {
        return this.httpClient.post(this.service +
            '/continuar-comprando/' +
            environment.filialId +
            '/' +
            pedido.id, pedido);
    }
    solicitarTrocaDevolucao(tipo) {
        return this.httpClient
            .get(this.service +
            '/solicitar-troca-devolucao/' +
            environment.filialId +
            '/' +
            tipo)
            .toPromise()
            .then((data) => {
            this.pedidos = data.pedidos;
            this.message = data.message;
        });
    }
    detalheTrocaDevolucao(id) {
        return this.httpClient
            .get(this.service + '/detalhe-troca-devolucao/' + environment.filialId + '/' + id)
            .toPromise()
            .then((data) => (this.pedido = data.pedido));
    }
    cancelar(id) {
        return this.httpClient
            .post(this.service + '/cancelar/' + environment.filialId + '/' + id, {})
            .toPromise()
            .then((data) => {
            this.cookieService.delete('pedido');
            window.dispatchEvent(new Event('compraCriada'));
            this.pedido = {};
            return data;
        });
    }
    buscaInfoPedido(cdId, pedidoId) {
        const URI = `listas/compras/${pedidoId}/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
        return this.httpClient.get(`${environment.apiUrl}/loja/${URI}`);
    }
    buscaProdutosCompra(cdId, pedidoId, query) {
        const URI = `listas/compras/${pedidoId}/filial/${environment.filialId}/centro_distribuicao/${cdId}/itens`;
        return this.httpClient.get(`${environment.apiUrl}/loja/${URI}${query}`);
    }
    alterarAplicacaoDesconto(pedido) {
        return this.httpClient
            .post(this.service + '/alterar-aplicacao-desconto/' + pedido.filial_id + '/' + pedido.id, {})
            .toPromise()
            .then((data) => {
            this.pedido = data.pedido;
            return data;
        });
    }
    sincronizaCompraGoogleAnalytics() {
        const URI = `/sincroniza-compra-google-analytics/${this.pedido.id}`;
        return this.httpClient.post(`${this.service}${URI}`, {});
    }
    validarProcessoCompra() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.processo();
            if (this.pedido && this.pedido.id) {
                alert('Sua compra está em processo de finalização.');
                this.router.navigate(['/checkout/pagamento']);
                return;
            }
            return true;
        });
    }
    getFormaPagamentoPorNumeroCartao(cdId, tipoPagamento, numCartao) {
        return this.httpClient
            .post(`/forma_pagamentos/cd/${cdId}/tipo_pagamento/${tipoPagamento}`, { bin: numCartao })
            .toPromise();
    }
}
