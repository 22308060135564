import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TermosUsoService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.aprovacaoPendente = false;
        this.service = `${environment.apiUrl}/loja/termos_uso`;
    }
    getPossuiTermoUsoPendente(filialId) {
        return this.httpClient
            .get(`${this.service}/filial/${filialId}/aprovacao_pendente`)
            .toPromise()
            .then((res) => {
            this.aprovacaoPendente = res.data;
        });
    }
    getTermoUsoVigente(filialId) {
        return this.httpClient
            .get(`${this.service}/filial/${filialId}/vigente`)
            .toPromise()
            .then((res) => {
            this.termoUso = res.data;
        });
    }
    aprovarTermoUso(termoUsoId) {
        return this.httpClient
            .post(`${this.service}/${termoUsoId}/aprovar`, {})
            .toPromise();
    }
}
TermosUsoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TermosUsoService_Factory() { return new TermosUsoService(i0.ɵɵinject(i1.HttpClient)); }, token: TermosUsoService, providedIn: "root" });
