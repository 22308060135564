import {Component, Input, OnInit} from '@angular/core';
import {CompraService} from '../../pedidos/shared/compra.service';
import {CookieService} from 'ngx-cookie-service';
import {Compra} from '../../pedidos/shared/compras';
import {BsModalService} from 'ngx-bootstrap';
import {Router} from '@angular/router';

@Component({
  selector: 'app-checkout-pagamento-solicitar-cancelamento',
  templateUrl: './checkout-pagamento-solicitar-cancelamento.component.html',
  styleUrls: ['./checkout-pagamento-solicitar-cancelamento.component.css'],
  providers: [CompraService, CookieService]
})
export class CheckoutPagamentoSolicitarCancelamentoComponent implements OnInit {
  @Input() public compra: Compra = <Compra>{};
  @Input() public finalizacao: boolean = false;

  constructor(
    private pedidoService: CompraService,
    private modalService: BsModalService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  public cancelarPagamento(pedido) {
    return this.pedidoService.cancelarPagamento(pedido.id, pedido.compra_pagamento_digital.id);
  }

  public cancelar(pedido: Compra) {
    this.cancelarPagamento(pedido)
      .toPromise()
      .then((data: any) => {
        this.fecharModal();
        alert(data.message);
        if (data.success == true) {
          this.router.navigate(['/checkout/pagamento']);
        }
      });
  }

  fecharModal() {
    this.modalService._hideModal(1);
  }
}
