/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./modal-centro-distribuicao-list.component";
import * as i3 from "ngx-bootstrap/modal";
var styles_ModalCentroDistribuicaoListComponent = [];
var RenderType_ModalCentroDistribuicaoListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalCentroDistribuicaoListComponent, data: {} });
export { RenderType_ModalCentroDistribuicaoListComponent as RenderType_ModalCentroDistribuicaoListComponent };
function View_ModalCentroDistribuicaoListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_ModalCentroDistribuicaoListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalCentroDistribuicaoListComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cds; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ModalCentroDistribuicaoListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h4", [["class", "modal-title text-info text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Lojas "])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModalCentroDistribuicaoListComponent_1)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cds.length; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_ModalCentroDistribuicaoListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal-centro-distribuicao-list", [], null, null, null, View_ModalCentroDistribuicaoListComponent_0, RenderType_ModalCentroDistribuicaoListComponent)), i0.ɵdid(1, 49152, null, 0, i2.ModalCentroDistribuicaoListComponent, [i3.BsModalService], null, null)], null, null); }
var ModalCentroDistribuicaoListComponentNgFactory = i0.ɵccf("app-modal-centro-distribuicao-list", i2.ModalCentroDistribuicaoListComponent, View_ModalCentroDistribuicaoListComponent_Host_0, { cds: "cds" }, {}, []);
export { ModalCentroDistribuicaoListComponentNgFactory as ModalCentroDistribuicaoListComponentNgFactory };
