import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class CepService {
  public service: string = '/loja/faixas_de_cep/verificar';
  public televendas: any;
  public cliente: any;

  constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService,) {
    this.televendas = this.localStorageService.get('operador');
    this.cliente = this.localStorageService.get("cliente")
  }

  validar(cep: string) {
    return this.httpClient.get(
        environment.apiUrl + this.service + '/filial_id/' + environment.filialId + '/cep/' + cep
    );
  }

  validaCepCd(cep: number|string) {
    cep = cep.toString().replace(/\-/g, '');
    const CD_CEP_URL = `loja/centros_distribuicoes/filial/${environment.filialId}/cep/${cep}/entrega`;
    return this.httpClient.get(`${environment.apiUrl}/${CD_CEP_URL}`);
  }

  buscaRetiradas(_cep: string = null) {
    const CD_RETIRADA_URL = `loja/centros_distribuicoes/filial/${environment.filialId}/retiradas`;
    const cep = _cep ? _cep : this.getCepEnderecoPrincipal(this.cliente);
    const params = new HttpParams().set('cep', cep);

    return this.httpClient.get(`${environment.apiUrl}/${CD_RETIRADA_URL}`,  { params }).pipe(
      map((res: any) => {
        let retiradas = this.removerRetiradasExclusivasTelevendas(res.data);
        return { data: retiradas };
      })
    );
  }

  removerRetiradasExclusivasTelevendas(retiradas) {
    return retiradas.filter((retirada) => {
      return !retirada.exclusivo_televendas || this.televendas;
    });
  }

  /**
   * Busca as informações referentes a localização
   * @param latitude int
   * @param longitude int
   */
  buscaInfoGeolocation(lat, lon) {
    return this.httpClient.get(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`);
  }

  possuiEntregas() {
    const URL = `loja/tipo_entregas/filial/${environment.filialId}/realiza_entrega`;
    return this.httpClient.get(`${environment.apiUrl}/${URL}`);
  }

  private getCepEnderecoPrincipal(cliente) {
    return cliente && cliente.endereco_principal_cep ? cliente.endereco_principal_cep : null;
  }
}
