/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./produto-imagem.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "ng-lazyload-image";
import * as i5 from "./produto-imagem.component";
import * as i6 from "../../shared/services/imagem.service";
var styles_ProdutoImagemComponent = [i0.styles];
var RenderType_ProdutoImagemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProdutoImagemComponent, data: {} });
export { RenderType_ProdutoImagemComponent as RenderType_ProdutoImagemComponent };
function View_ProdutoImagemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "img-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "width.px": 0, "height.px": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "img-container--loader fa-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (!_co.imgLoaded ? _co.tamanho : 0), (!_co.imgLoaded ? _co.tamanho : 0)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProdutoImagemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.clickAction() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProdutoImagemComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { "height.px": 0, visibility: 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 4, "img", [], [[8, "alt", 0], [8, "title", 0], [8, "width", 0]], [[null, "onLoad"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLoad" === en)) {
        var pd_0 = (_co.onLoadImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(11, { show: 0 }), i1.ɵdid(12, 1720320, null, 0, i4.LazyLoadImageDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID, [2, "options"]], { lazyImage: [0, "lazyImage"], defaultImage: [1, "defaultImage"], offset: [2, "offset"] }, { onLoad: "onLoad" })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getRouterLink(); _ck(_v, 1, 0, currVal_2); var currVal_3 = !_co.imgLoaded; _ck(_v, 3, 0, currVal_3); var currVal_4 = _ck(_v, 7, 0, (_co.imgLoaded ? _co.tamanho : 0), (_co.imgLoaded ? "visible" : "hidden")); _ck(_v, 6, 0, currVal_4); var currVal_8 = i1.ɵinlineInterpolate(1, "img-container--product ", _co.classe, ""); var currVal_9 = _ck(_v, 11, 0, _co.imgLoaded); _ck(_v, 10, 0, currVal_8, currVal_9); var currVal_10 = _co.srcImagem; var currVal_11 = _co.getFallback(); var currVal_12 = _co.offset; _ck(_v, 12, 0, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵinlineInterpolate(1, "", _co.produto.descricao, ""); var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.produto.descricao, ""); var currVal_7 = i1.ɵinlineInterpolate(1, "", _co.tamanho, ""); _ck(_v, 8, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_ProdutoImagemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-produto-imagem", [], null, null, null, View_ProdutoImagemComponent_0, RenderType_ProdutoImagemComponent)), i1.ɵdid(1, 638976, null, 0, i5.ProdutoImagemComponent, [i6.ImageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProdutoImagemComponentNgFactory = i1.ɵccf("app-produto-imagem", i5.ProdutoImagemComponent, View_ProdutoImagemComponent_Host_0, { produto: "produto", tamanho: "tamanho", classe: "classe", preventLink: "preventLink" }, { clickAdsAction: "clickAdsAction" }, []);
export { ProdutoImagemComponentNgFactory as ProdutoImagemComponentNgFactory };
