import { Component } from "@angular/core";

@Component({
  selector: "app-offline",
  templateUrl: "./offline.component.html",
  styleUrls: ["./offline.component.css"]
})
export class OfflineComponent {
  constructor() {}
}
