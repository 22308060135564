import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TermosUsoService } from 'src/app/shared/services/termos-uso.service';
import { environment } from 'src/environments/environment';
export class TermosUsoComponent {
    constructor(bsModalRef, termoUsoService, formBuilder) {
        this.bsModalRef = bsModalRef;
        this.termoUsoService = termoUsoService;
        this.formBuilder = formBuilder;
    }
    ngOnInit() {
        this.criarFormulario();
        this.getTermoUsoVigente();
    }
    criarFormulario() {
        this.form = this.formBuilder.group({
            termos_aceite: [false, [Validators.requiredTrue]],
        });
    }
    getTermoUsoVigente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.termoUsoService.getTermoUsoVigente(environment.filialId);
        });
    }
    aprovarTermoUso() {
        const termoUsoId = this.termoUsoService.termoUso.id;
        this.termoUsoService.aprovarTermoUso(termoUsoId).then(() => {
            this.close();
            this.termoUsoService.aprovacaoPendente = false;
        });
    }
    close() {
        this.bsModalRef.hide();
    }
}
