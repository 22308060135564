<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title text-info">Termos de uso</h4>
    </div>
    <div class="modal-body termos-uso-modal-body">
        <div *ngIf="termoUsoService?.aprovacaoPendente" class="alert-aceite">
            Para prosseguir com o uso da loja, você deve aceitar os termos de uso.
        </div>
        <h5 *ngIf="termoUsoService?.termoUso?.id" class="text-warning">Versão do Termo de Uso: {{termoUsoService?.termoUso?.id}} </h5>
        <hr *ngIf="termoUsoService?.aprovacaoPendente" class="alert-separator" />
        <div [innerHTML]="termoUsoService?.termoUso?.texto | safeHtml"></div>
    </div>
    <div class="modal-footer">
        <form [formGroup]="form" *ngIf="termoUsoService?.aprovacaoPendente">
            <div class="row">
                <div class="col-xs-6 col-md-6 field-aceite">
                    <label>
                        <input type="checkbox" formControlName="termos_aceite" />
                        Li e aceito os termos de uso
                    </label>
                </div>
                <div class="col-xs-6 col-md-6">
                    <button [disabled]="!form.valid" type="button" class="btn btn-success pull-right" (click)="aprovarTermoUso()">
                        Confirmar
                    </button>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-md-12">
                <button *ngIf="!termoUsoService?.aprovacaoPendente" type="button" class="btn btn-danger pull-right" (click)="close()">
                    Fechar
                </button>
            </div>
        </div>
    </div>
</div>
