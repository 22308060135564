/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tag-preco.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/pipes/price.pipe";
import * as i4 from "./tag-preco.component";
var styles_TagPrecoComponent = [i0.styles];
var RenderType_TagPrecoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagPrecoComponent, data: {} });
export { RenderType_TagPrecoComponent as RenderType_TagPrecoComponent };
function View_TagPrecoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "info-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tituloOferta; _ck(_v, 2, 0, currVal_0); }); }
function View_TagPrecoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "info-price"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unidade; _ck(_v, 1, 0, currVal_0); }); }
function View_TagPrecoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "/", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "info-price info-price-unidade-medida ", (!_v.parent.context.$implicit.md ? "info-price-unidade-medida-sm" : ""), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), (_v.parent.context.$implicit.md ? _co.precoUnidadeMedida : _co.precoUnidadeMedidaSm))); var currVal_2 = _co.fracaoUnidadeMedida; _ck(_v, 1, 0, currVal_1, currVal_2); }); }
function View_TagPrecoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_2)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "info-price"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "bullet"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.md; _ck(_v, 1, 0, currVal_0); var currVal_2 = !_co.produto.possui_unidade_diferente; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.possuiUnidadeMedida; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), (_v.context.$implicit.md ? _co.precoProduto : (_co.mostrarComparacaoPrecoAntigo(_co.produto.oferta) ? _co.produto.oferta.preco_antigo : _co.produto.oferta.preco_oferta)))); _ck(_v, 3, 0, currVal_1); var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.arrowClass, ""); _ck(_v, 9, 0, currVal_4); }); }
function View_TagPrecoComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TagPrecoComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "drill-price drill-price-md text-danger"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "drill-price-unidade-medida": 0 }), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_TagPrecoComponent_7)), i1.ɵdid(6, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(7, { md: 0 }), i1.ɵpod(8, { $implicit: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "drill-price drill-price-md text-danger"; var currVal_1 = _ck(_v, 4, 0, _co.possuiUnidadeMedida); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 8, 0, _ck(_v, 7, 0, true)); var currVal_3 = i1.ɵnov(_v.parent.parent, 2); _ck(_v, 6, 0, currVal_2, currVal_3); }, null); }
function View_TagPrecoComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TagPrecoComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "drill-price drill-price-sm text-danger"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "drill-price-preco-vip": 0 }), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_TagPrecoComponent_10)), i1.ɵdid(5, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(6, { md: 0 }), i1.ɵpod(7, { $implicit: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "drill-price drill-price-sm text-danger"; var currVal_1 = _ck(_v, 3, 0, _co.possuiUnidadeMedida); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 7, 0, _ck(_v, 6, 0, false)); var currVal_3 = i1.ɵnov(_v.parent.parent.parent, 2); _ck(_v, 5, 0, currVal_2, currVal_3); }, null); }
function View_TagPrecoComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TagPrecoComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 3, null, View_TagPrecoComponent_12)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { md: 0 }), i1.ɵpod(3, { $implicit: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _ck(_v, 2, 0, true)); var currVal_1 = i1.ɵnov(_v.parent.parent.parent, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TagPrecoComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "info-price oferta-atacarejo"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.context.$implicit.valor)); var currVal_1 = _co.unidade; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TagPrecoComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "info-price info-price-unidade-medida"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "/", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 0), _v.parent.context.$implicit.preco_oferta_fracao)); var currVal_1 = _co.fracaoUnidadeMedida; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TagPrecoComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_17)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.possuiUnidadeMedida; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TagPrecoComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "info-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["A cada ", " por"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_15)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_16)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.produto.oferta.faixas_precos; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.produto.oferta.faixas_precos; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.produto.oferta.faixas_precos[0].quantidade; _ck(_v, 1, 0, currVal_0); }); }
function View_TagPrecoComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "/", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 0), _v.parent.context.$implicit.preco_oferta_fracao)); var currVal_1 = _co.fracaoUnidadeMedida; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TagPrecoComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "info-price oferta-atacarejo oferta-atacarejo-for"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " por ", " ", " "])), i1.ɵppd(2, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_20)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.possuiUnidadeMedida; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.quantidade; var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.context.$implicit.valor)); var currVal_2 = _co.unidade; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_TagPrecoComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_19)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.produto.oferta.faixas_precos; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TagPrecoComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "/", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 0), _v.parent.context.$implicit.preco_oferta_fracao)); var currVal_1 = _co.fracaoUnidadeMedida; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TagPrecoComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "info-price oferta-atacarejo oferta-atacarejo-default"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " por ", " ", " "])), i1.ɵppd(2, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_23)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.possuiUnidadeMedida; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.quantidade; var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.context.$implicit.valor)); var currVal_2 = _co.unidade; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_TagPrecoComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_22)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.produto.oferta.faixas_precos; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TagPrecoComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_14)), i1.ɵdid(3, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_18)), i1.ɵdid(5, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_21)), i1.ɵdid(7, 16384, null, 0, i2.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "bullet"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.produto.oferta.faixas_precos.length; _ck(_v, 1, 0, currVal_0); var currVal_1 = 1; _ck(_v, 3, 0, currVal_1); var currVal_2 = 2; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.arrowClass, ""); _ck(_v, 8, 0, currVal_3); }); }
function View_TagPrecoComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_9)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "drill-price drill-price-md text-success"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "drill-price-unidade-medida": 0 }), i1.ɵdid(7, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_11)), i1.ɵdid(9, 16384, null, 0, i2.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_13)), i1.ɵdid(11, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.produto.oferta.classe_oferta; _ck(_v, 2, 0, currVal_0); var currVal_1 = "drill-price drill-price-md text-success"; var currVal_2 = _ck(_v, 6, 0, _co.possuiUnidadeMedida); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.produto.oferta.classe_oferta; _ck(_v, 7, 0, currVal_3); var currVal_4 = "oferta-atacarejo"; _ck(_v, 11, 0, currVal_4); }, null); }
function View_TagPrecoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_8)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.produto.em_oferta; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.produto.em_oferta; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TagPrecoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.PricePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(0, [["templatePrecoOferta", 2]], null, 0, null, View_TagPrecoComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagPrecoComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.produto.disponivel; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_TagPrecoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tag-preco", [], null, null, null, View_TagPrecoComponent_0, RenderType_TagPrecoComponent)), i1.ɵdid(1, 114688, null, 0, i4.TagPrecoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagPrecoComponentNgFactory = i1.ɵccf("app-tag-preco", i4.TagPrecoComponent, View_TagPrecoComponent_Host_0, { produto: "produto", arrowDirection: "arrowDirection" }, { clickAdsAction: "clickAdsAction" }, []);
export { TagPrecoComponentNgFactory as TagPrecoComponentNgFactory };
