import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CentroDistribuicaoComponent } from './centro-distribuicao.component';
import { CentroDistribuicaoService } from './shared/centro-distribuicao.service';
import { AlterarLojaService } from './shared/alterar-loja.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CepService } from '../ceps/shared/cep.service';
import { CorreioService } from '../shared/services/correio.service';
import { EnderecoModule } from '../enderecos/endereco.module';
import { AlterarLojaModule } from './alterar-loja/alterar-loja.module';
import { LimiteCdModule } from './limite-cd/limite-cd.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    EnderecoModule,
    AlterarLojaModule,
    LimiteCdModule
  ],
  declarations: [CentroDistribuicaoComponent],
  providers: [CentroDistribuicaoService, AlterarLojaService, CorreioService, CepService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CentroDistribuicaoModule { }
