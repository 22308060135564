export class DocumentValidation {

  static pesoCpf = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

  public static validarCpf(cpfValidar: string): boolean {
    const cpf = cpfValidar && cpfValidar.replace(/\D+/g, '');
    if (this.digitosIguais(cpf) || cpf.length !== 11) {
      return false;
    }
    const digito1 = this.calcularDigito(cpf.substring(0, 9), this.pesoCpf);
    const digito2 = this.calcularDigito(cpf.substring(0, 9) + digito1, this.pesoCpf);
    return cpf === cpf.substring(0, 9) + digito1.toString() + digito2.toString();
  }


  private static calcularDigito(str: string, peso: number[]): number {
    let soma = 0;
    for (let i = str.length - 1, digito; i >= 0; i--) {
      digito = Number(str.substring(i, i + 1));
      soma += digito * peso[peso.length - str.length + i];
    }
    soma = 11 - soma % 11;
    return soma > 9 ? 0 : soma;
  }

  private static digitosIguais(cpf: string) {
    return (cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999' ||
      cpf == null);
  }
}
