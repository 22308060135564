import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { LimiteCdComponent } from './limite-cd.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [LimiteCdComponent],
  declarations: [LimiteCdComponent],
  entryComponents: [LimiteCdComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LimiteCdModule { }
