<div>
    <div class="modal-header">
        <h4 class="modal-title text-center">Imperdível!</h4>
    </div>
    <div class="modal-body">
        {{ texto }}
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="close()">Não, obrigado</button>
        <button class="btn btn-primary" routerLink="/sorteios" (click)="close()">Ver mais</button>
    </div>
</div>
