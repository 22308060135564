import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular-2-local-storage";
import * as i3 from "@angular/router";
export class CarrinhoAnonimoService {
    constructor(httpClient, localStorageService, router) {
        this.httpClient = httpClient;
        this.localStorageService = localStorageService;
        this.router = router;
        this.carrinhoIniciado = false;
        this._permitirCarrinhoAnonimo = false;
        this._carrinhoAnonimoVerificado = true;
        this._sincronizarCarrinhoAnonimo = new Subject();
        this.sincronizarCarrinhoAnonimo$ = this._sincronizarCarrinhoAnonimo.asObservable();
        this._escolhaCarrinhoCliente = new Subject();
        this.escolhaCarrinhoCliente$ = this._escolhaCarrinhoCliente.asObservable();
        this.sincronizar = 'sincronizar';
        this.possuiCarrinhoCliente = 'possuiCarrinhoCliente';
    }
    get permitirCarrinhoAnonimo() {
        return this._permitirCarrinhoAnonimo;
    }
    get carrinhoAnonimoVerificado() {
        return this._carrinhoAnonimoVerificado;
    }
    verificarCarrinhoAnonimo() {
        this.httpClient
            .get(`${environment.apiUrl}/loja/permitir_carrinho_anonimo`)
            .subscribe((response) => {
            this._permitirCarrinhoAnonimo = response.data;
            this.setCarrinhoAnonimoVerificado(!response.data);
        });
    }
    verificarCompraExcluida(compraCancelada) {
        if (compraCancelada)
            alert(`Sua compra de número ${compraCancelada} foi cancelada. Você pode realizá-la novamente acessando o histórico de compras.`);
    }
    verificarBebidaAlcoolica(menorIdade) {
        if (menorIdade)
            alert('Devido sua idade as bebidas alcoólicas foram removidas do carrinho');
    }
    emitirEventoEscolhaCarrinhoCliente(valor) {
        this.localStorageService.set('carrinhoAnonimoVerificado', true);
        this._escolhaCarrinhoCliente.next(valor);
    }
    emitirEventoSincronizarCarrinhoAnonimoCliente(valor) {
        this._sincronizarCarrinhoAnonimo.next(valor);
    }
    setCarrinhoAnonimoVerificado(valor) {
        this._carrinhoAnonimoVerificado = valor;
    }
    redirecionarHome() {
        this.router.navigate(['/']);
    }
    mostrarMensagem(mensagem) {
        alert(`${mensagem}. Você será redirecionado para a tela inicial`);
    }
}
CarrinhoAnonimoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CarrinhoAnonimoService_Factory() { return new CarrinhoAnonimoService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.Router)); }, token: CarrinhoAnonimoService, providedIn: "root" });
