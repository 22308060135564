import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AplicativoService {
  private requestData: any;
  public request: Observable<any>;

  constructor(private httpClient: HttpClient) {
    this.request = this.httpClient.get(
      `${environment.apiUrl}/build/app/configuracao/${environment.filialId}`
    )
  }

  public getInfoAplicativo() {
    if (this.requestData && 'success' in this.requestData) {
      return of(this.requestData);
    }

    return this.request.pipe(map((res) => {
      this.requestData = res;
      return res
    }));
  }
}
