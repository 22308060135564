import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class FooterService {
    constructor() {
        this.showFooter$ = new BehaviorSubject(true);
    }
    mostrarFooter() {
        this.showFooter$.next(true);
    }
    ocultarFooter() {
        this.showFooter$.next(false);
    }
}
FooterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FooterService_Factory() { return new FooterService(); }, token: FooterService, providedIn: "root" });
