import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value != null) {
            value = String(value).toLowerCase();
            return value.substring(0, 1).toUpperCase() + value.substring(1);
        }
    }

}
