import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'price'
})
export class PricePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        return (args != undefined ? args : 'R$ ') +
            new Intl.NumberFormat('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    }
}
