import { Injectable, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable()
export class ViewService {
  public customSearchPlaceholders = {
    'momoconfeitaria.com.br': 'Buscar um ou mais produtos (ex.: torta floresta negra; bombom de morango...)',
    'deliverysorvetesalada.com.br': 'Buscar um ou mais produtos'
  };

  private inAppMode: boolean = false;
  private online: boolean = true;
  constructor() {
    window.addEventListener('offline', (e) => { this.setOnline(false); });
    window.addEventListener('online', (e) => { this.setOnline(true); });
  }

  public setInAppMode(inAppMode) {
    inAppMode = (inAppMode == 'true');
    this.inAppMode = inAppMode;
  }

  public isInAppMode() {
    return this.inAppMode;
  }

  public setOnline(online) {
    this.online = online;
  }

  public isOnline() {
    return this.online;
  }

  public getSearchPlaceholder() {
    let placeholder = 'Buscar um ou mais produtos (ex.: arroz; feijão...)';
    Object.keys(this.customSearchPlaceholders).forEach((domain) => {
      if (window.location.host.includes(domain)) {
        placeholder = this.customSearchPlaceholders[domain];
      }
    });
    return placeholder;
  }
}
