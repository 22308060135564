<div class="modal-minhas-listas-edit">
    <i class="fa fa-close modal-minhas-listas-edit__close" aria-hidden="true" (click)="close()"></i>
    <header class="title">
        <h4>{{title}}</h4>
    </header>
    <section>
        <form class="modal-minhas-listas-edit__form" (ngSubmit)="save()" #listForm="ngForm">
            <div class="form-group input-group">
                <label class="control-label">Nome</label>
                <input id="nome" name="nome" class="form-control" type="text" required maxlength="255" [(ngModel)]="list.nome" #nome="ngModel">
            </div>
            <div *ngIf="listForm.form.invalid && listForm.form.dirty && listForm.form.touched" class="text-danger error">
                <div *ngIf="nome.errors.required">
                    Digite um nome
                </div>
            </div>
            <button type="submit" class="btn btn-checkout button" [disabled]="!listForm.form.valid">
                <span>Salvar</span>
            </button>
        </form>
    </section>
</div>