import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TermosUsoService {
  public service: string;
  public termoUso: any;
  public aprovacaoPendente: boolean = false;

  constructor(private httpClient: HttpClient) {
    this.service = `${environment.apiUrl}/loja/termos_uso`;
  }

  getPossuiTermoUsoPendente(filialId) {
    return this.httpClient
      .get(`${this.service}/filial/${filialId}/aprovacao_pendente`)
      .toPromise()
      .then((res: any) => {
        this.aprovacaoPendente = res.data;
      });
  }

  getTermoUsoVigente(filialId) {
    return this.httpClient
      .get(`${this.service}/filial/${filialId}/vigente`)
      .toPromise()
      .then((res: any) => {
        this.termoUso = res.data;
      });
  }

  aprovarTermoUso(termoUsoId) {
    return this.httpClient
      .post(`${this.service}/${termoUsoId}/aprovar`, {})
      .toPromise();
  }
}
