import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Produto } from './produto';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProdutoService {
  public service: string = '/produtos';
  public produtos: Produto[] = [];
  public oferta: any;
  public paging: any;

  constructor(private httpClient: HttpClient) {}

  banners(bannerID: number, params?: any) {
    return this.httpClient
      .get(this.service + '/banners/' + environment.filialId + '/' + bannerID, {
        params: params
      })
      .toPromise()
      .then((data: any) => {
        this.produtos = data.produtos;
        this.paging = data.paging;
      });
  }

  convertParams(params) {
    if (!params || Object.keys(params).length === 0) {
      return '';
    }

    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    return `?${queryString}`;
  }
}
