import * as tslib_1 from "tslib";
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
export class MinhaContaCashbackService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.service = '/cashback';
        this.needUpdateData = new BehaviorSubject(false);
    }
    setNeedUpdateValue(newValue) {
        this.needUpdateData.next(newValue);
    }
    getNeedUpdateValue() {
        return this.needUpdateData.asObservable();
    }
    saldo() {
        const client = this.httpClient.get(`${this.service}/saldo/`);
        this.setNeedUpdateValue(false);
        return client;
    }
    extrato() {
        return this.httpClient.get(`${this.service}/extrato/`);
    }
    alterarSenha(novaSenha) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.httpClient
                .post(`${this.service}/alterar-senha/`, {
                nova_senha: novaSenha,
            })
                .toPromise();
        });
    }
    utilizarSaldo(compraId, valorUtilizado) {
        return this.httpClient.post(`${this.service}/utilizar-saldo/`, {
            compra_id: compraId,
            vlr_utilizado: valorUtilizado,
        });
    }
}
