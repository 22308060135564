import { Component, Input } from '@angular/core';
import { SolicitacaoCepService } from './shared/solicitacao-cep.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-solicitacao-ceps',
  templateUrl: './solicitacao-ceps.component.html',
  styleUrls: ['./solicitacao-ceps.component.css'],
  providers: [SolicitacaoCepService]
})
export class SolicitacaoCepsComponent {

  @Input() cep: string;
  email: string;
  solicitacao = {
    erro: false,
    sucesso: false
  };

  constructor(public solicitacaoCepService: SolicitacaoCepService) {
  }

  enviarSolicitacao() {
    const solicitacao = {
      filial_id: environment.filialId,
      cep: this.cep,
      email: this.email,
      enviado: false,
    };

    if (!this.validateEmail(this.email)) {
      alert('Digite um e-mail válido.');
      return;
    }

    this.solicitacaoCepService.add(solicitacao).subscribe((req: any) => {
      this.solicitacao.erro = !req.success;
      this.solicitacao.sucesso = req.success;
    });
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

}
