/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./minhas-listas-replace.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../partials/produtos-indisponiveis-lista/produtos-indisponiveis-lista.component.ngfactory";
import * as i3 from "../../../partials/produtos-indisponiveis-lista/produtos-indisponiveis-lista.component";
import * as i4 from "ngx-bootstrap/modal";
import * as i5 from "../../../partials/carrinho/shared/carrinho.service";
import * as i6 from "../../../centro-distribuicao/shared/centro-distribuicao.service";
import * as i7 from "./minhas-listas-replace.component";
var styles_MinhasListasReplaceComponent = [i0.styles];
var RenderType_MinhasListasReplaceComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MinhasListasReplaceComponent, data: {} });
export { RenderType_MinhasListasReplaceComponent as RenderType_MinhasListasReplaceComponent };
export function View_MinhasListasReplaceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-minhas-listas-replace"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-close modal-minhas-listas-replace__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-produtos-indisponiveis-lista", [], null, [[null, "afterReplace"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("afterReplace" === en)) {
        var pd_0 = (_co.afterReplace($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ProdutosIndisponiveisListaComponent_0, i2.RenderType_ProdutosIndisponiveisListaComponent)), i1.ɵdid(3, 245760, null, 0, i3.ProdutosIndisponiveisListaComponent, [i4.BsModalService, i5.CarrinhoService, i6.CentroDistribuicaoService], { products: [0, "products"], modalLevel: [1, "modalLevel"] }, { afterReplace: "afterReplace" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.products; var currVal_1 = 1; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_MinhasListasReplaceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_MinhasListasReplaceComponent_0, RenderType_MinhasListasReplaceComponent)), i1.ɵdid(1, 49152, null, 0, i7.MinhasListasReplaceComponent, [i4.BsModalService], null, null)], null, null); }
var MinhasListasReplaceComponentNgFactory = i1.ɵccf("ng-component", i7.MinhasListasReplaceComponent, View_MinhasListasReplaceComponent_Host_0, {}, {}, []);
export { MinhasListasReplaceComponentNgFactory as MinhasListasReplaceComponentNgFactory };
