<div class="recuperar-senha-modal">
  <i class="fa fa-close recuperar-senha-modal__close" aria-hidden="true" (click)="closeButton()"></i>
  <header class="title">
    <h4>Esqueci minha senha</h4>
  </header>
  <section>
    <div class="subtitle">Se você esqueceu sua senha, você poderá recuperar seu acesso utilizando o formulário abaixo. Você receberá um link por e-mail para redefinição da senha</div>
    <form class="recuperar-senha-modal__form" #forgotPasswordForm="ngForm">
      <re-captcha [style.display]="env.showCaptcha ? 'flex' : 'none'" #captchaRef="reCaptcha" (resolved)="$event && recuperarSenha($event)" size="invisible"></re-captcha>
      <div class="form-group">
        <label class="control-label">E-mail ou telefone com DDD</label>
        <input id="contato" name="contato" class="form-control" appEmailTelefone [(ngModel)]="user.contato" required #contato="ngModel" />
      </div>
      <div *ngIf="contato.invalid && (contato.dirty || contato.touched)" class="text-danger error">
        <div *ngIf="contato.errors?.required">Digite um e-mail ou telefone</div>
      </div>

      <div class="form-group">
        <label class="control-label">CPF ou CNPJ</label>
        <input id="rec_documento" name="rec_documento" class="form-control" type="text" cpfCnpj [(ngModel)]="user.rec_documento" required #rec_documento="ngModel" />
      </div>
      <div *ngIf="rec_documento.invalid && (rec_documento.dirty || rec_documento.touched)" class="text-danger error">
        <div *ngIf="rec_documento.errors?.required">Digite um CPF ou CNPJ</div>
      </div>

      <button type="button" (click)="env.showCaptcha ? validaSolicitacao() : recuperarSenha()" class="btn btn-primary" [disabled]="!forgotPasswordForm.form.valid || loading">
        <span *ngIf="!loading">Enviar solicitação</span>
        <span *ngIf="loading"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Aguarde</span>
      </button>
    </form>
  </section>
</div>
