import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import 'hammerjs';
import * as $ from 'jquery';
import { BannerService } from './shared/banner.service';
import { Banner } from './shared/banners';
import { TipoOfertaEnum } from 'src/app/produtos/shared/tipo_oferta.enum';
import { ImageService } from 'src/app/shared/services/imagem.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BannersComponent implements OnInit, OnDestroy {
  @ViewChild('carousel', { static: false }) carousel: NguCarousel<any>;

  public show_banners: boolean = true;
  public total: number = 0;
  public banners: Banner[] = [];
  public allBanners: Banner[] = [];

  public timeout: any;
  public resizeTimeout: any;
  public currentIndex: number = 0;

  public carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 400,
    load: 2,
    touch: true,
    loop: true,
    custom: 'banner',
    point: {
      hideOnSingleSlide: true,
      visible: true
    }
  };

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      if (this.carousel) {
        this.carousel.reset();
      }
      this.banners = this.filteredBanners();
    }, 300);
  }

  constructor(private bannerService: BannerService, private imageService: ImageService) {}

  public loopTimeout() {
    if (
      this.banners.length > this.currentIndex &&
      'tempo_segundos' in this.banners[this.currentIndex]
    ) {
      this.timeout = setTimeout(() => {
        this.currentIndex = (this.currentIndex + 1) % this.banners.length;
        this._nextSlide(this.currentIndex);
        this.loopTimeout();
      }, this.banners[this.currentIndex].tempo_segundos * 1000);
    }
  }

  public pauseTimeout() {
    clearTimeout(this.timeout);
  }

  public resumeTimeout() {
    if (
      this.banners.length > this.currentIndex &&
      'tempo_segundos' in this.banners[this.currentIndex]
    ) {
      this.timeout = setTimeout(() => {
        this.loopTimeout();
      }, this.banners[this.currentIndex].tempo_segundos * 1000);
    }
  }

  public atualizarBanners = () => {
    this.show_banners = this.bannerService.show_banners;
    this.allBanners = this.bannerService.banners;
    this.banners = this.filteredBanners();

    this.total = this.bannerService.total;
    this.currentIndex = 0;
    this.carouselConfig.point.visible = this.banners.length > 1;

    clearTimeout(this.timeout);

    if (this.banners.length > 0) {
      this._nextSlide(this.currentIndex);
      this.loopTimeout();
    }
  };

  public filteredBanners() {
    let banners: Banner[] = [];

    // TODO: Remover jQuery
    let width = $('body').width() + this.scrollbar_width();

    this.allBanners.forEach(banner => {
      let images = [];
      banner.banner_imagens.forEach(imagem => {
        if (width > 1000 && imagem.largura >= 1200) {
          images.push(imagem);
        } else if (
          width > 800 &&
          width <= 1000 &&
          imagem.largura >= 1000 &&
          imagem.largura < 1200
        ) {
          images.push(imagem);
        } else if (width < 800 && imagem.largura <= 800) {
          images.push(imagem);
        }
      });

      if (images.length) {
        let customBanner = Object.assign({}, banner);
        customBanner.banner_imagens = images;
        banners.push(customBanner);
      }
    });

    return banners.map(banner => {
      banner.link_externo = this.getLinkExterno(banner.link);
      banner.link_banner = this.getLinkBanner(banner);
      return banner;
    });
  }

  private getLinkExterno(link) {
    return link && link.startsWith('http') ? 's' : 'n';
  }

  private getLinkBanner(banner) {
    if (banner.model === 'Produto') {
      return `/produtos/detalhe/${banner.produto.produto_id}/${banner.produto.link}`;
    }

    if (banner.model === 'Colecao') {
      return `/produtos/colecoes/${banner.colecao.id}/${banner.colecao.slug}`;
    }

    if (banner.model === 'Oferta') {
      const oferta = banner.oferta;
      const nome = btoa(oferta.nome);
      if (oferta.tipo_oferta_id == TipoOfertaEnum.COMBO_DE_PRODUTOS) {
        return `/produtos/combos/${oferta.id}`;
      } else {
        return `/produtos/ofertas/banner/${oferta.id}/${nome}`;
      }
    }

    if (banner.model === 'Pagina') {
      return `/institucional/paginas/${banner.pagina.slug}`;
    }

    if (banner.model === 'Receita') {
      return `/receitas/${banner.receita.id}/${banner.receita.slug}`;
    }

    return banner.link_banner;
  }

  private scrollbar_width() {
    // TODO: Remover jQuery
    if ($('body').height() > $(window).height()) {
      /* Modified from: http://jdsharp.us/jQuery/minute/calculate-scrollbar-width.php */
      let calculationContent = $(
        '<div style="width:50px;height:50px;overflow:hidden;position:absolute;top:-200px;left:-200px;"><div style="height:100px;"></div>'
      );
      $('body').append(calculationContent);
      let widthOne = $('div', calculationContent).innerWidth();
      calculationContent.css('overflow-y', 'scroll');
      let widthTwo = $('div', calculationContent).innerWidth();
      $(calculationContent).remove();
      return widthOne - widthTwo;
    }
    return 0;
  }

  public ngOnInit() {
    window.addEventListener('atualizarBanners', this.atualizarBanners);
  }

  public ngOnDestroy() {
    window.removeEventListener('atualizarBanners', this.atualizarBanners);
  }

  public getUrl(banner: any) {
    if (banner.error) {
      return this.imageService.getPathPlaceholderBySize(960);
    }
    return this.imageService.getBasePath() + banner.base + '/' + banner.arquivo;
  }

  public onError(array: any[], index: number) {
    array[index].error = true;
  }

  private _nextSlide(currentIndex: number) {
    if (this.carousel) {
      this.carousel.moveTo(currentIndex);
    }
  }

  public countCLick(id) {
    this.bannerService.addClick(id).subscribe();
  }
}
