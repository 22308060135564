import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { MinhaContaCashbackService } from '../shared/minha-conta-cashback.service';
import { FidelidadeService } from 'src/app/shared/services/fidelidade.service';

@Component({
  selector: 'app-minha-conta-cashback-modal-extrato',
  templateUrl: './minha-conta-cashback-modal-extrato.component.html',
  styleUrls: ['./minha-conta-cashback-modal-extrato.component.css'],
})
export class MinhaContaCashbackModalExtratoComponent implements OnInit {
  public saldo: number;
  public extrato = [];
  public loading: boolean;
  public cashback: string;

  constructor(
    private cashbackService: MinhaContaCashbackService,
    private modalService: BsModalService,
    private fidelidadeService: FidelidadeService
  ) {}

  ngOnInit() {
    if(this.fidelidadeService.cashbackParams().ativo) {
      this.cashback = 'izio';
    } else if (this.fidelidadeService.vorticeParams().ativo && this.fidelidadeService.vorticeParams().cashback == 1) {
      this.cashback = 'idever';
    }

    this.carregarDadosCashback();
  }

  carregarDadosCashback() {
    Promise.all([this.carregarSaldo(), this.carregarExtrato()]);
  }

  async carregarSaldo() {
    this.loading = true;
    this.cashbackService.saldo().subscribe((cashbackSaldoResponse) => {
      if (cashbackSaldoResponse.success) {
        const {
          saldo: { saldo },
        } = cashbackSaldoResponse;
        this.saldo = saldo;
        this.loading = false;
      } else {
        alert(cashbackSaldoResponse.message);
        this.fecharModal();
      }
    });
  }

  async carregarExtrato() {
    this.cashbackService.extrato().subscribe((cashbackExtratoResponse) => {
      if (cashbackExtratoResponse.success) {
        this.loading = true;
        const { extrato = [] } = cashbackExtratoResponse;
        this.extrato = extrato.slice(0, 6);
        this.loading = false;
      }
    });
  }

  fecharModal() {
    this.modalService._hideModal(1);
  }

  getTipoOperacaoCredito(tipo: number) {
    return tipo % 2 === 1;
  }
}
