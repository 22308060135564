import { Injectable } from '@angular/core';
import { Departamento } from '../../produtos/produto-departamento/shared/departamentos';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DepartamentoService {
  private departamentos: Array<Departamento> = [];
  private _atualizaDepartamentos$: Subject<Departamento> = new Subject();
  public atualizaDepartamentos$: Observable<Departamento> = this._atualizaDepartamentos$.asObservable();

  private favoritos: Array<Departamento> = [];
  private _atualizaFavoritos$: Subject<Array<Departamento>> = new Subject();
  public atualizaFavoritos$: Observable<Array<Departamento>> = this._atualizaFavoritos$.asObservable();

  public service: string = '/departamentos';

  public showMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private httpClient: HttpClient) { }

  public detail: Departamento = <Departamento>{};

  listagem(cdId: number) {
    const URI = `classificacoes_mercadologicas/departamentos/arvore/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
    this.httpClient
      .get(`${environment.apiUrl}/loja/${URI}`)
      .subscribe((req: any) => {
        this.departamentos = req.data;
        this._atualizaDepartamentos$.next(req.data);
      });
  }

  buscaFavoritos(cdId: number) {
    const URI = `classificacoes_mercadologicas/departamentos/arvore/favoritos/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
    this.httpClient
      .get(`${environment.apiUrl}/loja/${URI}`)
      .subscribe((req: any) => {
        this.favoritos = req.data;
        this._atualizaFavoritos$.next(req.data);
      });
  }

  detalhe(slug: string) {
    return this.httpClient
      .get(this.service + '/detalhe/' + environment.filialId + '/' + slug)
      .toPromise()
      .then((data: any) => {
        this.detail = data;
      });
  }

  findDepartamentoBySlug(slug): Departamento {
    return this.departamentos.find(dp => {
      return dp.link === `/${slug}`;
    });
  }

  findDepartamentoById(id: number): Departamento {
    return this.departamentos.find(dp => {
      return Number(dp.classificacao_mercadologica_id) === Number(id);
    });
  }

  getDepartamentoIdBySlug(slug): number {
    const departamento = this.findDepartamentoBySlug(slug);
    return departamento ? departamento.classificacao_mercadologica_id : 0;
  }

  findDepartamentoBySecaoId(id: number) {
    return this.departamentos.find(dp => {
      return dp.children.some(sc => {
        return sc.classificacao_mercadologica_id === id;
      });
    });
  }

  findSecaoBySecaoId(departamento: Departamento, id: number) {
    if (!departamento || !id) {
      return '';
    }

    return departamento.children.find(item => {
      return item.classificacao_mercadologica_id === id;
    });
  }

  findSecaoBySlug(departamento: Departamento, slugSecao) {
    if (!departamento || !slugSecao) {
      return '';
    }

    return departamento.children.find(item => {
      return item.link === `${departamento.link}/${slugSecao}`;
    });
  }

  getSecaoIdBySlug(slugDepartamento, slugSecao): number {
    const departamento = this.findDepartamentoBySlug(slugDepartamento);

    if (!departamento) {
      return 0;
    }

    const secao = this.findSecaoBySlug(departamento, slugSecao);
    return secao ? secao.classificacao_mercadologica_id : 0;
  }

  limparFavoritos() {
    this.favoritos = [];
    this._atualizaFavoritos$.next(this.favoritos);
  }

  mostrarMenu() {
    this.showMenu$.next(true);
  }

  ocultarMenu() {
    this.showMenu$.next(false);
  }
}
