/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./observacao-produto.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./observacao-produto.component";
import * as i4 from "ngx-bootstrap/modal";
var styles_ObservacaoProdutoComponent = [i0.styles];
var RenderType_ObservacaoProdutoComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ObservacaoProdutoComponent, data: {} });
export { RenderType_ObservacaoProdutoComponent as RenderType_ObservacaoProdutoComponent };
export function View_ObservacaoProdutoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "observacao-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "textarea", [["class", "form-control"], ["id", "observacao"], ["maxlength", "200"], ["placeholder", "Adicione sua observa\u00E7\u00E3o"], ["rows", "5"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.item.observacao = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(4, 540672, null, 0, i2.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.MaxLengthValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "btn btn-success"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fechar(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fa fa-check-square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵted(-1, null, ["Confirmar"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["class", "btn btn-danger pull-right"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fechar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fa fa-minus-square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵted(-1, null, ["Cancelar"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = "200"; _ck(_v, 4, 0, currVal_8); var currVal_9 = _co.item.observacao; _ck(_v, 7, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).maxlength ? i1.ɵnov(_v, 4).maxlength : null); var currVal_1 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 9).ngClassValid; var currVal_6 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_ObservacaoProdutoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ObservacaoProdutoComponent_0, RenderType_ObservacaoProdutoComponent)), i1.ɵdid(1, 49152, null, 0, i3.ObservacaoProdutoComponent, [i4.BsModalService], null, null)], null, null); }
var ObservacaoProdutoComponentNgFactory = i1.ɵccf("ng-component", i3.ObservacaoProdutoComponent, View_ObservacaoProdutoComponent_Host_0, { item: "item", observacao: "observacao" }, {}, []);
export { ObservacaoProdutoComponentNgFactory as ObservacaoProdutoComponentNgFactory };
