import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Paginacao } from '../../partials/paginacao/shared/paginacao';
import { Compra } from './compras';

@Injectable()
export class CompraService {
  public service: string = '/compras';
  public pedidos: Compra[] = [];
  public pedido: Compra = <Compra>{};
  public instrucoes: string;
  public message: string = '';
  public auth_url: string = '';
  public transacao: any;
  public status: any;
  public paginacao: Paginacao = <Paginacao>{};
  public error: any;
  public STATUS_AGUARDANDO_ATENDIMENTO: number = 9;
  public STATUS_AGUARDANDO_SEPARACAO: number = 12;
  public STATUS_PROCESSO_COMPRA: number = 16;
  public STATUS_EM_ENTREGA: number = 27;
  public STATUS_AGUARDANDO_CHECKOUT: number = 30;
  public STATUS_SOLICITACAO_DE_CANCELAMENTO: number = 31;

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private router: Router,
  ) {}

  public index(tipo: string, params?: any) {
    return this.httpClient
      .get(this.service + '/index/' + environment.filialId + '/' + tipo, { params })
      .toPromise()
      .then((data: any) => {
        this.pedidos = data.pedidos;
        this.paginacao = data.paging;
      });
  }

  public view(id: number) {
    return this.httpClient
      .get(this.service + '/view/' + environment.filialId + '/' + id)
      .toPromise()
      .then((data: any) => {
        this.pedido = data.pedido;
        this.instrucoes = data.instrucoes;
      });
  }

  public add(data: Compra, cdId: number) {
    let meliuz = null;
    try {
      meliuz = JSON.parse(this.cookieService.get('Meliuz'));
    } catch (e) {}

    let uri = `${this.service}/add/${environment.filialId}/${cdId}`;
    if (meliuz != null && data.tipo_compra == 'N') {
      return this.httpClient.post(uri + '/' + meliuz.trans_id, data);
    }
    return this.httpClient.post(uri, data);
  }

  public edit(data: Compra) {
    return this.httpClient.post(
      this.service + '/edit/' + environment.filialId + '/' + data.id,
      data
    );
  }

  public deleteItem(data: Compra) {
    return this.httpClient.post(
      this.service + '/delete/' + environment.filialId + '/' + data.id,
      data
    );
  }

  public cancelarCompraFinalizacao(data: Compra) {
    return this.httpClient
      .post(this.service + '/cancelar-compra-finalizacao/' + environment.filialId + '/' + data.id,
        data
      );
  }

  public last(type: string) {
    return this.httpClient
      .get(this.service + '/last/' + environment.filialId + '/' + type)
      .toPromise()
      .then((data: any) => (this.pedido = data.pedido));
  }

  public processo() {
    return this.httpClient
      .get(this.service + '/processo/' + environment.filialId)
      .toPromise()
      .then((data: any) => {
        this.pedido = data.pedido;
        return data;
      });
  }

  public finalizarProcesso(body) {
    return this.httpClient
      .post(this.service + '/processo/' + environment.filialId, body)
      .toPromise()
      .then((data: any) => {
        this.pedido = data.pedido;
        this.message = data.message;
        this.auth_url = data.auth_url;
        this.error = data.error;
      });
  }

  public finalizarPagamento(id: number, pg: number) {
    return this.httpClient
      .post(this.service + '/retorno-pagamento/' + environment.filialId, {
        compra_id: id,
        pagamento_key: pg
      })
      .toPromise()
      .then((data: any) => {
        this.status = data.status;
        this.message = data.message;
        this.transacao = data.transacao;
      });
  }

  public cancelarPagamento(id: number, pg: number) {
    const data = {compra_id: id, id: pg};
    return this.httpClient.post(
      this.service + '/cancelar-pagamento/' + environment.filialId + '/' + id,
      data
    );
  }

  public verificarPagamento(id: number, pg: number) {
    const data = {compra_id: id, id: pg};
    return this.httpClient.post(
      this.service + '/verificar-pagamento/' + environment.filialId + '/' + id,
      data
    );
  }

  public comprarNovamente(pedido: Compra) {
    return this.httpClient.post(
      this.service + '/comprar-novamente/' + environment.filialId + '/' + pedido.id,
      pedido
    );
  }

  public continuarComprando(pedido: Compra) {
    return this.httpClient.post(
      this.service +
        '/continuar-comprando/' +
        environment.filialId +
        '/' +
        pedido.id,
      pedido
    );
  }

  public solicitarTrocaDevolucao(tipo: string) {
    return this.httpClient
      .get(
        this.service +
          '/solicitar-troca-devolucao/' +
          environment.filialId +
          '/' +
          tipo
      )
      .toPromise()
      .then((data: any) => {
        this.pedidos = data.pedidos;
        this.message = data.message;
      });
  }

  public detalheTrocaDevolucao(id: number) {
    return this.httpClient
      .get(
        this.service + '/detalhe-troca-devolucao/' + environment.filialId + '/' + id
      )
      .toPromise()
      .then((data: { pedido }) => (this.pedido = data.pedido));
  }

  public cancelar(id: number) {
    return this.httpClient
      .post(this.service + '/cancelar/' + environment.filialId + '/' + id, {})
      .toPromise()
      .then((data: { pedido }) => {
        this.cookieService.delete('pedido');
        window.dispatchEvent(new Event('compraCriada'));
        this.pedido = <Compra>{};
        return data;
      });
  }

  public buscaInfoPedido(cdId: number, pedidoId: number) {
    const URI = `listas/compras/${pedidoId}/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
    return this.httpClient.get<any>(`${environment.apiUrl}/loja/${URI}`);
  }

  public buscaProdutosCompra(cdId: number, pedidoId: number, query?) {
    const URI = `listas/compras/${pedidoId}/filial/${environment.filialId}/centro_distribuicao/${cdId}/itens`;
    return this.httpClient.get<any>(`${environment.apiUrl}/loja/${URI}${query}`);
  }

  public alterarAplicacaoDesconto(pedido: Compra) {

    return this.httpClient
      .post(this.service + '/alterar-aplicacao-desconto/' + pedido.filial_id + '/' + pedido.id, {})
      .toPromise()
      .then((data: { pedido }) => {
        this.pedido = data.pedido;
        return data;
      });
  }

  public sincronizaCompraGoogleAnalytics() {
    const URI = `/sincroniza-compra-google-analytics/${this.pedido.id}`;
    return this.httpClient.post<any>(`${this.service}${URI}`, {});
  }

  public async validarProcessoCompra() {
    await this.processo();
    if (this.pedido && this.pedido.id) {
      alert('Sua compra está em processo de finalização.');
      this.router.navigate(['/checkout/pagamento']);
      return;
    }
    return true;
  }

    public getFormaPagamentoPorNumeroCartao(cdId: number, tipoPagamento: string, numCartao: string) {
        return this.httpClient
            .post<any>(`/forma_pagamentos/cd/${cdId}/tipo_pagamento/${tipoPagamento}`, { bin: numCartao })
            .toPromise();
    }
}
