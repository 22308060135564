import { Directive, HostListener, AfterContentInit, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import * as VanillaMaker from 'vanilla-masker';

@Directive({ selector: '[appEmailTelefone]' })
export class EmailTelefoneDirective implements AfterContentInit {

    constructor(private ngControl: NgControl, private element: ElementRef) { }

    ngAfterContentInit() {
        this.format(this.element.nativeElement.value || '');
    }

    @HostListener('input', ['$event.target.value']) onInput(value: string) {
        this.format(value);
    }

    private format(value) {
        
        let valueWithoutPhoneMask = value.replace(/[/\s-()]/g, '');
        
        if (value && (!isNaN(valueWithoutPhoneMask) && (valueWithoutPhoneMask != ''))) {
            value = VanillaMaker.toPattern(valueWithoutPhoneMask, this.mascaraTelefone(valueWithoutPhoneMask));
            this.element.nativeElement.type = 'text';
        }
        else if (value)
            this.element.nativeElement.type = 'email';
        this.ngControl.control.setValue(value);
    }

    private mascaraTelefone(value) {
        return value.length <= 10 ? '(99) 9999-9999' : '(99) 99999-9999';
    }
}