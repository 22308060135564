import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'match',
})
export class MatchPipe implements PipeTransform {
  transform(input: any, pattern: any, flag: any): any {
    const regexp = pattern instanceof RegExp ? pattern : new RegExp(pattern, flag);
    return input.match(regexp);
  }
}
