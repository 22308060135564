import { Component, OnInit, OnDestroy } from '@angular/core';
import { CentroDistribuicaoService } from './shared/centro-distribuicao.service';
import { CarrinhoService } from '../partials/carrinho/shared/carrinho.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClienteService } from '../clientes/shared/cliente.service';

@Component({
  selector: 'app-centro-distribuicao',
  templateUrl: './centro-distribuicao.component.html',
  styleUrls: ['./centro-distribuicao.component.less'],
})
export class CentroDistribuicaoComponent implements OnInit, OnDestroy {
  private alterarParaCd$: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdService: CentroDistribuicaoService,
    private carrinhoService: CarrinhoService,
    private clienteService: ClienteService
  ) {}

  ngOnInit() {
    this.alterarParaCdSubscription();
    this.processarAlteracaoCd();
  }

  ngOnDestroy(): void {
    this.alterarParaCd$.unsubscribe();
  }

  private alterarParaCdSubscription() {
    this.alterarParaCd$ = this.cdService.alterarParaCd$.subscribe(() => {
      this.redirecionar();
    });
  }

  private processarAlteracaoCd() {
    const cdAtual = this.cdService.id;
    const cdDestino = Number(this.route.snapshot.params.id);
    if (cdAtual !== cdDestino) {
      if (this.clienteService.logged()) {
        this.processarAlteracaoCdComCarrinho(cdAtual, cdDestino);
      } else {
        this.alterarParaCd(cdDestino);
      }
    } else {
      this.redirecionar();
    }
  }

  private processarAlteracaoCdComCarrinho(cdAtual, cdDestino) {
    this.carrinhoService.buscarCarrinho(cdAtual).subscribe((res) => {
      if (this.possuiCarrinho(res.data)) {
        this.alterarParaCd(cdDestino);
      } else {
        this.redirecionar();
      }
    });
  }

  private alterarParaCd(cdDestino) {
    this.cdService.buscaEAlteraCd(cdDestino);
  }

  private possuiCarrinho(carrinho) {
    return !carrinho || carrinho.quantidade === 0;
  }

  private redirecionar() {
    const queryParams = this.route.snapshot.queryParams;
    let url = '';
    if (queryParams && 'redirecionar_url' in queryParams) {
      url = this.removerDominio(queryParams.redirecionar_url);
    }
    this.router.navigate([`/${url}`]);
  }

  private removerDominio(redirecionarUrl) {
    return redirecionarUrl.split('/').slice(3).join('/');
  }
}
