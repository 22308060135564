import { Filial } from '../../filiais/shared/filiais';
import { Iframe } from '../../iframe/shared/iframe.interface';
import { Banner } from '../../partials/banners/shared/banners';
import { Produto } from '../../produtos/shared/produto';

export enum TipoPagina {
    INTERNA = 'interna',
    EXTERNA = 'externa',
    IFRAME = 'iframe'
}
export interface Pagina {
    titulo: string;
    subtitulo: string;
    slug: string;
    link: string;
    descricao: string;
    conteudo: any;
    tipo: TipoPagina;
    filial: Filial;
    produtos: Produto[];
    banners: Banner[];
    injecao_iframe: Iframe
}
