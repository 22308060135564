import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { BsModalService } from 'ngx-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { CepService } from 'src/app/ceps/shared/cep.service';
import { ClienteService } from 'src/app/clientes/shared/cliente.service';
import { Endereco } from 'src/app/enderecos/shared/enderecos';
import { CarrinhoAnonimoService } from 'src/app/partials/carrinho/shared/carrinho-anonimo.service';
import { CorreioService } from 'src/app/shared/services/correio.service';
import { AlterarLojaService } from '../shared/alterar-loja.service';
import { Cd } from '../shared/cd';
import { TipoExibicaoRetirada } from '../shared/tipo-exibicao-retirada';
import { FormaEntrega } from '../shared/tipo-retirada';


@Component({
  selector: 'app-alterar-loja',
  templateUrl: './alterar-loja.component.html',
  styleUrls: ['./alterar-loja.component.less'],
})
export class AlterarLojaComponent implements OnInit {
  @Input() hiddenTitle: boolean = false;
  @Input() hiddenClose: boolean | 'static' = false;
  @Input() showOptionsOnMultiple: boolean = false;
  @Input() validaTipoExibicaoRetirada: boolean = false;
  @Input() selectDefaults: boolean = false;
  @Output() changeTipoEntrega = new EventEmitter<FormaEntrega>();

  tipoExibicaoRetirada = TipoExibicaoRetirada;
  cep: string = null;
  prefReceberCompra: string;
  entregaDestino: Endereco;
  clientePreferences: any;
  cdAtual: Cd;
  cds: { entrega: Array<Cd>; retirada: Array<any> } = {
    entrega: [],
    retirada: [],
  };
  formaEntrega = FormaEntrega;
  changePreferences$: Subscription;
  exibirOpcaoEntrega: boolean = false;
  exibirErroCepNaoEncontrado: boolean = false;
  msgErroCepNaoEncontrado: string = '';
  permiteCadastro: boolean = false;
  loading: boolean = false;
  exibirSelecaoCd: boolean = false;
  public onClose: Subject<boolean>;

  constructor(
    private modalService: BsModalService,
    private alterarLojaService: AlterarLojaService,
    private clienteService: ClienteService,
    private cepService: CepService,
    private router: Router,
    private lsService: LocalStorageService,
    private correioService: CorreioService,
    @Inject('Window') private w: Window,
    private carrinhoAnonimoService: CarrinhoAnonimoService,
  ) {}

  ngOnInit() {
    this.onClose = new Subject();
    this.buscarEntregasERetiradas();
    this.loadClientPreferences();
    this.cdAtual = this.alterarLojaService.getOrigem();
    this.validateModalHiddenClose();
    this.changePreferences$ = this.clienteService.changePreferences$.subscribe(
      this.loadClientPreferences.bind(this)
    );
  }

  public ngOnDestroy() {
    this.changePreferences$.unsubscribe();
  }

  isLogged() {
    return this.clienteService.logged();
  }

  close() {
    this.onClose.next(true);
    this.modalService._hideModal(1);
  }

  selecionarRetirada(cd) {
    this.alterarLojaService.verificarPossibilidadeTrocarCarrinhoCd(this.cdAtual.id, cd.id)
      .subscribe(
        () => {
          this.savePreference();
          this.salvarLojaSelecionada(cd);
        },
        err => {
          if ('error' in err) {
            window.alert(err.error.error.message);
          }
        }
      );
  }

  confirmarEntrega() {
    this.salvarLojaSelecionada(this.cds.entrega[0]);
  }

  selecionarEndereco(value) {
    if (!value.addressSelected) {
      this.exibirSelecaoCd = false;
      return;
    }

    this.cepService.validaCepCd(value.addressSelected.cep).subscribe((response: any) => {
      if (response.data && response.data.length) {
        this.entregaDestino = value.addressSelected;
        this.cds.entrega = response.data;
        this.savePreference(value.addressSelected);

        if (value.confirmActionAddressSelected) {
          this.exibirSelecaoCd = true;
          this.verificarQuantidadeCds();
        }
      } else {
        alert('Não realizamos entrega no endereço selecionado.');
      }
    });
  }

  verificarQuantidadeCds() {
    if (this.cds.entrega && this.cds.entrega.length == 1) {
      this.salvarLojaSelecionada(this.cds.entrega[0]);
    }
  }

  private savePreference(entregaDestino?: Endereco) {
    this.clienteService.editPreference({
      tipoEntrega: this.prefReceberCompra,
      entregaDestino: entregaDestino,
    });
  }

  salvarLojaSelecionada(cd) {
    this.alterarLojaService.verificarPossibilidadeTrocarCarrinhoCd(this.cdAtual.id, cd.id)
      .subscribe(
        () => {
          const alterandoLoja = this.alterandoLoja(cd);
          if (alterandoLoja) {
            if (!this.confirmaAlteracaoLoja()) {
              return;
            }
            this.router.navigate(['/']);
          }

          this.lsService.set('cdConfirmado', true);
          this.alterarLojaService.salvarLojaSelecionada(cd);
          this.exibirSelecaoCd = false;
          this.close();
        },
        err => {
          if ('error' in err) {
            window.alert(err.error.error.message);
          }
        }
      );
  }

  private alterandoLoja(cd) {
    return this.cdAtual.id !== cd.id;
  }

  private confirmaAlteracaoLoja() {
    if (this.alterarLojaService.possuiCarrinhoIniciado()) {
      return this.alterarLojaService.confirmarAlteracaoCd();
    }
    return true;
  }

  private buscaRetiradas() {
    this.cepService.buscaRetiradas(this.cep).subscribe((req: any) => {
      this.cds.retirada = req.data;
      this.alterarPreferenciaAoValidarOpcoesDisponives();
    });
  }

  cepCompleto() {
    return this.cep && this.cep.length === 8;
  }

  validarCep(cep: string) {
    this.cep = cep.replace(/\-/g, '');
    this.prefReceberCompra = '';
    this.exibirErroCepNaoEncontrado = false;

    if (this.cepCompleto()) {
      this.loading = true;
      this.clienteService.permitirCadastro(this.cep).subscribe((res: any) => {
        this.permiteCadastro = res.success && res.data;
        if (this.permiteCadastro) {
          this.correioService.consultarCEP(cep).then(() => {
            this.loading = false;
            if (this.correioService.success === true) {
              this.cepService.validaCepCd(this.cep).subscribe((req: any) => {
                this.cds.entrega = req.data;
              });
              this.buscaRetiradas();
            } else {
              this.setCepInvalido();
            }
          });
        } else {
          this.loading = false;
        }
      })
    }
  }

  public setCepInvalido() {
    this.msgErroCepNaoEncontrado = this.correioService.errorMessage;
    this.exibirErroCepNaoEncontrado = true;
    this.permiteCadastro = false;

  }

  /**
   * Utiliza geolocalizacao para buscar o cep
   */
  utilizaGeolocalizacaoBuscarCep() {
    if (!this.isLogged()) {
      this.w.navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lon = position.coords.longitude;
        const buscaInfoGeolocation$ = this.cepService
          .buscaInfoGeolocation(lat, lon)
          .subscribe((res: any) => {
            if (res && 'address' in res) {
              const postcode = res.address.postcode;
              this.cep = postcode.replace(/^(\d{5})(\d{3}).*/, '$1-$2');
            }

            buscaInfoGeolocation$.unsubscribe();
          });
      });
    }
  }

  private loadClientPreferences() {
    this.clientePreferences = this.clienteService.getPreferences();
    this.prefReceberCompra = this.clientePreferences.tipoEntrega;
    this.entregaDestino = this.clientePreferences.entregaDestino;
  }

  public setPrefReceberCompra(formaEntrega: FormaEntrega) {

    if(this.prefReceberCompra != formaEntrega)  {
      this.prefReceberCompra = formaEntrega;
      this.changeTipoEntrega.emit(formaEntrega);

      if (formaEntrega === FormaEntrega.RETIRADA) {
        if (this.selectDefaults) {
          this.selecionarRetiradaPadrao();
        }
      }
    }
  }

  private selecionarRetiradaPadrao() {
    if (this.cds.retirada && this.cds.retirada.length === 1) {
      this.selecionarRetirada(this.cds.retirada[0]);
    }
  }

  private validateModalHiddenClose() {
    if (this.modalService.getModalsCount() > 0) {
      this.hiddenClose = this.modalService.config.ignoreBackdropClick;
    }
  }

  /*
   * Mostra as opções para selecionar 'retirada'/'entrega' quando
   * existir mais de uma opção disponível.
   *
   * Padrão sempre exibir.
   */
  public validateShowMultipleOptions() {
    return (
      !this.showOptionsOnMultiple ||
      (this.showOptionsOnMultiple &&
        this.cds.retirada.length > 0 &&
        this.exibirOpcaoEntrega)
    );
  }

  private buscarEntregasERetiradas() {
    if (this.isLogged()) {
      this.cepService.possuiEntregas().subscribe((res: any) => {
        this.exibirOpcaoEntrega = res.data;
        this.buscaRetiradas();
      });
    } else {
      this.exibirOpcaoEntrega = true;
    }
  }

  private alterarPreferenciaAoValidarOpcoesDisponives() {
    if (
      !this.exibirOpcaoEntrega &&
      this.prefReceberCompra !== FormaEntrega.RETIRADA
    ) {
      this.setPrefReceberCompra(FormaEntrega.RETIRADA);
    }

    if (this.existeSomenteOpcaoEntregaENaoEstaSelecionado()) {
      this.setPrefReceberCompra(FormaEntrega.ENTREGA);
    }
  }

  private existeSomenteOpcaoEntregaENaoEstaSelecionado() {
    return (
      !this.validateShowMultipleOptions() &&
      this.exibirOpcaoEntrega &&
      this.prefReceberCompra !== FormaEntrega.ENTREGA
    );
  }
}
