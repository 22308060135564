/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aceite-cookie.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./aceite-cookie.component";
import * as i4 from "../../clientes/shared/cliente.service";
var styles_AceiteCookieComponent = [i0.styles];
var RenderType_AceiteCookieComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AceiteCookieComponent, data: {} });
export { RenderType_AceiteCookieComponent as RenderType_AceiteCookieComponent };
export function View_AceiteCookieComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "lgpd--cookie"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "lgpd--cookie__opened": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Usamos "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["cookies"])), (_l()(), i1.ɵted(-1, null, [" para personalizar an\u00FAncios e melhorar a sua experi\u00EAncia no site. Ao continuar navegando, voc\u00EA concorda com a nossa "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["href", "/institucional/paginas/politica-de-privacidade"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pol\u00EDtica de Privacidade"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "lgpd--cookie-accept"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.aceitarUsoCookie() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["continuar e fechar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "lgpd--cookie"; var currVal_1 = _ck(_v, 3, 0, !_co.autorizadoUsarCookie); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AceiteCookieComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-aceite-cookie", [], null, null, null, View_AceiteCookieComponent_0, RenderType_AceiteCookieComponent)), i1.ɵdid(1, 114688, null, 0, i3.AceiteCookieComponent, [i4.ClienteService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AceiteCookieComponentNgFactory = i1.ɵccf("app-aceite-cookie", i3.AceiteCookieComponent, View_AceiteCookieComponent_Host_0, {}, {}, []);
export { AceiteCookieComponentNgFactory as AceiteCookieComponentNgFactory };
