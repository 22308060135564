import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CompartilharListaService {
  public service: string = '/minha-listas';
  private list: any = {};

  constructor(private httpClient: HttpClient) {}

  public get(hash: string): Promise<Object> {
    return this.httpClient.get(this.service + '/view/' + hash).toPromise();
  }

  public acceptInvite(payload: any): Promise<Object> {
    return this.httpClient
      .post(this.service + '/acceptInvite', payload)
      .toPromise();
  }

  public setList(list: any): void {
    this.list = list;
  }

  public getList(): any {
    return this.list;
  }
}
