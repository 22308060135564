
    <app-offline *ngIf="!viewService.isOnline()"></app-offline>
    <app-navbar>Navbar ...</app-navbar>
    <app-header>Header ...</app-header>
    <app-card-adicionar-carrinho></app-card-adicionar-carrinho>
    <app-busca-rapida></app-busca-rapida>
    <app-tabs *ngIf="!isMobileResolution">Tabs...</app-tabs>
    <app-banners>Banners...</app-banners>
    <app-tabs-bar>Tabs bar...</app-tabs-bar>
    <router-outlet></router-outlet>
    <app-footer *ngIf="!viewService.isInAppMode()">Footer...</app-footer>
  