import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FooterService {
    public showFooter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor() { }

    mostrarFooter() {
        this.showFooter$.next(true);
    }

    ocultarFooter() {
        this.showFooter$.next(false);
    }
}