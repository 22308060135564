<div class="container-fluid vip-tabs hidden-xs hidden-sm" *ngIf="showMenu">
  <div class="row vip-tabs-list">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <ul class="nav nav-tabs nav-pills text-center" role="tablist">
              <li role="presentation" class="{{ currentSwiper['departamentos'] ? 'active' : '' }}">
                <a href="javascript:void(0);" ria-controls="departamentos" role="tab" data-toggle="tab" class="text-uppercase" (click)="swiper('departamentos')" appMetrics
                   appMtActionClick="Tabs">Departamentos</a>
                <div class="circle">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </div>
              </li>
              <li role="presentation" class="{{ currentSwiper['ofertas'] ? 'active' : '' }}" *ngIf="haOfertas()">
                <a href="javascript:void(0);" ria-controls="ofertas" role="tab" data-toggle="tab" class="text-uppercase" (click)="swiper('ofertas')" appMetrics appMtActionClick="Tabs">Ofertas</a>
                <div class="circle">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </div>
              </li>
              <li role="presentation" class="{{ currentSwiper['combos'] ? 'active' : '' }}" *ngIf="haCombos()">
                <a href="javascript:void(0);" ria-controls="combos" role="tab" data-toggle="tab" class="text-uppercase" (click)="swiper('combos')" appMetrics appMtActionClick="Tabs">Combos</a>
                <div class="circle">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </div>
              </li>
              <li role="presentation" class="{{ currentSwiper['colecoes'] ? 'active' : '' }}" *ngIf="haColecoes()">
                <a href="javascript:void(0);" ria-controls="colecoes" role="tab" data-toggle="tab" class="text-uppercase" (click)="swiper('colecoes')" appMetrics appMtActionClick="Tabs">Coleções</a>
                <div class="circle">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </div>
              </li>
              <li role="presentation" class="{{ currentSwiper['receitas'] ? 'active' : '' }}" *ngIf="haReceitas()">
                <a href="javascript:void(0);" ria-controls="receitas" role="tab" data-toggle="tab" class="text-uppercase" (click)="swiper('receitas')" appMetrics appMtActionClick="Tabs">Receitas</a>
                <div class="circle">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </div>
              </li>
              <li role="presentation" class="{{ currentSwiper['mais-vendidos'] ? 'active' : '' }}">
                <a href="javascript:void(0);" aria-controls="mais-vendidos" role="tab" data-toggle="tab" class="text-uppercase" (click)="swiper('mais-vendidos')" appMetrics
                   appMtActionClick="Tabs">Mais vendidos</a>
                <div class="circle">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </div>
              </li>
              <li role="presentation" class="{{ currentSwiper['meus-favoritos'] ? 'active' : '' }}" *ngIf="haFavoritos()">
                <a href="javascript:void(0);" aria-controls="meus-favoritos" role="tab" data-toggle="tab" class="text-uppercase" (click)="swiper('meus-favoritos')" appMetrics
                   appMtActionClick="Tabs">Meus favoritos</a>
                <div class="circle">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row vip-tabs-content">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="tab-content">
              <div id="departamentos" class="tab-pane active swiper-container" [@fadeIn] *ngIf="currentSwiper['departamentos']">
                <ul class="nav nav-pills swiper-wrapper">
                  <li *ngFor="let departamento of departamentos; let i = index" (mouseleave)="over({})" (mouseover)="over(departamento)" class="swiper-slide {{
                      link == departamento.link ? 'active' : ''
                    }}">
                    <a routerLink="/produtos/departamento/{{
                        departamento.link
                      }}" class="featured" appMetrics appMtActionClick="Tabs > Departamento" [appMtLabel]="departamento.descricao">
                      <div>
                        <img [defaultImage]="getFallback()" [lazyLoad]="getUrl(departamento.imagem, 'categorias')" [offset]="100" width="50" class="image-category" />
                      </div>
                      <div class="text-category">
                        {{ departamento.descricao | capitalize }}
                      </div>
                    </a>
                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                  </li>
                </ul>
              </div>

              <div class="tab-content row">
                <div class="col-md-10 col-md-push-1 col-md-pull-1">
                  <div *ngFor="let departamento of departamentos; let i = index" (mouseleave)="over({})" (mouseover)="over(departamento)">
                    <div class="tab-pane-sub" [ngClass]="{ 'pane-left': i < 7, 'pane-right': i >= 7 }" *ngIf="departamento.link == link">
                      <h4>{{ departamento.descricao | capitalize }}</h4>
                      <ul class="custom-columns" [ngStyle]="{ height: isFirefox ? height : 'auto' }" id="section-columns">
                        <li *ngFor="let item of departamento.children">
                          <a routerLink="/produtos/departamento//{{ item.link }}" (click)="link = null" appMetrics appMtActionClick="Tabs > Departamento > Secao">{{ item.descricao | capitalize }}</a>
                        </li>
                      </ul>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>

              <div id="mais-vendidos" class="tab-pane swiper-container {{
                  currentSwiper['mais-vendidos'] ? 'active in' : ''
                }}" [@fadeIn] *ngIf="currentSwiper['mais-vendidos']">
                <ul class="nav nav-pills swiper-wrapper">
                  <li *ngFor="let departamento of departamentos" class="swiper-slide" appMetrics appMtActionClick="Tabs > Mais vendido" [appMtLabel]="departamento.descricao">
                    <a routerLink="/produtos/mais-vendidos/{{
                        departamento.link
                      }}" class="featured">
                      <div>
                        <img [defaultImage]="getFallback()" [lazyLoad]="getUrl(departamento.imagem, 'categorias')" [offset]="100" width="50" class="image-category" />
                      </div>
                      <div class="text-category">
                        {{ departamento.descricao | capitalize }}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div id="ofertas" class="tab-pane swiper-container {{
                  currentSwiper['ofertas'] ? 'active in' : ''
                }}" [@fadeIn] *ngIf="currentSwiper['ofertas']">
                <ul class="nav nav-pills swiper-wrapper">
                  <li *ngFor="let departamento of departamentos" [style.display]="
                      departamento.total_ofertas > 0 ? 'block' : 'none'
                    " class="swiper-slide" appMetrics appMtActionClick="Tabs > Oferta" [appMtLabel]="departamento.descricao">
                    <div class="nr-promotion pull-left" *ngIf="departamento.total_ofertas > 0">
                      <a routerLink="/produtos/ofertas/departamento/{{
                          departamento.link
                        }}">{{ departamento.total_ofertas }}</a>
                    </div>
                    <a routerLink="/produtos/ofertas/departamento/{{
                        departamento.link
                      }}" class="featured">
                      <div>
                        <img [defaultImage]="getFallback()" [lazyLoad]="getUrl(departamento.imagem, 'categorias')" [offset]="100" width="50" class="image-category" />
                      </div>
                      <div class="text-category">
                        {{ departamento.descricao | capitalize }}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div id="combos" class="tab-pane swiper-container {{
                  currentSwiper['combos'] ? 'active in' : ''
                }}" [@fadeIn] *ngIf="currentSwiper['combos']">
                <ul class="nav nav-pills swiper-wrapper">
                  <li *ngFor="let combo of combos" [style.display]="combo.quantidade > 0 ? 'block' : 'none'" class="swiper-slide" appMetrics appMtActionClick="Tabs > Combo"
                      [appMtLabel]="combo.descricao">
                    <div class="nr-promotion pull-left" *ngIf="combo.quantidade > 0">
                      <a routerLink="/produtos/combos/{{
                          combo.departamento_id
                        }}/{{ combo.link }}">{{ combo.quantidade }}</a>
                    </div>
                    <a routerLink="/produtos/combos/{{
                        combo.departamento_id
                      }}/{{ combo.link }}" class="featured">
                      <div>
                        <img [defaultImage]="getFallback()" [lazyLoad]="getUrl(combo.imagem, 'categorias')" [offset]="100" width="50" class="image-category" />
                      </div>
                      <div class="text-category">
                        {{ combo.descricao | capitalize }}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div id="colecoes" class="tab-pane swiper-container {{
                  currentSwiper['colecoes'] ? 'active in' : ''
                }}" [@fadeIn] *ngIf="currentSwiper['colecoes']">
                <ul class="nav nav-pills swiper-wrapper">
                  <li *ngFor="let colecao of colecoes" class="swiper-slide display-grid" appMetrics appMtActionClick="Tabs > Colecao" [appMtLabel]="colecao.descricao">
                    <a routerLink="/produtos/colecoes/{{ colecao.id }}/{{
                        colecao.slug
                      }}" class="featured">
                      <div class="img-category">
                        <img [defaultImage]="getFallback()" lazyLoad="{{ baseSrc + 'colecoes/' + colecao.icone }}" width="50" class="image-category" />
                      </div>
                      <div class="text-category">
                        {{ colecao.descricao | capitalize }}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div id="receitas" class="tab-pane swiper-container {{
                  currentSwiper['receitas'] ? 'active in' : ''
                }}" [@fadeIn] *ngIf="currentSwiper['receitas']">
                <ul class="nav nav-pills swiper-wrapper">
                  <li *ngFor="let receita of receitas" class="swiper-slide display-grid" appMetrics appMtActionClick="Tabs > Receita" [appMtLabel]="receita.descricao">
                    <a routerLink="/receitas/{{ receita.id }}/{{ receita.slug }}" class="featured">
                      <div class="img-category">
                        <img defaultImage="assets/img/receitas.png" lazyLoad="{{ baseSrc + 'receitas/' + receita.icone }}" src-fallback="assets/img/receitas.png" width="50" class="image-category" />
                      </div>
                      <div class="text-category">
                        {{ receita.descricao | capitalize }}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div id="listas" class="tab-pane swiper-container {{
                  currentSwiper['listas'] ? 'active in' : ''
                }}" [@fadeIn] *ngIf="currentSwiper['listas']">
                <ul class="nav nav-pills swiper-wrapper">
                  <li *ngFor="let lista of listas" class="swiper-slide display-grid" appMetrics appMtActionClick="Tabs > Lista" [appMtLabel]="lista.descricao">
                    <a routerLink="/listas/{{ lista.link }}" class="featured">
                      <div class="img-category">
                        <img defaultImage="assets/img/listas.png" lazyLoad="{{ baseSrc + 'listas/' + lista.imagem }}" width="50" class="image-category" />
                      </div>
                      <div class="text-category">
                        {{ lista.descricao | capitalize }}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <div id="meus-favoritos" class="tab-pane swiper-container {{
                  currentSwiper['meus-favoritos'] ? 'active in' : ''
                }}" [@fadeIn] *ngIf="currentSwiper['meus-favoritos']">
                <ul class="nav nav-pills swiper-wrapper">
                  <li *ngFor="let favorito of favoritos" class="swiper-slide" appMetrics appMtActionClick="Tabs > Meus favoritos" [appMtLabel]="favorito.descricao">
                    <div class="nr-promotion pull-left" *ngIf="favorito.quantidade > 0">
                      <a routerLink="/produtos/meus-favoritos/{{
                          favorito.link
                        }}">{{ favorito.quantidade }}</a>
                    </div>
                    <a routerLink="/produtos/meus-favoritos/{{ favorito.link }}" class="featured">
                      <div>
                        <img [defaultImage]="getFallback()" [lazyLoad]="getUrl(favorito.imagem, 'categorias')" [offset]="100" width="50" class="image-category" />
                      </div>
                      <div class="text-category">
                        {{ favorito.descricao | capitalize }}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <!-- If we need navigation buttons -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
