import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AplicativoService } from './aplicativo.service';

@Injectable({
  providedIn: 'root'
})
export class SmartBannerService {
  constructor(
    private meta: Meta,
    @Inject('Window') private w: Window,
    private aplicativoService: AplicativoService,
  ) {}

  public open() {
    if (this.allowOpenSmartBanner()) {
      this.getInfoBanner().subscribe((res: any) => {
        if (this.hasInfoToOpenStore(res.data)) {
          this.setInfoSmartBanner(res.data);
          this.openSmartBanner();
        }
      });
    }
  }

  private getInfoBanner() {
    return this.aplicativoService.getInfoAplicativo();
  }

  private allowOpenSmartBanner() {
    return this.w.document.cookie.indexOf('smartbanner_exited=1') === -1;
  }

  private hasInfoToOpenStore(info) {
    return info && (info.apple_id || info.package);
  }

  private openSmartBanner() {
    const w: any = this.w;
    if ('smartbanner' in w) {
      w.smartbanner.constructor();
      w.smartbanner.publish();
      w.document.getElementsByTagName('html')[0].style.cssText = '';
    }
  }

  private setInfoSmartBanner(info) {
    this.meta.addTags(
      [
        {
          name: 'smartbanner:title',
          content: info.titulo
        },
        {
          name: 'smartbanner:author',
          content: 'VipCommerce Sistemas'
        },
        {
          name: 'smartbanner:price-suffix-apple',
          content: ' - Na App Store'
        },
        {
          name: 'smartbanner:price-suffix-google',
          content: ' - No Google Play'
        },
        {
          name: 'smartbanner:icon-apple',
          content: '/assets/icons/android-icon-72x72.png'
        },
        {
          name: 'smartbanner:icon-google',
          content: '/assets/icons/apple-icon-72x72.png'
        },
        {
          name: 'smartbanner:button-url-apple',
          content: `https://apps.apple.com/br/app/${info.apple_id}`
        },
        {
          name: 'smartbanner:button-url-google',
          content: `http://play.google.com/store/apps/details?id=${info.package}`
        },
        {
          name: 'smartbanner:enabled-platforms',
          content: 'android,ios'
        },
        { name: 'smartbanner:price', content: 'Grátis' },
        { name: 'smartbanner:button', content: 'Abrir' },
        { name: 'smartbanner:close-label', content: 'Fechar' }
      ],
      true
    );
  }
}
