import { Component, ViewEncapsulation } from "@angular/core";
import { BsModalService } from "ngx-bootstrap";
import { Produto } from "../../../produtos/shared/produto";
import { MinhasListas } from "./../../interfaces/minhas-listas";

@Component({
  styleUrls: ["./minhas-listas-replace.component.css"],
  templateUrl: "./minhas-listas-replace.component.html",
  encapsulation: ViewEncapsulation.None
})
export class MinhasListasReplaceComponent {
  public list: MinhasListas = <MinhasListas>{};
  public products: Array<{ infoProduto: any; novoProduto: Produto }> = [];
  public onHide: Function = res => {};

  public constructor(private modalService: BsModalService) { }

  public close(salvouProduto?: boolean) {
    this.modalService._hideModal(1);
    this.onHide(!!salvouProduto);
  }

  public afterReplace(response) {
    this.close(response && response.success);
  }
}
