import {Injectable, ApplicationRef, Inject} from '@angular/core';

@Injectable()
export class MatchMediaService {
    constructor(@Inject('Window') private w: Window) { }

    private rules = {
        print: "print",
        screen: "screen",
        phone: '(max-width: 767px)',
        tablet: '(min-width: 768px) and (max-width: 1024px)',
        desktop: '(min-width: 1025px)',
        portrait: '(orientation: portrait)',
        landscape: '(orientation: landscape)',
        retina: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)'
    };

    isPhone(): boolean {
        return this.w.matchMedia(this.rules.phone).matches;
    }

    isTablet(): boolean {
        return this.w.matchMedia(this.rules.tablet).matches;
    };

    isDesktop(): boolean {
        return this.w.matchMedia(this.rules.desktop).matches;
    };

    isPortrait(): boolean {
        return this.w.matchMedia(this.rules.portrait).matches;
    };

    isLandscape(): boolean {
        return this.w.matchMedia(this.rules.landscape).matches;
    };

    isRetina(): boolean {
        return this.w.matchMedia(this.rules.retina).matches;
    };

    public isMobile(): boolean {
      return this.w.innerWidth < 992;
    }
}
