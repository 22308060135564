/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./escolher-carrinho-cliente.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./escolher-carrinho-cliente.component";
import * as i3 from "ngx-bootstrap/modal";
var styles_EscolherCarrinhoClienteComponent = [i0.styles];
var RenderType_EscolherCarrinhoClienteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EscolherCarrinhoClienteComponent, data: {} });
export { RenderType_EscolherCarrinhoClienteComponent as RenderType_EscolherCarrinhoClienteComponent };
export function View_EscolherCarrinhoClienteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "escolha-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "texto-escolha"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" H\u00E1 um carrinho de compras vinculado ao seu usu\u00E1rio. Deseja manter o carrinho existente? "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "btn btn-success btn-sm"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmar(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fa fa-check-square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵted(-1, null, ["Manter existente"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "btn btn-primary btn-sm pull-right"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmar(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fa fa-minus-square"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵted(-1, null, ["Manter novo"]))], null, null); }
export function View_EscolherCarrinhoClienteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-escolher-carrinho-cliente", [], null, null, null, View_EscolherCarrinhoClienteComponent_0, RenderType_EscolherCarrinhoClienteComponent)), i1.ɵdid(1, 49152, null, 0, i2.EscolherCarrinhoClienteComponent, [i3.BsModalService], null, null)], null, null); }
var EscolherCarrinhoClienteComponentNgFactory = i1.ɵccf("app-escolher-carrinho-cliente", i2.EscolherCarrinhoClienteComponent, View_EscolherCarrinhoClienteComponent_Host_0, {}, {}, []);
export { EscolherCarrinhoClienteComponentNgFactory as EscolherCarrinhoClienteComponentNgFactory };
