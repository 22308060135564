import { BsModalService } from 'ngx-bootstrap';
import { LimiteCdComponent } from '../limite-cd/limite-cd.component';
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal";
export class LimiteCdService {
    constructor(modalService) {
        this.modalService = modalService;
    }
    abrirLimiteCd(ignoreBackdrop = false) {
        return this.modalService.show(LimiteCdComponent, {
            keyboard: !ignoreBackdrop,
            ignoreBackdropClick: ignoreBackdrop,
            class: 'modal-loja-selector',
        });
    }
    fecharLimiteCd() {
        this.modalService._hideModal(1);
    }
}
LimiteCdService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LimiteCdService_Factory() { return new LimiteCdService(i0.ɵɵinject(i1.BsModalService)); }, token: LimiteCdService, providedIn: "root" });
