<div class="vip-overlay-wrapper">
  <div class="vip-overlay"></div>
</div>
<ng-container *ngIf="show_tabs_bar == true">
  <div class="vip-tabs-bar">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-10 col-md-8 col-lg-7">
          <a class="vip-tabs-bar__item vip-tabs-bar__item--first" routerLink="/"
            ><i class="fa fa-home" aria-hidden="true"></i
          ></a>
          <ng-container *ngFor="let link of links; let i = index">
            <div
              class="vip-tabs-bar__item"
              [ngClass]="{
                'vip-categorias':
                  i == 0 && categorias != undefined && categorias.length > 0
              }"
            >
              <i class="separator"></i>
              <span *ngIf="link.url == undefined">{{
                link.titulo | capitalize
              }}</span>
              <span
                *ngIf="link.url != undefined"
                routerLink="{{ link.url }}"
                style="cursor: pointer"
                >{{ link.titulo | capitalize }}</span
              >
              <ul *ngIf="i == 0" class="vip-categorias__menu">
                <li *ngFor="let categoria of categorias">
                  <a
                    routerLink="produtos/departamento/{{ departamento.slug }}/{{
                      categoria.slug
                    }}"
                    >{{ categoria.descricao }}</a
                  >
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
        <div *ngIf="filter === true" class="hidden-xs hidden-sm col-md-4">
          <a
            href="javascript:void(0);"
            (click)="navegar({ page: '1' }, true)"
            title="Limpar filtros"
            ><i class="fa fa-filter" aria-hidden="true"></i
          ></a>

          <i class="separator"></i>

          <a
            href="javascript:void(0);"
            (click)="navegar({ orderby: 'produto.quantidade_vendida:desc' })"
            title="Mais vendidos"
            ><i class="fa fa-star" aria-hidden="true"></i
          ></a>
          <i class="separator"></i>
          <a
            href="javascript:void(0);"
            (click)="navegar({ orderby: 'produto.descricao' })"
            title="Ordem crescente"
            ><i class="fa fa-sort-alpha-asc" aria-hidden="true"></i
          ></a>
          <a
            href="javascript:void(0);"
            (click)="navegar({ orderby: 'produto.descricao:desc' })"
            title="Ordem decrescente"
            ><i class="fa fa-sort-alpha-desc" aria-hidden="true"></i
          ></a>
          <i class="separator"></i>
          <a
            href="javascript:void(0);"
            (click)="navegar({ orderby: 'produto.preco' })"
            title="Menor preço"
            ><i class="fa fa-sort-numeric-asc" aria-hidden="true"></i
          ></a>
          <a
            href="javascript:void(0);"
            (click)="navegar({ orderby: 'produto.preco:desc' })"
            title="Maior preço"
            ><i class="fa fa-sort-numeric-desc" aria-hidden="true"></i
          ></a>
          <i class="separator"></i>
          <div
            class="vip-tabs-bar__item vip-filtro-marcas vip-categorias"
            *ngIf="marcas != null && marcas.length > 0"
          >
            <span>{{
              marcaAtiva != null ? marcaAtiva.descricao : 'Marcas'
            }}</span>
            <ul class="vip-categorias__menu vip-categorias__menu--marca">
              <li *ngFor="let marca of marcas">
                <a
                  href="javascript:void(0)"
                  (click)="navegar({ marca: marca.slug })"
                  >{{ marca.descricao }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="hidden-xs hidden-sm col-md-3 col-lg-3"></div>
      </div>
    </div>
  </div>
</ng-container>
