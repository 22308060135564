<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title text-info text-center">
    Lojas
 </h4>
</div>
<div class="modal-body">
  <ul *ngIf="cds.length">
    <li *ngFor="let cd of cds">{{ cd }}</li>
  </ul>
</div>
