import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfiguracaoService } from './../shared/services/configuracao.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-seletor-loja',
  templateUrl: './seletor-loja.component.html',
  styleUrls: ['./seletor-loja.component.css'],
})
export class SeletorLojaComponent {
  public filiais: any = {};
  public filtered: any = [];
  public loja: any = {};
  public showCep: boolean = true;
  public showFoundStore: boolean = false;
  public showAllStore: boolean = false;
  public showRedirect: boolean = false;
  public manualSelection: boolean = true;
  private main_domain: string = '';

  constructor(private configService: ConfiguracaoService) {
    this.main_domain = environment.main_domain.replace(/(\w+\.)(\w+\.com\.br)/g, '$2');
  }

  public getCep() {
    this.configService.getDomainByCep(this.main_domain, this.loja.cep).then((response: any) => {
      let data = response.data;
      this.manualSelection = false;
      if (!data.length) {
        this.showCep = false;
        this.showAllStore = true;
        this.showFoundStore = false;
      } else if (data.length === 1) {
        this.showRedirect = true;
        window.location.href = 'https://' + data[0].dominio;
      } else if (data.length > 1) {
        this.filtered = data;
        this.showCep = false;
        this.showAllStore = false;
        this.showFoundStore = true;

      }
    });
  }

  public backToCep() {
    this.showCep = true;
    this.showFoundStore = false;
    this.showAllStore = false;
  }

  public displayAllStore() {
    this.manualSelection = true;
    this.showCep = false;
    this.showFoundStore = false;
    this.showAllStore = true;
  }
}
