import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

@Directive({
    selector: '[observeVisibility]',
})
export class ObserveVisibilityDirective
    implements OnDestroy, OnInit, AfterViewInit
{
    @Input() debounceTime = 200;
    @Input() threshold = 1;

    @Output() visible = new EventEmitter<HTMLElement>();

    private observer: IntersectionObserver | undefined;
    private subject$ = new Subject<{
        entry: IntersectionObserverEntry;
        observer: IntersectionObserver;
    }>();

    constructor(private element: ElementRef) {}

    ngOnInit() {
        this.createObserver();
    }

    ngAfterViewInit() {
        this.startObservingElements();
    }

    ngOnDestroy() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = undefined;
        }

        this.subject$.next();
        this.subject$.complete();
    }

    isVisible(element: HTMLElement) {
        return new Promise<boolean>((resolve) => {
            const observer = new IntersectionObserver(([entry]) => {
                resolve(entry.intersectionRatio === 1);
                observer.disconnect();
            });

            observer.observe(element);
        });
    }

    createObserver() {
        const options = {
            rootMargin: '0px',
            threshold: this.threshold,
        };

        const isIntersecting = (entry: IntersectionObserverEntry) =>
            entry.isIntersecting || entry.intersectionRatio > 0;

        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (isIntersecting(entry)) {
                    this.subject$.next({ entry, observer });
                }
            });
        }, options);
    }

    startObservingElements() {
        if (!this.observer) {
            return;
        }

        this.observer.observe(this.element.nativeElement);

        this.subject$
            .pipe(
                delay(this.debounceTime),
                filter(
                    (
                        value
                    ): value is {
                        entry: IntersectionObserverEntry;
                        observer: IntersectionObserver;
                    } => value !== undefined
                )
            )
            .subscribe({
                next: ({ entry, observer }) => {
                    this.handleVisibility(entry, observer);
                },
            });
    }

    async handleVisibility(
        entry: IntersectionObserverEntry,
        observer: IntersectionObserver
    ) {
        const target = entry.target as HTMLElement;
        const isStillVisible = await this.isVisible(target);

        if (isStillVisible) {
            this.visible.emit(target);
            observer.unobserve(target);
        }
    }
}
