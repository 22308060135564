import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'app-confirmar-remover-cartao-salvo',
    templateUrl: './confirmar-remover-cartao-salvo.component.html',
    styleUrls: ['./confirmar-remover-cartao-salvo.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmarRemoverCartaoSalvoComponent {
    @Input() cartao: any;
    public onHide: Function;

    constructor(private modalService: BsModalService) {}

    fechar(confirmado = false) {
        this.onHide(confirmado);
        this.modalService._hideModal(1);
    }

}
