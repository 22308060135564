<div class="criar-conta-modal">
  <i class="fa fa-close criar-conta-modal__close" aria-hidden="true" (click)="closeButton()"></i>
  <header class="title">
    <h4>Entre com Número de Celular</h4>
  </header>
  <section>
    <div class="subtitle">
      <p>Por favor, informe seu CPF e número do seu
      celular no campo abaixo para continuar.</p>
    </div>
    <form class="login-modal__form" #loginForm="ngForm">
      <div class="form-group">
        <label class="control-label">CPF</label>
        <input id="cpf" name="cpf" type="text" class="form-control" [(ngModel)]="user.cpf" [mask]="'999.999.999-99'" required
          #cpf="ngModel" />
        <span *ngIf="cpf.invalid && (cpf.dirty || cpf.touched)" class="text-danger error">
          <span *ngIf="cpf.errors?.required">Campo obrigatório</span>
        </span>
        <span *ngIf="!validarCPF()" class="text-danger error">
          <span>CPF inválido</span>
        </span>
      </div>
      <div class="form-group">
        <label class="control-label">Número do Celular</label>
        <input id="celular" name="celular" type="text" class="form-control" [(ngModel)]="user.celular" (change)="validarCelular()" [mask]="'(99) 99999-9999'" required
          #celular="ngModel" />
        <span *ngIf="celular.invalid && (celular.dirty || celular.touched)" class="text-danger error">
          <span *ngIf="celular.errors?.required">Campo obrigatório</span>
        </span>
      </div>
      <input type="hidden" value="login-cliente" name="validacaoBot" [(ngModel)]="validacaoBot" />
      <button type="button"  (click)="solicitarSmsPin()" [disabled]="bloquearBotaoLogin"
        class="btn btn-get-in" [disabled]="!loginForm.form.valid || loading">
        <span *ngIf="!loading">Avançar</span>
        <span *ngIf="loading"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Aguarde</span>
      </button>
    </form>
  </section>
</div>