import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConfiguracaoService {
  private headers = {
    'DomainKey': 'dominios.vipcommerce.com.br'
  };
  public service: string = '/configuracao/dominios';
  public url: string = '';

  /**
   * Creates an instance of ConfiguracaoService.
   * @constructor
   * @param {HttpClient} httpClient
   */
  constructor(private httpClient: HttpClient) {
    this.url = environment.apiConfig;
  }

  /**
   * Find all domains available to client domain
   * @param {string} domain - Client's domain
   * @returns {Promise}
   */
  public getDomains(domain: string): Promise<Object> {
    let options: any = {
      headers: this.headers,
      params: this.getParams({ dominio: domain })
    };

    return this.httpClient.get(this.url + this.service, options).toPromise();
  }

  /**
   * Find domain available by CEP
   * @param {string} domain - Client's domain
   * @param {string} cep - Client's cep
   * @returns {Promise}
   */
  public getDomainByCep(domain: string, cep: string): Promise<Object> {
    let options: any = {
      headers: this.headers,
      params: this.getParams({ dominio: domain, cep })
    };
    return this.httpClient
      .get(this.url + this.service + '/cep', options)
      .toPromise();
  }

  /**
   * Convert object to HttpParams
   * @param params
   * @returns {HttpParams}
   */
  private getParams(params: {}): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      httpParams = httpParams.append(key, params[key]);
    });
    return httpParams;
  }
}
