import { Injectable } from '@angular/core';
import { Colecao } from '../interfaces/colecoes';
import { HttpClient } from '@angular/common/http';
import { HttpProduto } from 'src/app/produtos/shared/httpProduto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ColecaoService {
  private _colecoes: Colecao[] = [];
  public get colecoes(): Array<Colecao> {
    return this._colecoes;
  };

  private service: string = '/colecoes';

  constructor(private httpClient: HttpClient) {}

  home() {
    // TODO: Remover duplicidade. Ao carregar a aplicação a função é executada duas vezes
    const URI = `colecoes/vigentes/filial/${environment.filialId}`
    return this.httpClient.get(`${environment.apiUrl}/loja/${URI}`)
      .toPromise()
      .then((res: any) => {
        this._colecoes = res.data;
        window.dispatchEvent(new Event('atualizarColecoes'));
      });
  }

  homePerfis() {
    return this.httpClient
      .get(this.service + '/home-perfis/' + environment.filialId)
      .toPromise()
      .then((data: any) => {
        this._colecoes = data.colecoes;
      });
  }

  produtosColecao(cdId, colecaoId, query?): Observable<HttpProduto> {
    const URI = `colecoes/${colecaoId}/produtos/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
    return this.httpClient.get<HttpProduto>(
      `${environment.apiUrl}/loja/${URI}${query}`
    );
  }

  colecaoPorId(colecaoId: number) {
    return this._colecoes.find(colecao => {
      return colecao.id === Number(colecaoId);
    })
  }
}
