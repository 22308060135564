import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpProduto } from '../../shared/httpProduto';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable()
export class ProdutoTagService {
    constructor(private httpClient: HttpClient, private lsService: LocalStorageService) { }

    produtossTag(tagId: string, cdId: number, query?): Observable<HttpProduto> {
        const URI = `filial/${environment.filialId
            }/centro_distribuicao/${cdId}/loja/produtos/tag/${tagId}`;
        return this.httpClient.get<HttpProduto>(
            `${environment.apiUrl}/org/${this.lsService.get('organizacao')}/${URI}${query}`
        );
    }

}
