<a [routerLink]="getRouterLink()" (click)="clickAction()">
  <div
    class="img-container"
    [ngStyle]="{
      'width.px': !imgLoaded ? tamanho : 0,
      'height.px': !imgLoaded ? tamanho : 0
    }"
    *ngIf="!imgLoaded"
  >
    <div class="img-container--loader fa-2x">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
  </div>
  <div
    [ngStyle]="{
      'height.px': imgLoaded ? tamanho : 0,
      visibility: imgLoaded ? 'visible' : 'hidden'
    }"
  >
    <img
      [defaultImage]="getFallback()"
      [lazyLoad]="srcImagem"
      [offset]="offset"
      alt="{{ produto.descricao }}"
      title="{{ produto.descricao }}"
      class="img-container--product {{ classe }}"
      width="{{ tamanho }}"
      (onLoad)="onLoadImage($event)"
      [ngClass]="{ show: imgLoaded }"
    />
  </div>
</a>
