<div class="modal-header">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="fecharModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title text-info" id="solicitarCancelamentoLabel">
    Cancelar pagamento: {{ compra.id }}
  </h4>
</div>
<form id="formCancelar" (submit)="cancelar(compra)">
  <div class="modal-body">
    <span class="text-muted">Você será direcionado para selecionar uma nova forma de pagamento</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" id="closeModalButton" (click)="fecharModal()">
      Fechar
    </button>
    <button type="submit" class="btn btn-primary">
      Realizar cancelamento
    </button>
  </div>
</form>
