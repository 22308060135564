import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { timer } from 'rxjs';
import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';
import { CarrinhoService } from '../shared/carrinho.service';
import { TipoProdutoCarrinho } from '../shared/tipo-produto-carrinho';
import { ProdutoDetalheService } from 'src/app/produtos/produto-detalhe/shared/produto-detalhe.service';
import { DepartamentoService } from 'src/app/shared/services/departamento.service';
import { FilialService } from 'src/app/filiais/shared/filial.service';

@Component({
    selector: 'app-carrinho-spinner',
    templateUrl: './carrinho-spinner.component.html',
    styleUrls: ['./carrinho-spinner.component.css'],
    providers: [ProdutoDetalheService]
})
export class CarrinhoSpinnerComponent implements OnInit {
    @Input() carrinhoId: number;
    @Input() itemId: number;
    @Input() produtoId: number;
    @Input() quantidade: number = 1;
    @Input() tipoProduto: TipoProdutoCarrinho;
    @Input() salvarAposAlteracao: boolean = true;
    @Output() quantidadeChange = new EventEmitter();

    @Input() callback: number = 0;
    @Input() class: number = 0;
    @Input() preco_venda: number;
    @Input() total_preco: number;
    @Input() quantidadeMaxima: number;
    @Input() quantidadeMinima: number = 0;

    private ultimaQuantidade: number = 1;
    private updateQtTimer;

    constructor(
        private carrinhoService: CarrinhoService,
        private cdService: CentroDistribuicaoService,
        private produtoDetalheService: ProdutoDetalheService,
        private departamentoService: DepartamentoService,
        private filialService: FilialService
    ) {}

    ngOnInit(): void {
        this.ultimaQuantidade = this.quantidade;
    }

    public updateQtSpinner() {

        let timerMs: number = (this.salvarAposAlteracao) ? 500 : 100;

        if (this.errorQtMax()) {
            this.quantidade = this.quantidadeMaxima;
            this.carrinhoService.showMsgErrorQtMax();
        }

        if (this.updateQtTimer) {
            this.updateQtTimer.unsubscribe();
        }

        this.updateQtTimer = timer(timerMs).subscribe(() => this.atualizar());
    }

    mais() {
        this.ultimaQuantidade = this.quantidade;
        this.quantidade = parseInt(this.quantidade.toString(), 10) + 1;
        this.updateQtSpinner();
    }

    menos() {
        this.ultimaQuantidade = this.quantidade;
        this.quantidade = this.quantidade - 1;

        if (this.quantidade >= this.quantidadeMinima) {
            this.updateQtSpinner();
        } else {
            this.quantidade = this.quantidadeMinima;
        }
    }

    atual() {
        this.quantidadeChange.emit(this.quantidade);
        if (this.callback == 1) {
            this.updateQtSpinner();
        }
    }

    private validaRemoverItem(): boolean {
        return this.quantidade <= 0 && !!this.carrinhoId && !!this.itemId;
    }

    private confirmarExclusao() {
        return this.carrinhoService.remover();
    }

    private cancelaExlusao() {
        this.quantidade = this.ultimaQuantidade;
        this.quantidadeChange.emit(this.ultimaQuantidade);
    }

    atualizar() {
        if (this.validaRemoverItem()) {
            if (this.confirmarExclusao()) {
                let resp = this.carrinhoService.apagarItem(this.cdService.id, this.itemId);
                this.buscaProdutoDetalhe(this.cdService.id, this.produtoId);
                return resp;
            }
            return this.cancelaExlusao();
        }

        if (this.errorQtMax()) {
            this.quantidade = this.quantidadeMaxima;
            this.carrinhoService.showMsgErrorQtMax();
        }

        if (this.salvarAposAlteracao) {
            this.salvarAposAlteracao = false;
            return this.carrinhoService.addItem(
                this.cdService.id,
                this.produtoId,
                this.quantidade,
                this.tipoProduto
            );
        }

        this.quantidadeChange.emit(this.quantidade);
    }

    private errorQtMax() {
        if (!this.quantidadeMaxima) {
            return false;
        }

        return Number(this.quantidade) > Number(this.quantidadeMaxima);
    }

    comprar() {
        this.salvarAposAlteracao = true;
        this.updateQtSpinner();
    }

    public buscaProdutoDetalhe(cdId, produtoId) {
        this.produtoDetalheService.detalhe(cdId, produtoId).subscribe(
          (req: any) => {
            let produto = req.data.produto;
            let cmId = req.data.produto.classificacao_mercadologica_id;
            produto.departamento = this.departamentoService.findDepartamentoBySecaoId(cmId);
            produto.secao = this.departamentoService.findSecaoBySecaoId(produto.departamento, cmId);
            this.removeFromCartGoogleAnalytics(produto);
          }
        );
      }
    
    public removeFromCartGoogleAnalytics(produto) {
        if(this.filialService.detail.ga4) {
            let itens: Array<any> = [];
      
            let add = {
              item_id: produto.id,
              item_name: produto.descricao,
              item_category: produto.departamento.descricao,
              item_category2: produto.secao.descricao,
              price: null,
              discount: null,
              quantity: this.quantidade
            }
      
            if (produto.em_oferta) {
              add.price = produto.oferta.preco_oferta
              add.discount = produto.oferta.preco_antigo - produto.oferta.preco_oferta;
            }
      
            itens.push(add);
      
            gtag('event', 'remove_from_cart', {
              currency: 'BRL',
              value: add.price ? (add.price * this.quantidade) : (produto.preco * this.quantidade),
              items: itens
            });
          }
    }
}
