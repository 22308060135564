/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmar-remover-cartao-salvo.component.less.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./confirmar-remover-cartao-salvo.component";
import * as i4 from "ngx-bootstrap/modal";
var styles_ConfirmarRemoverCartaoSalvoComponent = [i0.styles];
var RenderType_ConfirmarRemoverCartaoSalvoComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ConfirmarRemoverCartaoSalvoComponent, data: {} });
export { RenderType_ConfirmarRemoverCartaoSalvoComponent as RenderType_ConfirmarRemoverCartaoSalvoComponent };
function View_ConfirmarRemoverCartaoSalvoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "image-cards"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "margin: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cartao.url_imagem; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.cartao.numero_cartao; _ck(_v, 3, 0, currVal_1); }); }
export function View_ConfirmarRemoverCartaoSalvoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "escolha-modal"], ["role", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [["class", "texto-escolha"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Deseja realmente remover o cart\u00E3o? "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "wbr", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Lembrando que este cart\u00E3o deixar\u00E1 de estar dispon\u00EDvel. "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmarRemoverCartaoSalvoComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-light btn-sm pull-left"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fechar(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancelar "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary btn-sm pull-right"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fechar(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sim, remover "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cartao; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_ConfirmarRemoverCartaoSalvoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmar-remover-cartao-salvo", [], null, null, null, View_ConfirmarRemoverCartaoSalvoComponent_0, RenderType_ConfirmarRemoverCartaoSalvoComponent)), i1.ɵdid(1, 49152, null, 0, i3.ConfirmarRemoverCartaoSalvoComponent, [i4.BsModalService], null, null)], null, null); }
var ConfirmarRemoverCartaoSalvoComponentNgFactory = i1.ɵccf("app-confirmar-remover-cartao-salvo", i3.ConfirmarRemoverCartaoSalvoComponent, View_ConfirmarRemoverCartaoSalvoComponent_Host_0, { cartao: "cartao" }, {}, []);
export { ConfirmarRemoverCartaoSalvoComponentNgFactory as ConfirmarRemoverCartaoSalvoComponentNgFactory };
