import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TermosUsoService } from 'src/app/shared/services/termos-uso.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-termos-uso',
    templateUrl: './termos-uso.component.html',
    styleUrls: ['./termos-uso.component.css'],
})
export class TermosUsoComponent implements OnInit {
    form: FormGroup;

    constructor(
        private bsModalRef: BsModalRef,
        public termoUsoService: TermosUsoService,
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit() {
        this.criarFormulario();
        this.getTermoUsoVigente();
    }

    private criarFormulario() {
        this.form = this.formBuilder.group({
            termos_aceite: [false, [Validators.requiredTrue]],
        });
    }

    private async getTermoUsoVigente() {
        await this.termoUsoService.getTermoUsoVigente(environment.filialId);
    }

    aprovarTermoUso() {
        const termoUsoId = this.termoUsoService.termoUso.id;
        this.termoUsoService.aprovarTermoUso(termoUsoId).then(() => {
            this.close();
            this.termoUsoService.aprovacaoPendente = false;
        });
    }

    close() {
        this.bsModalRef.hide();
    }
}
