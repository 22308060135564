<div class="observacao-modal">
  <div class="form-group">
    <textarea rows="5" class="form-control" id="observacao" placeholder="Adicione sua observação"
      [(ngModel)]="item.observacao" maxlength="200"></textarea>
  </div>
  <button type="submit" class="btn btn-success" (click)="fechar(true)"><i class="fa fa-check-square"
      aria-hidden="true">&nbsp;</i>Confirmar</button>
  <button type="submit" class="btn btn-danger pull-right" (click)="fechar()"><i class="fa fa-minus-square"
      aria-hidden="true">&nbsp;</i>Cancelar</button>
</div>
