<div class="modal-loja">
	<div class="title">Bem-vindo ao {{filiais.titulo}}</div>
	<div class="main" *ngIf="showCep">
		<div class="header">
			<div class="info">Informe seu CEP</div>
			<div class="text">Para acessar uma de nossas lojas, informe o CEP do endereço de entrega:</div>
		</div>
		<form class="form" (ngSubmit)="getCep()" #storeForm="ngForm">
			<div class="form-group input-group">
				<input id="cep" name="cep" class="form-control" type="tel" required minlength="9" [(ngModel)]="loja.cep" [mask]="'99999-999'" #cep="ngModel">
			</div>
			<div *ngIf="storeForm.form.invalid && storeForm.form.dirty && storeForm.form.touched" class="text-danger error">
				<div *ngIf="cep.errors.required">
					Digite um CEP
				</div>
				<div *ngIf="!cep.errors.required && cep.errors.minlength">
					CEP inválido
				</div>
			</div>
			<button type="submit" class="btn btn-primary button" [disabled]="!storeForm.form.valid">
				<span>Buscar</span>
			</button>
		</form>
		<div (click)="displayAllStore()" class="back"><i class="fa fa-chevron-down" aria-hidden="true"></i>Ou selecione uma loja</div>
		<div class="back redirect" *ngIf="showRedirect">Redirecionando...</div>
	</div>

	<div class="store-found" *ngIf="showFoundStore">
		<div class="back" (click)="backToCep()"><i class="fa fa-chevron-left" aria-hidden="true"></i>Voltar</div>
		<div class="info">Por favor, selecione uma loja para iniciar sua compra:</div>
		<div class="store-link-list">
			<a class="store-link" [href]="'https://' + filial.dominio" *ngFor="let filial of filtered">{{filial.titulo}}</a>
		</div>
	</div>

	<div class="all-stores" *ngIf="showAllStore">
		<div class="back" (click)="backToCep()"><i class="fa fa-chevron-left" aria-hidden="true"></i>Voltar</div>
		<div class="info" *ngIf="manualSelection">Por favor, selecione uma loja para iniciar sua compra:</div>
		<div class="info" *ngIf="!manualSelection">Ainda não atendemos sua região, mas você ainda pode visitar algumas de nossas lojas:</div>
		<div class="store-link-list">
			<a class="store-link" [href]="'https://' + filial.dominio" *ngFor="let filial of filiais.dominios">{{filial.titulo}}</a>
		</div>
	</div>
</div>
