import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarrinhoAnonimoService {

  public carrinhoIniciado = false;

  private _permitirCarrinhoAnonimo = false;
  public get permitirCarrinhoAnonimo(): boolean {
    return this._permitirCarrinhoAnonimo;
  }

  private _carrinhoAnonimoVerificado = true;
  public get carrinhoAnonimoVerificado(): boolean {
    return this._carrinhoAnonimoVerificado;
  }

  private _sincronizarCarrinhoAnonimo = new Subject();
  public sincronizarCarrinhoAnonimo$: Observable<any> = this._sincronizarCarrinhoAnonimo.asObservable();

  private _escolhaCarrinhoCliente = new Subject();
  public escolhaCarrinhoCliente$: Observable<any> = this._escolhaCarrinhoCliente.asObservable();

  public readonly sincronizar = 'sincronizar';
  public readonly possuiCarrinhoCliente = 'possuiCarrinhoCliente';

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {}

  public verificarCarrinhoAnonimo() {
    this.httpClient
      .get<any>(`${environment.apiUrl}/loja/permitir_carrinho_anonimo`)
      .subscribe(
        (response) => {
          this._permitirCarrinhoAnonimo = response.data;
          this.setCarrinhoAnonimoVerificado(!response.data);
        });
  }

  public verificarCompraExcluida(compraCancelada) {
    if (compraCancelada)
      alert(`Sua compra de número ${compraCancelada} foi cancelada. Você pode realizá-la novamente acessando o histórico de compras.`);
  }

  public verificarBebidaAlcoolica(menorIdade: boolean) {
    if (menorIdade)
      alert('Devido sua idade as bebidas alcoólicas foram removidas do carrinho');
  }

  public emitirEventoEscolhaCarrinhoCliente(valor: boolean) {
    this.localStorageService.set('carrinhoAnonimoVerificado', true);
    this._escolhaCarrinhoCliente.next(valor);
  }

  public emitirEventoSincronizarCarrinhoAnonimoCliente(valor: string) {
    this._sincronizarCarrinhoAnonimo.next(valor);
  }

  public setCarrinhoAnonimoVerificado(valor: boolean) {
    this._carrinhoAnonimoVerificado = valor;
  }

  public redirecionarHome() {
    this.router.navigate(['/']);
  }

  public mostrarMensagem(mensagem) {
    alert(`${mensagem}. Você será redirecionado para a tela inicial`);
  }
}
