import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from 'src/environments/environment';
import { Banner } from './banners';
@Injectable({ providedIn: 'root' })
export class BannerService {

    private dispositivo: string = 'S';
    public service: string = '/banners';
    public banner: Banner = <Banner>{};
    public banners: Banner[] = [];
    public show_banners: boolean = true;
    public total = 0;

    constructor(private httpClient: HttpClient, private localStorageService: LocalStorageService) {
    }

    public clearSessionStorageBanners() {
        const tipoBanners = [
            'home',
            'home-perfis',
            'categorias',
            'categorias-perfis'
        ]
        tipoBanners.forEach((tipoBanner) => {
            sessionStorage.removeItem('bn-' + tipoBanner);
        });
    }

    private setSessionStorageBanner(name, data) {
        const dataString = JSON.stringify(this.removerCaracterNaoAlfanumerico(data));
        sessionStorage.setItem('bn-' + name, btoa(dataString));
    }

    private getSessionStorageBanner(name) {
        const data = sessionStorage.getItem('bn-' + name);
        return data ? JSON.parse(atob(data)) : [];
    }

    private atualizaInfoBanner(banners) {
        this.banners = this.removerCaracterNaoAlfanumerico(banners);
        this.total = banners.length;
        window.dispatchEvent(new Event('atualizarBanners'));
    }

    home(cdId: number) {
        const hasToken = !!this.localStorageService.get('token');
        const tipoBanner = hasToken ? 'home-perfis' : 'home';
        const bannerSession = this.getSessionStorageBanner(tipoBanner);

        if (bannerSession.length > 0) {
            this.atualizaInfoBanner(bannerSession);
            return;
        }

        if (bannerSession.length > 0) {
            this.atualizaInfoBanner(bannerSession);
            return;
        }

        const URI = `loja/banner/filial/${environment.filialId}/centro_distribuicao/${cdId}/dispositivo/${this.dispositivo}`;
        this.httpClient.get(`${environment.apiUrl}/${URI}`).toPromise()
            .then((res: any) => {
                this.atualizaInfoBanner(res.data);
                this.setSessionStorageBanner(tipoBanner, res.data);
            });
    }

    private removerCaracterNaoAlfanumerico(data) {
        return data.map((banner: any) => {
            const pattern = new RegExp('[^a-zA-Z0-9 -]/g');
            banner.descricao = banner.descricao.replace(pattern, '');
            return banner;
        });
    }

    categorias(cdId, categoria) {
        const hasToken = !!this.localStorageService.get('token');
        const tipoBanner = hasToken ? 'categorias-perfis' : 'categorias';
        const sessionName = `${tipoBanner}-${categoria}`;
        const bannerSession = this.getSessionStorageBanner(sessionName);

        if (bannerSession.length > 0) {
            this.atualizaInfoBanner(bannerSession);
            return;
        }

        const URI = `loja/banner/filial/${environment.filialId}/centro_distribuicao/${cdId}/classificacao_mercadologica/${categoria}/dispositivo/${this.dispositivo}`;
        this.httpClient.get(`${environment.apiUrl}/${URI}`).toPromise()
            .then((res: any) => {
                this.atualizaInfoBanner(res.data);
                this.setSessionStorageBanner(tipoBanner, res.data);
            });
    }

    view(bannerID: number) {
        return this.httpClient.get(this.service + '/view/' + environment.filialId + '/' + bannerID).toPromise()
            .then((data: any) => {
                this.banners = data.banners;
                this.banner = data.banners[0];
                this.total = data.banners.length;
            });
    }

    public addClick(bannerId: number) {
        return this.httpClient.post(`${environment.apiUrl}/loja/banner/add`, {
            id: bannerId,
        });
    }

    public show() {
        this.show_banners = true;
    }

    public hide() {
        this.show_banners = false;
    }
}
