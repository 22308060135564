<div class="escolha-modal" role="dialog">
    <div class="modal-header">
        <p class="texto-escolha">
            Deseja realmente remover o cartão?
            <wbr>
            Lembrando que este cartão deixará de estar disponível.
        </p>
    </div>
    <div class="modal-body" *ngIf="cartao">
        <img [src]="cartao.url_imagem" class="image-cards" />
        <span style="margin: auto;">{{ cartao.numero_cartao }}</span>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-light btn-sm pull-left" (click)="fechar(false)">Cancelar </button>
        <button type="submit" class="btn btn-primary btn-sm pull-right" (click)="fechar(true)">Sim, remover </button>
    </div>
</div>
