export class FormatarRecuperacaoSenha {

  static data(data) {
    data.documento = this.replaceDocumento(data.documento);
    data.contato = this.replaceContato(data.contato);
    return data;
  }

  private static replaceDocumento(field: string) {
    return field.replace(/\D+/g, '').trim();
  }

  private static replaceContato(field: string) {

    let fieldWithoutPhoneMask: any = field.replace(/[/\s-()]/g, '').trim();

    if (!isNaN(fieldWithoutPhoneMask)) {
      return fieldWithoutPhoneMask;
    }

    return field;
  }
}
