import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { FidelidadeService } from 'src/app/shared/services/fidelidade.service';

@Component({
    styleUrls: ['./divulgacao.component.css'],
    templateUrl: './divulgacao.component.html'
})
export class DivulgacaoComponent implements OnInit {
    public texto: string;
    public campanhas: Array<any> = [];

    public constructor(
        private modalService: BsModalService,
        private fidelidadeService: FidelidadeService
    ) {}

    public ngOnInit() {}

    public close() {
        this.fidelidadeService.saveDisplayed(this.campanhas);
        this.modalService._hideModal(1);
    }
}
