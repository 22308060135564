<div class="criar-conta-modal">
  <i
    class="fa fa-close criar-conta-modal__close"
    aria-hidden="true"
    (click)="closeButton()"
  ></i>
  <header class="title">
    <h4>Quero me cadastrar</h4>
  </header>
  <section>
    <div class="subtitle">
      Antes de se cadastrar, verifique se seu endereço faz parte
       da nossa área de cobertura de serviço
    </div>
    <app-cliente-simulador-de-frete (aoCadastrar)="close()"
      >Simulador de Taxa de frete...</app-cliente-simulador-de-frete
    >
  </section>
</div>
