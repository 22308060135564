// REFACTOR GLOBAL FUNCTIONS
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import pt from '@angular/common/locales/pt';
// REFACTOR GLOBAL FUNCTIONS
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
    LocalStorageModule,
    LocalStorageService
} from 'angular-2-local-storage';
import { Angulartics2Module } from 'angulartics2';
import * as LogRocket from 'logrocket';
import { ModalModule } from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
/*
 * Platform and Environment providers/directives/pipes
 */
import { environment } from '../environments/environment';
import '../styles/headings.css';
import { AntifraudeService } from './antifraude/shared/antifraude.service';
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { routes } from './app.routes';
import { AppState } from './app.service';
import { CentroDistribuicaoModule } from './centro-distribuicao/centro-distribuicao.module';
import { CheckoutDescontosNovaTentativaComponent } from './checkout/checkout-descontos-nova-tentativa/checkout-descontos-nova-tentativa.component';
import { CheckoutPagamentoSolicitarCancelamentoComponent } from './checkout/checkout-pagamento-solicitar-cancelamento/checkout-pagamento-solicitar-cancelamento.component';
import { ConfirmarRemoverCartaoSalvoComponent } from './checkout/modals/confirmar-remover-cartao-salvo/confirmar-remover-cartao-salvo.component';
import { EscolherCarrinhoClienteComponent } from './checkout/modals/escolher-carrinho-cliente-component/escolher-carrinho-cliente.component';
import { ObservacaoProdutoComponent } from './checkout/modals/observacao-produto/observacao-produto.component';
import { ClienteEnderecoComponent } from './clientes/cliente-endereco/cliente-endereco.component';
import { ClienteService } from './clientes/shared/cliente.service';
import { CriarContaModalComponent } from './criar-conta-modal/criar-conta-modal.component';
import { CriarSenhaMarketplaceModalComponent } from './criar-senha-marketplace-modal/criar-senha-marketplace-modal.component';
import { FiliaisComponent } from './filiais/filiais.component';
import { FilialService } from './filiais/shared/filial.service';
import { InstitucionalService } from './institucional/shared/institucional.service';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { LoginCelularModalComponent } from './login-modal/login-celular-modal/login-celular-modal.component';
import { MinhaContaCashbackModalExtratoComponent } from './minha-conta/minha-conta-cashback/minha-conta-cashback-modal-extrato/minha-conta-cashback-modal-extrato.component';
import { MinhaContaCashbackService } from './minha-conta/minha-conta-cashback/shared/minha-conta-cashback.service';
import { MinhaContaPedidoSolicitarCancelamentoComponent } from './minha-conta/minha-conta-pedido-solicitar-cancelamento/minha-conta-pedido-solicitar-cancelamento.component';
import { ObservacaoProdutoModalComponent } from './minha-conta/observacao-produto-modal/observacao-produto-modal.component';
import { MinhasListasEditComponent } from './minhas-listas/modals/minhas-listas-edit/minhas-listas-edit.component';
import { MinhasListasProductComponent } from './minhas-listas/modals/minhas-listas-product/minhas-listas-product.component';
import { MinhasListasReplaceOptionsComponent } from './minhas-listas/modals/minhas-listas-replace-options/minhas-listas-replace-options.component';
import { MinhasListasReplaceComponent } from './minhas-listas/modals/minhas-listas-replace/minhas-listas-replace.component';
import { MinhasListasShareComponent } from './minhas-listas/modals/minhas-listas-share/minhas-listas-share.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ParametrosComponent } from './parametros/parametros.component';
import { PartialsModule } from './partials';
import { BannerService } from './partials/banners/shared/banner.service';
import { CarrinhoAnonimoService } from './partials/carrinho/shared/carrinho-anonimo.service';
import { CarrinhoService } from './partials/carrinho/shared/carrinho.service';
import { PaginacaoService } from './partials/paginacao/shared/paginacao.service';
import { TabsBarService } from './partials/tabs-bar/shared/tabs-bar.service';
import { TermosUsoComponent } from './partials/termos-uso/termos-uso.component';
import { CompraService } from './pedidos/shared/compra.service';
import { ProdutoBuscaService } from './produtos/produto-busca/shared/produto-busca.service';
import { ListaService } from './receitas/listas/shared/lista.service';
import { ReceitaService } from './receitas/shared/receita.service';
import { RecuperarSenhaModalComponent } from './recuperar-senha-modal/recuperar-senha-modal.component';
import { SeletorLojaComponent } from './seletor-loja/seletor-loja.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { AuthCarrinhoAnonimoGuard } from './shared/guards/authCarrinhoAnonimo.guard';
import { AplicativoService } from './shared/services/aplicativo.service';
import { ColecaoService } from './shared/services/colecao.service';
import { CombosService } from './shared/services/combos.service';
import { CompartilharListaService } from './shared/services/compartilhar-lista.service';
import { ConfiguracaoService } from './shared/services/configuracao.service';
import { DepartamentoService } from './shared/services/departamento.service';
import { FidelidadeService } from './shared/services/fidelidade.service';
import { FooterService } from './shared/services/footer.service';
import { HttpClientInterceptor } from './shared/services/http.interceptor';
import { ImageService } from './shared/services/imagem.service';
import { MatchMediaService } from './shared/services/match-media.service';
import { MinhasListasService } from './shared/services/minhas-listas.service';
import { StartupService } from './shared/services/startup.service';
import { SharedModule } from './shared/shared.module';
import { DivulgacaoComponent } from './sorteios/modal/divulgacao.component';
import { UsuarioService } from './televendas/shared/usuario.service';
import { PrevisaoEntregaComponent } from './tipo-entregas/previsao-entrega-modal/previsao-entrega.component';
import { TipoEntregaService } from './tipo-entregas/shared/tipo-entrega.service';
import { TipoEntregasComponent } from './tipo-entregas/tipo-entregas.component';
import { PinGuard } from './shared/guards/pin.guard';
import { TagsBuscaService } from './partials/tags-busca/shared/tags-busca.service';
import { ProdutoTagService } from './produtos/produto-tag/shared/produto-tag.service'

export function serialize(query) {
    const qs = [];
    for (const key in query) {
        // skip loop if the property is from prototype
        if (!query.hasOwnProperty(key)) {
            continue;
        }

        const obj = query[key];
        if (obj instanceof Array) {
            for (const prop in obj) {
                qs.push(key + '[]=' + obj[prop]);
            }
        } else {
            qs.push(key + '=' + (obj == undefined ? null : obj));
        }
    }

    return qs.join('&');
}

if (environment.logRocket) {
    LogRocket.init('933jpi/store');
}
registerLocaleData(pt);
// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    {
        provide: APP_INITIALIZER,
        useFactory: startupProviderFactory,
        deps: [StartupService],
        multi: true,
    },
    AppState,
    CarrinhoService,
    CompraService,
    ClienteService,
    TabsBarService,
    BannerService,
    InstitucionalService,
    DepartamentoService,
    ColecaoService,
    ReceitaService,
    ListaService,
    ProdutoBuscaService,
    FilialService,
    TipoEntregaService,
    Title,
    PaginacaoService,
    CombosService,
    CookieService,
    LocalStorageService,
    CompartilharListaService,
    MinhasListasService,
    ConfiguracaoService,
    UsuarioService,
    FidelidadeService,
    MatchMediaService,
    ImageService,
    AplicativoService,
    TipoEntregaService,
    AntifraudeService,
    AuthGuard,
    PinGuard,
    MinhaContaCashbackService,
    FooterService,
    AuthCarrinhoAnonimoGuard,
    CarrinhoAnonimoService,
    TagsBuscaService,
    ProdutoTagService,
    {
        provide: 'LOCAL_STORAGE_SERVICE_CONFIG',
        useValue: { storageType: 'localStorage' },
    },
    {
        provide: 'LOCAL_STORAGE_SERVICE_CONFIG',
        useValue: { storageType: 'localStorage' },
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpClientInterceptor, multi: true },
    { provide: 'Window', useValue: window },
    { provide: LOCALE_ID, useValue: 'pt' },
];

export function startupProviderFactory(provider: StartupService) {
    return () => provider.load();
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        FiliaisComponent,
        TipoEntregasComponent,
        ClienteEnderecoComponent,
        ParametrosComponent,
        NotFoundComponent,
        SeletorLojaComponent,
        MinhasListasEditComponent,
        MinhasListasShareComponent,
        MinhasListasProductComponent,
        MinhasListasReplaceComponent,
        MinhasListasReplaceOptionsComponent,
        MinhaContaPedidoSolicitarCancelamentoComponent,
        CheckoutPagamentoSolicitarCancelamentoComponent,
        CheckoutDescontosNovaTentativaComponent,
        LoginModalComponent,
        LoginCelularModalComponent,
        CriarContaModalComponent,
        CriarSenhaMarketplaceModalComponent,
        RecuperarSenhaModalComponent,
        DivulgacaoComponent,
        PrevisaoEntregaComponent,
        MinhaContaCashbackModalExtratoComponent,
        ObservacaoProdutoComponent,
        ObservacaoProdutoModalComponent,
        EscolherCarrinhoClienteComponent,
        ConfirmarRemoverCartaoSalvoComponent,
    ],
    entryComponents: [
        SeletorLojaComponent,
        MinhasListasEditComponent,
        MinhasListasShareComponent,
        MinhasListasProductComponent,
        MinhasListasReplaceComponent,
        MinhasListasReplaceOptionsComponent,
        LoginModalComponent,
        LoginCelularModalComponent,
        CriarContaModalComponent,
        CriarSenhaMarketplaceModalComponent,
        RecuperarSenhaModalComponent,
        MinhaContaPedidoSolicitarCancelamentoComponent,
        CheckoutPagamentoSolicitarCancelamentoComponent,
        CheckoutDescontosNovaTentativaComponent,
        DivulgacaoComponent,
        PrevisaoEntregaComponent,
        TermosUsoComponent,
        MinhaContaCashbackModalExtratoComponent,
        ObservacaoProdutoComponent,
        ObservacaoProdutoModalComponent,
        EscolherCarrinhoClienteComponent,
        ConfirmarRemoverCartaoSalvoComponent
    ],
    /**
     * Import Angular's modules.
     */
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ModalModule.forRoot(),
        QuicklinkModule,
        RouterModule.forRoot(routes, {
            useHash: Boolean(history.pushState) === false,
            preloadingStrategy: QuicklinkStrategy,
            scrollPositionRestoration: 'top',
        }),
        SharedModule,
        PartialsModule,
        LocalStorageModule.forRoot({
            prefix: 'vp',
            storageType: 'localStorage',
        }),
        BrowserAnimationsModule,
        Angulartics2Module.forRoot(),
        CentroDistribuicaoModule,
        ServiceWorkerModule.register('service-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
        }),
    ],
    /**
     * Expose our Services and Providers into Angular's dependency injection.
     */
    providers: [environment.ENV_PROVIDERS, APP_PROVIDERS],
})
export class AppModule { }
