import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-ceps',
  templateUrl: './ceps.component.html',
  styleUrls: ['./ceps.component.css']
})
export class CepsComponent implements OnInit {

  @Input() customLabel: boolean = false;
  @Input() validation: boolean = false;

  cep: string = null;
  @Output() cepChange: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  private formatCep(cep) {
    return cep ? cep.split('-').join('') : '';
  }

  change() {
    this.cepChange.emit(this.formatCep(this.cep));
  }

}
