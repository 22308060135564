import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DepartamentoService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.departamentos = [];
        this._atualizaDepartamentos$ = new Subject();
        this.atualizaDepartamentos$ = this._atualizaDepartamentos$.asObservable();
        this.favoritos = [];
        this._atualizaFavoritos$ = new Subject();
        this.atualizaFavoritos$ = this._atualizaFavoritos$.asObservable();
        this.service = '/departamentos';
        this.showMenu$ = new BehaviorSubject(true);
        this.detail = {};
    }
    listagem(cdId) {
        const URI = `classificacoes_mercadologicas/departamentos/arvore/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
        this.httpClient
            .get(`${environment.apiUrl}/loja/${URI}`)
            .subscribe((req) => {
            this.departamentos = req.data;
            this._atualizaDepartamentos$.next(req.data);
        });
    }
    buscaFavoritos(cdId) {
        const URI = `classificacoes_mercadologicas/departamentos/arvore/favoritos/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
        this.httpClient
            .get(`${environment.apiUrl}/loja/${URI}`)
            .subscribe((req) => {
            this.favoritos = req.data;
            this._atualizaFavoritos$.next(req.data);
        });
    }
    detalhe(slug) {
        return this.httpClient
            .get(this.service + '/detalhe/' + environment.filialId + '/' + slug)
            .toPromise()
            .then((data) => {
            this.detail = data;
        });
    }
    findDepartamentoBySlug(slug) {
        return this.departamentos.find(dp => {
            return dp.link === `/${slug}`;
        });
    }
    findDepartamentoById(id) {
        return this.departamentos.find(dp => {
            return Number(dp.classificacao_mercadologica_id) === Number(id);
        });
    }
    getDepartamentoIdBySlug(slug) {
        const departamento = this.findDepartamentoBySlug(slug);
        return departamento ? departamento.classificacao_mercadologica_id : 0;
    }
    findDepartamentoBySecaoId(id) {
        return this.departamentos.find(dp => {
            return dp.children.some(sc => {
                return sc.classificacao_mercadologica_id === id;
            });
        });
    }
    findSecaoBySecaoId(departamento, id) {
        if (!departamento || !id) {
            return '';
        }
        return departamento.children.find(item => {
            return item.classificacao_mercadologica_id === id;
        });
    }
    findSecaoBySlug(departamento, slugSecao) {
        if (!departamento || !slugSecao) {
            return '';
        }
        return departamento.children.find(item => {
            return item.link === `${departamento.link}/${slugSecao}`;
        });
    }
    getSecaoIdBySlug(slugDepartamento, slugSecao) {
        const departamento = this.findDepartamentoBySlug(slugDepartamento);
        if (!departamento) {
            return 0;
        }
        const secao = this.findSecaoBySlug(departamento, slugSecao);
        return secao ? secao.classificacao_mercadologica_id : 0;
    }
    limparFavoritos() {
        this.favoritos = [];
        this._atualizaFavoritos$.next(this.favoritos);
    }
    mostrarMenu() {
        this.showMenu$.next(true);
    }
    ocultarMenu() {
        this.showMenu$.next(false);
    }
}
DepartamentoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DepartamentoService_Factory() { return new DepartamentoService(i0.ɵɵinject(i1.HttpClient)); }, token: DepartamentoService, providedIn: "root" });
