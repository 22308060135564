import { Directive, HostListener, Input } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

@Directive({
  selector: '[appMetrics]'
})
export class MetricsDirective {
  @Input() appMtActionClick: string;
  @Input() appMtLabel: string;

  constructor(private angulartics2: Angulartics2) {}

  @HostListener('click', ['$event.target']) onClick(el) {
    const event = this.getEvent(el);

    if (event) {
      this.angulartics2.eventTrack.next(event);
    }
  }

  private getEvent(el) {
    if (!this.appMtActionClick) {
      return null;
    }

    const label = this.appMtLabel ? this.appMtLabel : el.innerText;
    return {
      action: `Clique em ${this.appMtActionClick}`,
      properties: {
        category: 'UX',
        label
      }
    };
  }
}
