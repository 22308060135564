import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';
import { CarrinhoService } from 'src/app/partials/carrinho/shared/carrinho.service';
import { TipoProdutoCarrinho } from 'src/app/partials/carrinho/shared/tipo-produto-carrinho';
import { Produto } from 'src/app/produtos/shared/produto';
import { MinhasListasReplaceOptionsComponent } from 'src/app/minhas-listas/modals/minhas-listas-replace-options/minhas-listas-replace-options.component';

@Component({
  selector: 'app-produtos-indisponiveis-lista',
  templateUrl: './produtos-indisponiveis-lista.component.html',
  styleUrls: ['./produtos-indisponiveis-lista.component.css']
})
export class ProdutosIndisponiveisListaComponent implements OnInit, OnDestroy {
  @Input() public products: Array<{ infoProduto: any; novoProduto: Produto }> = [];
  @Input() public modalLevel: number = 0;
  @Output() public afterReplace: EventEmitter<any> = new EventEmitter();

  public title!: string;
  public legend!: string;
  public index: number;
  public loading: boolean = false;

  private afterAddCarrinho$?: Subscription;

  public constructor(
    private modalService: BsModalService,
    private carrinhoService: CarrinhoService,
    private cdService: CentroDistribuicaoService,
  ) {
    this.title = 'Substituir Produtos';
    this.legend = this.carrinhoService.msgSubstituirProdutos;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.afterAddCarrinho$) {
      this.afterAddCarrinho$.unsubscribe();
    }
  }

  public undo(index: number) {
    this.products[index].novoProduto = <Produto>{};
  }

  public buyProduct(product: { infoProduto: any; novoProduto: Produto }) {
    product.novoProduto = { ...product.infoProduto.mercadoria };
    product.novoProduto.quantidade = 1;
  }

  private getMaxQuantity(product) {
    return this.carrinhoService.calcQtMaxAdd(
      product.quantidade_maxima,
      product.em_oferta,
      product.em_oferta ? product.oferta.quantidade_maxima : null
    );
  }

  private selectProduct(product) {
    if (product) {
      product.quantidade = this.products[this.index].infoProduto.quantidade;

      const maxQt = this.getMaxQuantity(product);

      if (product.quantidade > maxQt) {
        product.quantidade = maxQt;
      }

      this.products[this.index].novoProduto = product;
    }
  }

  public openProductOptionsModal(
    product: { infoProduto: any; novoProduto: Produto },
    index: number
  ) {
    let modalRef = this.modalService.show(MinhasListasReplaceOptionsComponent, {
      keyboard: true,
      class: 'custom-modal modal-replace-options',
      ignoreBackdropClick: false
    });
    this.index = index;
    modalRef.content.modalLevel = this.modalLevel + 1;
    modalRef.content.cdId = this.cdService.id;
    modalRef.content.produtoId = product.infoProduto.mercadoria.produto_id;
    modalRef.content.onHide = prod => this.selectProduct(prod);
  }

  public up(product: Produto) {
    const qtMax = this.getMaxQuantity(product);

    if (product.quantidade >= qtMax) {
      product.quantidade = qtMax;
      return this.carrinhoService.showMsgErrorQtMax()
    }

    product.quantidade += 1;

  }

  public down(product: Produto) {
    if (product.quantidade > 1) {
      product.quantidade -= 1;
    }
  }

  public edit(product: Produto) {
    if (product.quantidade < 1) {
      product.quantidade = 1;
    }
  }

  public showButtonByProduct(product) {
    const info = product && product.infoProduto;
    const mercadoria = info && product.infoProduto.mercadoria;
    return mercadoria && mercadoria.disponivel && info.quantidade > mercadoria.quantidade_maxima;
  }

  private buscaProdutosSubstituidos() {
    return this.products.filter((obj: any) => obj.novoProduto && obj.novoProduto.produto_id);
  }

  private formatItensToSave(
    produtos
  ): Array<{
    mercadoria_id: number;
    quantidade: number;
    tipo: TipoProdutoCarrinho;
  }> {
    return produtos.map(item => {
      return {
        mercadoria_id: item.novoProduto.produto_id,
        quantidade: item.novoProduto.quantidade,
        tipo: 1
      };
    });
  }

  public saveReplace() {
    const produtos = this.buscaProdutosSubstituidos();

    if (produtos.length === 0) {
      return alert('Substitua pelo menos um produto antes de finalizar');
    }

    this.loading = true;

    const toSave = this.formatItensToSave(produtos);

    if (!this.afterAddCarrinho$) {
      this.afterAddCarrinho$ = this.carrinhoService.afterAddCarrinho$.subscribe((response) => {
        alert('Produtos adicionados ao carrinho.');
        this.loading = false;
        this.afterReplace.emit(response);
      });
    }
    this.carrinhoService.addMultiplosItens(this.cdService.id, toSave)
    .catch((err)=>{
      alert(err);
    });
  }
}
