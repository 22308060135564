import { Component, Inject, OnInit } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { EventEmitterService } from 'src/app/shared/services/event-emiter.service';

@Component({
  selector: 'app-limite-cd',
  templateUrl: './limite-cd.component.html',
  styleUrls: ['./limite-cd.component.less'],
})

export class LimiteCdComponent implements OnInit {

  public limite_carrinho: any;

  constructor(
    private localStorageService: LocalStorageService,
    @Inject('Window') private w: Window
  ){}

  ngOnInit() {
    this.limite_carrinho = JSON.parse(
      this.localStorageService.get('limite_carrinho')
    );
    this.localStorageService.remove('limite_carrinho');
  }

  limparCarrinho(){
    EventEmitterService.get('limpar_carrinho').emit(this.limite_carrinho.cdAtual);
  }

  voltarLoja() {
    EventEmitterService.get('voltar_loja').emit(this.limite_carrinho.cdAtual);
  }
}