import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Oferta } from 'src/app/produtos/shared/ofertas';

@Component({
  selector: 'app-tag-promotion',
  templateUrl: './tag-promotion.component.html',
  styleUrls: ['./tag-promotion.component.less']
})
export class TagPromotionComponent {

  @Input() oferta: Oferta = {} as Oferta
  @Output() clickAdsAction = new EventEmitter();

  constructor() { }

  onClick() {
    this.clickAdsAction.emit();
  }
}
