import { Injectable } from '@angular/core';
import { IFiltrosOptions } from './tags-busca';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TagsBuscaService {
    private tags: string[] = [];
    public tags$: BehaviorSubject<string[]> = new BehaviorSubject(this.tags);

    setTags(tag: IFiltrosOptions) {
        if (this.tags.includes(tag.value)) {
            const index = this.tags.indexOf(tag.value);
            this.tags.splice(index, 1);
        } else {
            this.tags.push(tag.value);
        }
        this.tags$.next(this.tags);
    }

    clearTags() {
        this.tags = [];
        this.tags$.next(this.tags);
    }
}
