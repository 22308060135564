import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class FidelidadeService {
  private _afterSaveToken$: Subject<string> = new Subject();
  public afterSaveToken$: Observable<any> = this._afterSaveToken$.asObservable();

  private _haCampanhaFidelidade: boolean = false;
  public get haCampanhaFidelidade(): boolean {
    return this._haCampanhaFidelidade;
  }

  private _params: any = {};

  constructor(
    private http: HttpClient,
    private lsService: LocalStorageService
  ) {}

  public getParams() {
    return this._params;
  }

  public setParams(params) {
    this._params = params;
  }

  public vorticeParams() {
    return this._params.vortice || {};
  }

  public cashbackParams() {
    return this._params.cashback || {};
  }

  public getToken() {
    return this.lsService.get('token-fidelidade');
  }

  public hasToken() {
    return !!this.lsService.get('token-fidelidade');
  }

  public saveDisplayed(campanhas: Array<any>) {
    const ids = campanhas.map((item) => {
      return item.idCampanha;
    });
    this.lsService.set('fidelidade-campanha', ids);
  }

  public hasNewCampaigns(campaignsId) {
    if (!this._haCampanhaFidelidade) {
      return false;
    }

    const displayedIds: Array<any> = this.lsService.get('fidelidade-campanha');

    if (!displayedIds) {
      return true;
    }

    const hasNew = campaignsId.some((campanha) => {
      return !displayedIds.some((id) => {
        return id === campanha.idCampanha;
      });
    });

    return hasNew;
  }

  private getQueryString() {
    const token = this.getToken();
    if (!token) {
      return '';
    }

    return `?auth=${token}`;
  }

  public removeToken() {
    this.lsService.remove('token-fidelidade');
  }

  public findAndSaveToken() {
    this.getAccessToken().subscribe((res: any) => {
      this.saveToken(res.token);
    });
  }

  public saveToken(token) {
    this.lsService.set('token-fidelidade', token);
    this._afterSaveToken$.next(token);
  }

  public getSignUpTerms() {
    return this.http.get(`/fidelidade/termosCadastro`);
  }

  public getAccessToken() {
    return this.http.get(`/fidelidade/token`);
  }

  public getCampaigns() {
    return this.http.get(`/fidelidade/campanhas${this.getQueryString()}`).pipe(
      map((res: any) => {
        this._haCampanhaFidelidade = res.data.length > 0;
        res.data = res.data.sort((item) => {
          return item.partAuto || item.participando ? 1 : -1;
        });

        return res;
      })
    );
  }

  public getCampaignInfo(id) {
    return this.http.get(`/fidelidade/campanha/${id}${this.getQueryString()}`);
  }

  public getCampaignRegulation(id) {
    return this.http.get(
      `/fidelidade/regulamento/${id}${this.getQueryString()}`
    );
  }

  public getCampaignCoupons(id) {
    return this.http.get(`/fidelidade/cupons/${id}${this.getQueryString()}`);
  }

  public joinCampaign(body) {
    return this.http.post(
      `/fidelidade/participar${this.getQueryString()}`,
      body
    );
  }

  public buscarConfiguracao() {
    if (Object.keys(this._params).length === 0) {
      return this.http
        .get(`${environment.apiUrl}/loja/fidelidade/configuracao`)
        .toPromise()
        .then((response: any) => {
          this._params = response.data;
        });
    } else {
      return Promise.resolve(this._params);
    }
  }
}
