import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular-2-local-storage";
export class BannerService {
    constructor(httpClient, localStorageService) {
        this.httpClient = httpClient;
        this.localStorageService = localStorageService;
        this.dispositivo = 'S';
        this.service = '/banners';
        this.banner = {};
        this.banners = [];
        this.show_banners = true;
        this.total = 0;
    }
    clearSessionStorageBanners() {
        const tipoBanners = [
            'home',
            'home-perfis',
            'categorias',
            'categorias-perfis'
        ];
        tipoBanners.forEach((tipoBanner) => {
            sessionStorage.removeItem('bn-' + tipoBanner);
        });
    }
    setSessionStorageBanner(name, data) {
        const dataString = JSON.stringify(this.removerCaracterNaoAlfanumerico(data));
        sessionStorage.setItem('bn-' + name, btoa(dataString));
    }
    getSessionStorageBanner(name) {
        const data = sessionStorage.getItem('bn-' + name);
        return data ? JSON.parse(atob(data)) : [];
    }
    atualizaInfoBanner(banners) {
        this.banners = this.removerCaracterNaoAlfanumerico(banners);
        this.total = banners.length;
        window.dispatchEvent(new Event('atualizarBanners'));
    }
    home(cdId) {
        const hasToken = !!this.localStorageService.get('token');
        const tipoBanner = hasToken ? 'home-perfis' : 'home';
        const bannerSession = this.getSessionStorageBanner(tipoBanner);
        if (bannerSession.length > 0) {
            this.atualizaInfoBanner(bannerSession);
            return;
        }
        if (bannerSession.length > 0) {
            this.atualizaInfoBanner(bannerSession);
            return;
        }
        const URI = `loja/banner/filial/${environment.filialId}/centro_distribuicao/${cdId}/dispositivo/${this.dispositivo}`;
        this.httpClient.get(`${environment.apiUrl}/${URI}`).toPromise()
            .then((res) => {
            this.atualizaInfoBanner(res.data);
            this.setSessionStorageBanner(tipoBanner, res.data);
        });
    }
    removerCaracterNaoAlfanumerico(data) {
        return data.map((banner) => {
            const pattern = new RegExp('[^a-zA-Z0-9 -]/g');
            banner.descricao = banner.descricao.replace(pattern, '');
            return banner;
        });
    }
    categorias(cdId, categoria) {
        const hasToken = !!this.localStorageService.get('token');
        const tipoBanner = hasToken ? 'categorias-perfis' : 'categorias';
        const sessionName = `${tipoBanner}-${categoria}`;
        const bannerSession = this.getSessionStorageBanner(sessionName);
        if (bannerSession.length > 0) {
            this.atualizaInfoBanner(bannerSession);
            return;
        }
        const URI = `loja/banner/filial/${environment.filialId}/centro_distribuicao/${cdId}/classificacao_mercadologica/${categoria}/dispositivo/${this.dispositivo}`;
        this.httpClient.get(`${environment.apiUrl}/${URI}`).toPromise()
            .then((res) => {
            this.atualizaInfoBanner(res.data);
            this.setSessionStorageBanner(tipoBanner, res.data);
        });
    }
    view(bannerID) {
        return this.httpClient.get(this.service + '/view/' + environment.filialId + '/' + bannerID).toPromise()
            .then((data) => {
            this.banners = data.banners;
            this.banner = data.banners[0];
            this.total = data.banners.length;
        });
    }
    addClick(bannerId) {
        return this.httpClient.post(`${environment.apiUrl}/loja/banner/add`, {
            id: bannerId,
        });
    }
    show() {
        this.show_banners = true;
    }
    hide() {
        this.show_banners = false;
    }
}
BannerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BannerService_Factory() { return new BannerService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService)); }, token: BannerService, providedIn: "root" });
