/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "ngx-quicklink";
import * as i5 from "./not-found.component";
import * as i6 from "../partials/banners/shared/banner.service";
import * as i7 from "../filiais/shared/filial.service";
import * as i8 from "../partials/tabs-bar/shared/tabs-bar.service";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 17, "div", [["class", "error-template"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Oops! "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 404 P\u00E1gina n\u00E3o encontrada "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "error-details text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Esta p\u00E1gina n\u00E3o existe ou n\u00E3o est\u00E1 dispon\u00EDvel. "])), (_l()(), i1.ɵeld(10, 0, null, null, 10, "div", [["class", "error-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "a", [["class", "btn btn-primary btn-lg"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(13, 671744, null, 0, i4.ɵɵLinkDirective, [i4.ɵa, i1.ElementRef, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-home"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Voltar ao site "])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "a", [["class", "btn btn-default btn-lg"], ["routerLink", "/institucional/paginas/fale-conosco"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(18, 671744, null, 0, i4.ɵɵLinkDirective, [i4.ɵa, i1.ElementRef, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-envelope"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Fale conosco "]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 12, 0, currVal_2); var currVal_3 = "/"; _ck(_v, 13, 0, currVal_3); var currVal_6 = "/institucional/paginas/fale-conosco"; _ck(_v, 17, 0, currVal_6); var currVal_7 = "/institucional/paginas/fale-conosco"; _ck(_v, 18, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).target; var currVal_1 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 17).target; var currVal_5 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_4, currVal_5); }); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-found-page", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i5.NotFoundComponent, [i6.BannerService, i7.FilialService, i8.TabsBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i1.ɵccf("app-not-found-page", i5.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
