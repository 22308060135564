import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProdutosPatrocinadosService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.chamadaRealizada = false;
        this.adsAtivo$ = new BehaviorSubject(false);
        this.request = this.httpClient.get(`${environment.apiUrl}/ads/ativo`);
        if (!this.chamadaRealizada) {
            this.chamadaRealizada = true;
            this.request.subscribe((response) => {
                if (response.success) {
                    this.adsAtivo$.next(response.data.ativo);
                }
            });
        }
    }
}
ProdutosPatrocinadosService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProdutosPatrocinadosService_Factory() { return new ProdutosPatrocinadosService(i0.ɵɵinject(i1.HttpClient)); }, token: ProdutosPatrocinadosService, providedIn: "root" });
