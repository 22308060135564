import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private localStorageService: LocalStorageService,
        private router: Router,
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isLoggedIn = !(this.localStorageService.get('token') == null);
        if (isLoggedIn) {
            return true;
        } else {
            this.router.navigate(['/clientes/login'], { queryParams: { redirectUrl: state.url} });
        }
    }
}
