import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-minha-conta-cashback-tipo-operacao',
  templateUrl: './minha-conta-cashback-tipo-operacao.component.html',
  styleUrls: ['./minha-conta-cashback-tipo-operacao.component.css'],
})
export class MinhaContaCashbackTipoOperacaoComponent {
  @Input() tipoOperacao: number;
  @Input() label = true;

  constructor() {}
}
