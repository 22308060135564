<div class="login-modal">
  <i class="fa fa-close login-modal__close" aria-hidden="true" (click)="closeButton()"></i>
  <header class="title">
    <h4>Bem-vindo(a)!</h4>
  </header>
  <section>
    <form class="login-modal__form" (ngSubmit)="login()" #loginForm="ngForm">
      <div class="form-group">
        <label class="control-label">E-mail, CPF ou CNPJ</label>
        <input id="usuario" name="usuario" type="email" class="form-control" [(ngModel)]="user.usuario" required #usuario="ngModel" (change)="onChangeLogin()" (keyup.enter)="env.showCaptcha ? validaLogin() : add()"/>
        <span *ngIf="usuario.invalid && (usuario.dirty || usuario.touched)" class="text-danger error">
          <span *ngIf="usuario.errors?.required">Campo obrigatório</span>
        </span>
      </div>
      <div class="form-group">
        <label class="control-label">Senha</label>
        <input id="password" name="password" class="form-control" type="password" [(ngModel)]="user.password" required #password="ngModel" (keyup.enter)="env.showCaptcha ? validaLogin() : add()"/>
        <span *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger error">
          <span *ngIf="password.errors?.required">Digite uma senha</span>
        </span>
      </div>
      <re-captcha [style.display]="env.showCaptcha ? 'flex' : 'none'" #captchaRef="reCaptcha" (resolved)="$event && add($event)" size="invisible"></re-captcha>

      <input type="hidden" value="login-cliente" name="validacaoBot" [(ngModel)]="validacaoBot" />
      <button type="button" (click)="env.showCaptcha ? validaLogin() : add()" [disabled]="bloquearBotaoLogin" class="btn btn-get-in" [disabled]="!loginForm.form.valid || loading">
        <span *ngIf="!loading">Entrar</span>
        <span *ngIf="loading"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Aguarde</span>
      </button>
      <a href="javascript:void(0)" class="text-primary login-modal__link-senha" (click)="openForgotPasswordModal()"> Esqueci minha senha </a>
      <div *ngIf="pinAtivo" class="login-modal__separator">
        <div class="login-modal__separator-line"></div>
        <div class="login-modal__separator-text">Ou login com</div>
        <div class="login-modal__separator-line"></div>
      </div>
      <button *ngIf="pinAtivo" type="button" class="btn btn-register login-modal__phone-login" (click)="openLoginMobileModal()">
        <i class="fa fa-mobile" aria-hidden="true"></i>
        <span> Número de Celular</span>
      </button>
      <div class="login-modal__separator">
        <div class="login-modal__separator-line"></div>
        <div class="login-modal__separator-text">Ainda não tem uma conta?</div>
        <div class="login-modal__separator-line"></div>
      </div>
      <button type="button" class="btn btn-register login-modal__create-account" (click)="openCreateAccountModal()">
        <i class="fa fa-user-plus" aria-hidden="true"></i>
        <span> Criar uma conta</span>
      </button>
    </form>
  </section>
</div>
