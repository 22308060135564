/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./centro-distribuicao.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./centro-distribuicao.component";
import * as i3 from "@angular/router";
import * as i4 from "./shared/centro-distribuicao.service";
import * as i5 from "../partials/carrinho/shared/carrinho.service";
import * as i6 from "../clientes/shared/cliente.service";
var styles_CentroDistribuicaoComponent = [i0.styles];
var RenderType_CentroDistribuicaoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CentroDistribuicaoComponent, data: {} });
export { RenderType_CentroDistribuicaoComponent as RenderType_CentroDistribuicaoComponent };
export function View_CentroDistribuicaoComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_CentroDistribuicaoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-centro-distribuicao", [], null, null, null, View_CentroDistribuicaoComponent_0, RenderType_CentroDistribuicaoComponent)), i1.ɵdid(1, 245760, null, 0, i2.CentroDistribuicaoComponent, [i3.ActivatedRoute, i3.Router, i4.CentroDistribuicaoService, i5.CarrinhoService, i6.ClienteService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CentroDistribuicaoComponentNgFactory = i1.ɵccf("app-centro-distribuicao", i2.CentroDistribuicaoComponent, View_CentroDistribuicaoComponent_Host_0, {}, {}, []);
export { CentroDistribuicaoComponentNgFactory as CentroDistribuicaoComponentNgFactory };
