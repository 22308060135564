import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { BsModalService } from 'ngx-bootstrap';
import { CentroDistribuicaoService } from 'src/app/centro-distribuicao/shared/centro-distribuicao.service';
import { ClienteService } from 'src/app/clientes/shared/cliente.service';
import { Filial } from 'src/app/filiais/shared/filiais';
import { FilialService } from 'src/app/filiais/shared/filial.service';
import { LoginModalComponent } from 'src/app/login-modal/login-modal.component';
import { ProdutoOfertaTagsService } from 'src/app/produtos/produto-oferta-tags/shared/produto-oferta-tags.service';
import { ProdutoBuscaService } from '../../produtos/produto-busca/shared/produto-busca.service';
import { UsuarioService } from './../../televendas/shared/usuario.service';
import { Subscription } from 'rxjs';
import { ColecaoService } from 'src/app/shared/services/colecao.service';
import { ReceitaService } from 'src/app/receitas/shared/receita.service';
import { ViewService } from 'src/app/shared/services/view.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [ProdutoOfertaTagsService]
})
export class HeaderComponent implements OnInit, OnDestroy {
  public q: string = '';
  public placeholder: string;
  public televendas: any;
  public logo: string;
  public cliente: any;
  public filial: Filial = <Filial>{};
  public popover = {
    show: false,
    timeout: null
  };
  public haOfertasExclusivas: boolean = false;
  private logoPrefix: string = '/';
  private clientLogo: string = 'assets/img/header-logo.png';
  private televendasLogo: string = 'assets/img/logo-televendas.png';
  private quickSearchTimeout: any;

  private alterarLoja$: Subscription;
  private afterLogin$: Subscription;

  constructor(
    private produtoBuscaService: ProdutoBuscaService,
    private router: Router,
    private usuarioService: UsuarioService,
    private modalService: BsModalService,
    private clienteService: ClienteService,
    private filialService: FilialService,
    private produtoOfertaTagsService: ProdutoOfertaTagsService,
    public cdService: CentroDistribuicaoService,
    private colecaoService: ColecaoService,
    private receitaService: ReceitaService,
    private viewService: ViewService,
  ) {
    this.setPlaceHolder();
    this.televendas = !!this.usuarioService.getUser();
    this.logo =
      this.logoPrefix +
      (this.televendas ? this.televendasLogo : this.clientLogo);
  }

  public atualizarBuscas = () => {
    if (this.produtoBuscaService.termo == undefined) {
      this.q = '';
    } else {
      this.q = this.produtoBuscaService.termo.replace(/\+/g, ' ');
    }
  };

  private buscaOfertasExclusivas() {
    if (!this.clienteService.logged()) {
      return;
    }
    const produtoOfertaTagService$ = this.produtoOfertaTagsService
      .ofertaExclusiva(this.cdService.cdSelecionado.id)
      .subscribe(req => {
        this.haOfertasExclusivas = req.data.length > 0;
        produtoOfertaTagService$.unsubscribe();
      });
  }

  public atualizaTelevendas(e) {
    this.televendas = e.detail;
    this.logo =
      this.logoPrefix +
      (this.televendas ? this.televendasLogo : this.clientLogo);
  }

  private afterLogin() {
    this.afterLogin$ = this.clienteService.afterLogin$.subscribe(() => {
      this.buscaOfertasExclusivas();
    });
  }

  public ngOnInit() {
    this.alterarLoja();
    this.afterLogin();

    const interval = setInterval(() => {
      this.filial = this.filialService.detail;
      if (this.filial && this.filial.id) {
        clearInterval(interval);
      }
    }, 300);

    this.colecaoService.home();
    this.receitaService.index();
    this.buscaOfertasExclusivas();

    window.addEventListener('atualizarTelevendas', e =>
      this.atualizaTelevendas(e)
    );
    window.addEventListener('atualizarBuscas', this.atualizarBuscas);
  }

  public ngOnDestroy() {
    this.alterarLoja$.unsubscribe();
    this.afterLogin$.unsubscribe();

    window.removeEventListener('atualizarTelevendas', e =>
      this.atualizaTelevendas(e)
    );
    window.removeEventListener('atualizarBuscas', this.atualizarBuscas);
  }

  private alterarLoja() {
    this.alterarLoja$ = this.cdService.selecionarLoja$.subscribe(info => {
      if (info.lojaAlterada) {
        this.buscaOfertasExclusivas();
      }
    });
  }

  public quickSearch() {
    if (this.q.length > 3) {
      clearTimeout(this.quickSearchTimeout);
      this.quickSearchTimeout = setTimeout(() => {
        this.produtoBuscaService.pushSource(this.q);
        this.showQuickSearch();
      }, 500);
    }
  }

  public realizarBusca() {
    if (this.q !== '') {
      clearTimeout(this.quickSearchTimeout);
      this.produtoBuscaService.visibleSearch = false;
      this.router.navigate(['produtos/buscas'], {
        queryParams: {
          q: this.q.replace(/ /g, '+')
        }
      });
    }
  }

  public showQuickSearch() {
    if (this.q.length > 0) {
      this.produtoBuscaService.visibleSearch = true;
    }
  }

  private setPlaceHolder() {
    this.placeholder = this.viewService.getSearchPlaceholder();
  }

  public isLogged() {
    return this.clienteService.logged();
  }

  public abrirSelecionarLoja() {
    this.cdService.abrirSelecionarLoja();
  }

  public openLoginModal() {
    this.modalService.show(LoginModalComponent, {
      keyboard: true,
      class: 'custom-modal w280',
      ignoreBackdropClick: false
    });
  }

  public showHideMenuUsuario(event) {
    this.popover.show = true;

    if (event.type === 'mouseleave') {
      this.popover.timeout = setTimeout(() => {
        this.popover.show = false;
      }, 300);
    } else if (this.popover.timeout) {
      clearTimeout(this.popover.timeout);
    }
  }

  public logout() {
    this.clienteService.logout().then(() => {
      this.haOfertasExclusivas = false;
      window.dispatchEvent(new Event('atualizarProdutosHome'));

      this.router.navigate(['/']);
    });
    this.popover.show = false;
  }

  public atendimento() {
    this.popover.show = false;
    this.filialService.view(environment.filialId).then(data => {
      if (this.filial.widget_chat !== '') {
        window.open(
          this.filial.widget_chat,
          'chat',
          'width=400px, height=550px, top=200px, left=300px'
        );
      }
    });
  }
}
