<div class="modal-header">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="tentativas > limiteTentativas ? atualizarPedido() : removerDesconto(compra)">
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title text-info" id="removerDescontoLabel">
        Erro de comunicação!
    </h4>
</div>
<form id="formRemoverDesconto" (submit)="removerDesconto(compra)">
    <div class="modal-body" style="min-height: 100px;">
        <span class="text-muted">{{ mensagem }}</span>
    </div>
    <div class="modal-footer" *ngIf="tentativas <= limiteTentativas">
        <button type="button" class="btn btn-danger pull-left" id="closeModalButton" (click)="atualizarPedido()">
            Finalizar sem desconto
        </button>
        <button type="submit" class="btn btn-primary">
            Tentar novamente
        </button>
    </div>

    <div class="modal-footer" *ngIf="tentativas > limiteTentativas">
        <button type="button" class="btn btn-danger" id="closeModalButton" (click)="atualizarPedido()">
            Finalizar sem desconto
        </button>
    </div>
</form>
  