import { Directive, HostListener, AfterContentInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import * as VanillaMaker from 'vanilla-masker';

@Directive({ selector: '[cpfCnpj]' })
export class CpfCnpjDirective implements AfterContentInit {

    private readonly CNPJ = '99.999.999/9999-99';
    private readonly CPF = '999.999.999-99';

    constructor(public control: NgControl) { }

    ngAfterContentInit() {
        this.format(this.control.value || '');
    }

    @HostListener('input', ['$event.target.value']) onInput(value: string) {
        this.format(value);
    }

    public format(value: string) {
        if (value && value.length <= 14)
            value = VanillaMaker.toPattern(value, this.CPF);
        else
            value = VanillaMaker.toPattern(value, this.CNPJ);
        this.control.control.setValue(value);
    }
}