<ng-container *ngIf="showFooter">
  <div class="visible-xs visible-sm">
    <div class="container-fluid footer">
      <div class="row">
        <div class="footer-block col-sm-4 col-xs-12">
          <p>CONTATO</p>
          <div class="whatsapp" *ngIf="relacionamentoCliente.whatsapp && !relacionamentoCliente.link_whatsapp">
            <i class="fa fa-whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;{{
              relacionamentoCliente.whatsapp
            }}
          </div>
          <div class="whatsapp" *ngIf="relacionamentoCliente.whatsapp && relacionamentoCliente.link_whatsapp">
            <a href="{{relacionamentoCliente.url_whatsapp}}" target="_blank" class="link-whatsapp-sm" >
              <i class="fa fa-whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;{{
                relacionamentoCliente.whatsapp
              }}
            </a>
          </div>
          
          <a *ngIf="relacionamentoCliente.link_whatsapp && !script" href="{{relacionamentoCliente.url_whatsapp}}" class="float-fab-wtt-sm" target="_blank">
            <i class="fa fa-whatsapp my-float-fab-wtt"></i>
          </a>
          <a *ngIf="relacionamentoCliente.link_whatsapp && script" href="{{relacionamentoCliente.url_whatsapp}}" class="float-fab-wtt-sm-up" target="_blank">
            <i class="fa fa-whatsapp my-float-fab-wtt"></i>
          </a>
          <div class="sav-tel">
            <a href="tel:{{ relacionamentoCliente.telefone }}">
              <i class="fa fa-phone" aria-hidden="true" *ngIf="relacionamentoCliente.telefone"></i>&nbsp;&nbsp;{{ relacionamentoCliente.telefone }}
            </a>
          </div>
          <div class="sav-email">
            <a href="mailto:{{ relacionamentoCliente.email }}">
              <i class="fa fa-envelope" aria-hidden="true" *ngIf="relacionamentoCliente.email"></i>&nbsp; &nbsp;{{ relacionamentoCliente.email }}
            </a>
          </div>
        </div>
        <div class="footer-block col-sm-8 col-xs-12">
          <p>INSTITUCIONAL</p>
          <app-lista-paginas-link [paginas]="paginas" [ulClass]="'institucional-rodape row'" [liClass]="'col-sm-6 col-xs-12'"></app-lista-paginas-link>
        </div>
      </div>
      <div class="row">
        <div class="footer-block col-sm-6 col-xs-12" *ngIf="url_facebook_fanpage != null && url_facebook_fanpage != ''">
          <div class="footer-fb">
            <div class="fb-page" [attr.data-href]="url_facebook_fanpage" data-stream="false" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false"
                 data-show-facepile="true" data-height="230"></div>
          </div>
        </div>
        <div class="footer-block col-sm-6 col-xs-12">
          <div class="row">
            <a class="col-xs-4 footer-selo-e" *ngIf="link_ebit" [href]="link_ebit" target="_blank"><img class="img-responsive" lazyLoad="{{ '/assets/img/seloebit.png' }}" alt="" /></a>
            <a class="col-xs-8 footer-selo-g" [href]="url_google" target="_blank"><img class="img-responsive" lazyLoad="{{ '/assets/img/google-safe.png' }}" alt="" /></a>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 mt10" *ngIf="appleID || package">
              <p class="text-muted" style="text-align: left;">
                COMPRE PELO SEU CELULAR
              </p>
              <a *ngIf="package" href="https://play.google.com/store/apps/details?id={{
                package
              }}&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" class="invite-accepted-img-sm android-img" target="_blank"></a>
              <a *ngIf="appleID" href="https://itunes.apple.com/br/app/{{ appleID }}?mt=8" class="invite-accepted-img-sm ios-img" target="_blank"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <p class="texto-final" [innerHTML]="filial.texto_rodape"></p>
        </div>
        <div class="col-xs-12 text-right">
          <a href="https://supermercadosonline.com.br" target="_blank" class="pull-right">
            <img lazyLoad="{{ '/assets/img/logo-supermercados-online.png' }}" class="vip-grayscale logoSupermercadoOnline"
                 alt="Supermercados Online" *ngIf="filial.participa_supermercado_online == 1" />
          </a>
          <a href="https://vipcommerce.com.br" target="_blank" class="pull-right">
            <img lazyLoad="{{ '/assets/img/vipcommerce.png' }}" class="vip-grayscale" alt="VIPCommerce" />
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="hidden-xs hidden-sm">
    <div class="container-fluid vip-footer">
      <div class="row">
        <div class="col-sm-12">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-lg-3 vip-customer-center text-uppercase text-right">
                RELACIONAMENTO COM O CLIENTE
              </div>
              <div class="col-md-8 col-lg-9 vip-customer-info">
                <div class="row">
                  <div class="col-md-3 col-lg-2" *ngIf="relacionamentoCliente.telefone">
                    <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{{
                    relacionamentoCliente.telefone
                    }}
                  </div>
                  <div class="col-md-3 col-lg-2" *ngIf="relacionamentoCliente.whatsapp && !relacionamentoCliente.link_whatsapp">
                    <i class="fa fa-whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;{{ relacionamentoCliente.whatsapp }}
                  </div>
                  <div class="col-md-3 col-lg-2" *ngIf="relacionamentoCliente.whatsapp && relacionamentoCliente.link_whatsapp">
                    <a href="{{relacionamentoCliente.url_whatsapp}}" target="_blank" class="link-whatsapp" >
                      <i class="fa fa-whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;{{ relacionamentoCliente.whatsapp }}
                    </a>
                  </div>
                  <a *ngIf="relacionamentoCliente.link_whatsapp && !script" href="{{relacionamentoCliente.url_whatsapp}}" class="float-fab-wtt" target="_blank">
                    <i class="fa fa-whatsapp my-float-fab-wtt"></i>
                  </a>
                  <a *ngIf="relacionamentoCliente.link_whatsapp && script" href="{{relacionamentoCliente.url_whatsapp}}" class="float-fab-wtt-up" target="_blank">
                    <i class="fa fa-whatsapp my-float-fab-wtt"></i>
                  </a>
                  <div class="col-md-6 col-lg-6 text-center" *ngIf="filial.email_sav">
                    <i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;&nbsp;{{ relacionamentoCliente.email }}
                  </div>
                  <div class="hidden-sm hidden-md col-lg-2 vip-social">
                    <i class="fa fa-commenting-o" routerLink="/institucional/paginas/fale-conosco" aria-hidden="true" style="cursor: pointer">&nbsp;</i>
                    <i class="fa fa-twitter-square" aria-hidden="true" (click)="goToUrl(filial.url_twitter)" *ngIf="filial.url_twitter" style="cursor: pointer">&nbsp;</i>
                    <i class="fa fa-facebook-square" aria-hidden="true" (click)="goToUrl(filial.url_facebook)" *ngIf="filial.url_facebook" style="cursor: pointer">&nbsp;</i>
                    <i class="fa fa-google-plus-square" aria-hidden="true" (click)="goToUrl(filial.url_google)" *ngIf="filial.url_google" style="cursor: pointer">&nbsp;</i>
                    <i class="fa fa-youtube-square" aria-hidden="true" (click)="goToUrl(filial.url_youtube)" *ngIf="filial.url_youtube" style="cursor: pointer">&nbsp;</i>
                    <i class="fa fa-instagram" aria-hidden="true" (click)="goToUrl(filial.url_instagram)" *ngIf="filial.url_instagram" style="cursor: pointer">&nbsp;</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix">&nbsp;</div>

    <div class="container">
      <div class="row">
        <div class="col-xs-6 col-sm-3">
          <p class="text-muted">QUEM SOMOS</p>
          <div class="text-muted" [innerHtml]="texto_quem_somos"></div>
          <a href="javascript:void(0)" routerLink="/institucional/paginas/{{ getSlug('quem-somos') }}" class="text-muted" style="text-decoration: underline;">Clique aqui e saiba mais</a>
        </div>
        <div class="col-xs-6 col-sm-3">
          <p class="text-muted">INSTITUCIONAL</p>
          <app-lista-paginas-link [paginas]="paginas" [ulClass]="'institucional-rodape row'" [liClass]="'col-xs-12'"></app-lista-paginas-link>
        </div>
        <div class="col-xs-6 col-sm-3">
          <p class="text-muted">FORMAS DE PAGAMENTO</p>
          <div class="text-muted" [innerHtml]="texto_forma_pagamentos"></div>
          <a href="javascript:void(0)" routerLink="/institucional/paginas/{{
            getSlug('formas-de-pagamento')
          }}" class="text-muted" style="text-decoration: underline;">Clique aqui e saiba mais</a>
        </div>
        <div class="col-xs-6 col-sm-3">
          <div class="row" style="margin-bottom: 10px;" *ngIf="url_facebook_fanpage != null && url_facebook_fanpage != ''">
            <div class="col-md-12">
              <div class="fb-page" [attr.data-href]="url_facebook_fanpage" data-stream="false" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false"
                   data-show-facepile="true" data-height="230" data-width="330"></div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 text-center" *ngIf="link_ebit">
              <a [href]="link_ebit" target="_blank"><img class="img-responsive" lazyLoad="{{ '/assets/img/seloebit.png' }}" alt="" /></a>
            </div>
            <div class="col-md-8 text-center">
              <a [href]="url_google" target="_blank"><img class="img-responsive" lazyLoad="{{ '/assets/img/google-safe.png' }}" alt="" /></a>
            </div>
          </div>
          <div class="row" *ngIf="package || appleID">
            <div class="col-xs-12 col-sm-12 mt10 text-center">
              <p class="text-muted" style="text-align: left;">
                COMPRE PELO SEU CELULAR
              </p>
              <a *ngIf="package" href="https://play.google.com/store/apps/details?id={{
                package
              }}&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" class="invite-accepted-img android-img" target="_blank"></a>
              <a *ngIf="appleID" href="https://itunes.apple.com/br/app/{{
                appleID
              }}?mt=8" class="invite-accepted-img ios-img" target="_blank"></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix">&nbsp;</div>

    <div class="container-fluid">
      <div class="row vip-divider">
        <div class="col-xs-6 col-sm-6"></div>
        <div class="col-xs-6 col-sm-6"></div>
      </div>
    </div>

    <div class="clearfix">&nbsp;</div>
    <div class="container">
      <div class="row">
        <div class="col-md-12" [innerHTML]="filial.texto_rodape"></div>
        <div class="clearfix">&nbsp;</div>
        <div class="col-md-12">
          <a href="https://supermercadosonline.com.br" target="_blank" class="pull-right">
            <img lazyLoad="{{ '/assets/img/logo-supermercados-online.png' }}" class="vip-grayscale logoSupermercadoOnline"
              alt="Supermercados Online" *ngIf="filial.participa_supermercado_online == 1" />
          </a>
          <a href="https://vipcommerce.com.br" target="_blank" class="pull-right">
            <img lazyLoad="{{ '/assets/img/vipcommerce.png' }}" class="vip-grayscale" alt="VIPCommerce" />
          </a>
        </div>
      </div>
    </div>

    <div class="clearfix">&nbsp;</div>
  </div>
</ng-container>

<app-aceite-cookie></app-aceite-cookie>
