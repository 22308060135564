import {Component, OnInit} from '@angular/core';
import {TextoService} from "./shared/texto.service";
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-textos',
    templateUrl: './textos.component.html',
    styleUrls: ['./textos.component.css']
})
export class TextosComponent implements OnInit {

    public texto: any;

    constructor(private textoService: TextoService,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit() {

        /*this.textoService.detalhe().then(
            () => {
                this.texto = this.sanitizer.bypassSecurityTrustHtml(this.textoService.texto.texto);
            }
        );*/

        this.textoService.slug().then(
            () => {
                this.texto = this.sanitizer.bypassSecurityTrustHtml(this.textoService.texto.texto);
            }
        );
    }

}
