<div class="cart-banner-wrapper">
  <div class="cart-banner" [ngClass]="{ visible: ultimoItem != null }">
    <h2 *ngIf="ultimoItem?.quantidade > 0">
      Produto adicionado ao carrinho
    </h2>
    <h2 *ngIf="ultimoItem?.quantidade == 0">
      Produto removido do carrinho
    </h2>
    <div class="cart-image">
      <app-produto-imagem
        *ngIf="ultimoItem != null"
        [produto]="ultimoItem"
        [tamanho]="60"
        [classe]="'center-block image-product'"
      ></app-produto-imagem>
      <div class="descricao">
        {{ ultimoItem?.descricao }}
        <br />
        <span>Preço un.: {{ ultimoItem?.preco_venda | price }}</span>
        <br />
        <span *ngIf="ultimoItem?.quantidade > 0">
          Qtd.: {{ ultimoItem?.quantidade }}
        </span>
      </div>
    </div>
  </div>
</div>
