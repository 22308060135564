import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AntifraudeService{

    constructor(
        private httpClient: HttpClient
    ) {}

    /**
     * Indica se um sistema de antifraude está ativo
     * @param nome o nome do antifraude a ser verificado.
     * @see AntifraudeEnum
     */
    isAtivo(nome){
        return this.httpClient
            .get<any>(`${environment.apiUrl}/loja/antifraudes/${nome}/ativo`);
    }

    /**
     * Retorna o appid de antifraudes que utilizem essa informação
     * em suas configurações
     * @param nome Nome do antifraude
     * @see AntifraudeEnum
     */
    getAppId(nome){
        return this.httpClient
        .get<any>(`${environment.apiUrl}/loja/antifraudes/${nome}/appid`);
    }
}