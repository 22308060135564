import { BsModalService } from 'ngx-bootstrap';
import { FilialService } from './../../../filiais/shared/filial.service';
import { MinhasListas } from './../../interfaces/minhas-listas';
import { Component, OnInit } from '@angular/core';

@Component({
  styleUrls: ['./minhas-listas-share.component.css'],
  templateUrl: './minhas-listas-share.component.html'
})
export class MinhasListasShareComponent implements OnInit {
  public list: MinhasListas = <MinhasListas>{};
  public title: string = 'Compartilhar Lista';
  public url: string;

  public constructor(
    private modalService: BsModalService,
    private filialService: FilialService
  ) {}

  public ngOnInit() {
    this.url = this.filialService.detail.site_url + '/compartilhar-lista/';
  }

  public close() {
    this.modalService._hideModal(1);
  }

  public copyText() {
    const aux = document.createElement('input');
    aux.setAttribute('value', document.getElementById('copy').innerHTML);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    document.body.removeChild(aux);

    let copyButton = document.getElementById('copyBtnLabel');
    copyButton.innerHTML = ' Copiado';
    setTimeout(() => {
      copyButton.innerHTML = ' Copiar';
    }, 1000);
  }

  public shareWhatsApp() {
    let text =
      'Olá, gostaria de compartilhar com você a lista ' +
      this.list.nome +
      '. Acesse o link para mais detalhes: ' +
      this.url +
      this.list.slug;
    let whatsAppUrl = 'whatsapp://send?text=' + text;
    window.open(whatsAppUrl);
  }
}
