export const States: Array<{ name: string, initials: string }> = [
  {
    initials: 'AC', name: 'Acre'
  },
  {
    initials: 'AL', name: 'Alagoas'
  },
  {
    initials: 'AM', name: 'Amazonas'
  },
  {
    initials: 'AP', name: 'Amapá'
  },
  {
    initials: 'BA', name: 'Bahia'
  },
  {
    initials: 'CE', name: 'Ceará'
  },
  {
    initials: 'DF', name: 'Distrito Federal'
  },
  {
    initials: 'ES', name: 'Espírito Santo'
  },
  {
    initials: 'GO', name: 'Goiás'
  },
  {
    initials: 'MA', name: 'Maranhão'
  },
  {
    initials: 'MG', name: 'Minas Gerais'
  },
  {
    initials: 'MS', name: 'Mato Grosso do Sul'
  },
  {
    initials: 'MT', name: 'Mato Grosso'
  },
  {
    initials: 'PA', name: 'Pará'
  },
  {
    initials: 'PB', name: 'Paraíba'
  },
  {
    initials: 'PE', name: 'Pernambuco'
  },
  {
    initials: 'PI', name: 'Piauí'
  },
  {
    initials: 'PR', name: 'Paraná'
  },
  {
    initials: 'RJ', name: 'Rio de Janeiro'
  },
  {
    initials: 'RN', name: 'Rio Grande do Norte'
  },
  {
    initials: 'RO', name: 'Rondônia'
  },
  {
    initials: 'RR', name: 'Roraima'
  },
  {
    initials: 'RS', name: 'Rio Grande do Sul'
  },
  {
    initials: 'SC', name: 'Santa Catarina'
  },
  {
    initials: 'SE', name: 'Sergipe'
  },
  {
    initials: 'SP', name: 'São Paulo'
  },
  {
    initials: 'TO', name: 'Tocantins'
  }
];
