import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { LimiteCdComponent } from '../limite-cd/limite-cd.component';

@Injectable({
  providedIn: 'root'
})
export class LimiteCdService {

  constructor (
    private modalService: BsModalService,
  ) {}

  public abrirLimiteCd(ignoreBackdrop: boolean = false) {
    return this.modalService.show(LimiteCdComponent, {
      keyboard: !ignoreBackdrop,
      ignoreBackdropClick: ignoreBackdrop,
      class: 'modal-loja-selector',
    });
  }

  public fecharLimiteCd() {
    this.modalService._hideModal(1);
  }
}