import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from 'src/environments/environment';

@Injectable()
export class UsuarioService {

  public service: string = '/usuarios';

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) { }

  public login(id: number, password: string) {
    const data = {
      id,
      password
    };

    return this.http.post(`${environment.apiUrl}/loja/televendas/operador/logar`, data)
      .toPromise()
      .then((response: any) => {
        this.saveOperador(response);
      });
  }

  public logout() {
    return this.http.post(this.service + '/logout', {})
      .toPromise()
      .then(() => this.removeUser());
  }

  public saveOperador(response) {
    this.dispacthTelevendas(true);
    this.localStorageService.set('operador', response.data.operador);
    this.localStorageService.set('tokenOperador', response.data.token);
    return response;
  }

  public removeUser() {
    this.dispacthTelevendas(false);
    this.localStorageService.remove('operador');
    this.localStorageService.remove('tokenOperador');
  }

  public getUser() {
    return this.localStorageService.get('operador');
  }

  public invalidUser() {
    this.dispacthTelevendas(false);
    alert('Autenticação não realizada!\nESTA JANELA DEVE SER FECHADA.');
    window.close();
    this.router.navigate(['/']);
  }

  public updateUser(info) {
    let operador: any = this.getUser();
    operador = Object.assign(operador, info);
    this.localStorageService.set('operador', operador);
  }

  public dispacthTelevendas(value) {
    let atualizarTelevendas = new CustomEvent('atualizarTelevendas', {
      detail: value,
    });
    window.dispatchEvent(atualizarTelevendas);
  };

}
