<ng-template #tmpBtnCd>
  <ng-container *ngIf="visibilidadeCd">
    <button type="button" class="btn btn-warning" (click)="abrirSelecaoLoja()">
      <i class="fa fa-map-marker" aria-hidden="true"></i>
      <span class="hidden-sm"> {{ nomeSite }} </span>
    </button>
  </ng-container>
</ng-template>

<ng-template #tmpBtnMinhasListas>
  <button type="button" class="btn right-menu-btn ml3" routerLink="minhas-listas">
    <i class="fa fa-list" aria-hidden="true"></i>
    <span class="hidden-sm"> Minhas listas</span>
  </button>
</ng-template>

<ng-template #tmpBtnIntegracao>
  <ng-container *ngIf="iframe">
    <button type="button" class="btn right-menu-btn ml3" routerLink="integracao">
      <i class="fa fa-flag-o" aria-hidden="true"></i>
      <span class="hidden-sm"> {{ iframe?.descricao_botao }} </span>
    </button>
  </ng-container>
</ng-template>

<ng-template #tmpBtnOfertasExclusivas>
  <button *ngIf="haOfertasExclusivas" type="button" class="btn right-menu-btn" routerLink="produtos/ofertas/tags/{{ tagOfertaExclusiva?.slug }}">
    <i class="fa fa-usd" aria-hidden="true"></i>
    <span class="hidden-sm"> {{ tagOfertaExclusiva?.descricao }}</span>
  </button>
</ng-template>

<ng-template #tmpBtnSorteios>
  <button *ngIf="haCampanhaFidelidade" type="button" class="btn right-menu-btn sorteios" routerLink="/sorteios">
    <i class="fa vp-clover vp-clover--white" aria-hidden="true"></i>
    <span class="hidden-sm"> Sorteios</span>
  </button>
</ng-template>

<ng-template #tmpClienteMenu>
  <div class="nav-bar__cliente-menu" [ngClass]="{
      'nav-bar__cliente-menu--all-buttons': allButtonsMenuCliente()
    }">
    <div class="text-center">
      <div class="form-group">
        <span>Olá {{ firstName }}! </span>
      </div>
      <a class="btn btn-my-account" href="javascript:void(0)" (click)="modalExtratoCashback()" *ngIf="mostrarSaldoCashback">
        Saldo <span class="hidden-sm">{{ saldo | price }}</span>
      </a>
      <a class="btn btn-my-account" routerLink="/minha-conta"><i class="fa fa-user" aria-hidden="true"></i>
        <span class="hidden-sm">Minha conta</span></a>
      <span>|</span>
      <button type="button" class="btn btn-success" (click)="atendimento()" *ngIf="filial.widget_chat">
        <i class="fa fa-users" aria-hidden="true"></i>
        <span class="hidden-sm">Atendimento online</span>
      </button>
      <button type="button" class="btn btn-danger" (click)="logout()">
        <i class="fa fa-user-times" aria-hidden="true"></i>
        <span class="hidden-sm">Sair</span>
      </button>
      <span>|</span>
    </div>
    <div class="pull-right">
      <ng-container *ngTemplateOutlet="tmpBtnMinhasListas"></ng-container>
      <ng-container *ngTemplateOutlet="tmpBtnOfertasExclusivas"></ng-container>
      <ng-container *ngTemplateOutlet="tmpBtnSorteios"></ng-container>
      <ng-container *ngTemplateOutlet="tmpBtnIntegracao"></ng-container>
      <span *ngIf="visibilidadeCd">|</span>
      <ng-container *ngTemplateOutlet="tmpBtnCd"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #tmpLogin>
  <div class="text-center">
    <button type="button" class="btn btn-get-in" (click)="openLoginModal()">
      <i class="fa fa-sign-in" aria-hidden="true"></i>
      <span class="hidden-sm">Entrar</span>
    </button>
    <a href="javascript:void(0)" (click)="openCreateAccountModal()" class="btn btn-register"><i class="fa fa-user-plus" aria-hidden="true"></i>
      <span class="hidden-sm">Criar uma conta</span></a>
    <div class="pull-right">
      <ng-container *ngTemplateOutlet="tmpBtnSorteios"></ng-container>
      <ng-container *ngTemplateOutlet="tmpBtnCd"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #tmpTelevendas>
  <div class="text-center">
    <div class="form-group">
      <a href="javascript:void(0);" routerLink="televendas" class="btn btn-primary">Selecione um cliente para realizar a
        compra</a>
    </div>
    <a class="btn btn-danger" (click)="sairTelevendas()">
      <i class="fa fa-trash" aria-hidden="true"></i>
      <span class="hidden-sm">Encerrar acesso</span>
    </a>

    <div class="pull-right">
      <ng-container *ngTemplateOutlet="tmpBtnCd"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #tmpClienteMenuTelevendas>
  <div *ngIf="televendas && clienteLogado" class="text-center">
    <div class="form-group">
      <span>Efetuando compra para <strong>{{ firstName }}</strong>!</span>
    </div>
    <a class="btn btn-success" routerLink="minha-conta"><i class="fa fa-user" aria-hidden="true"></i>
      <span class="hidden-sm">Acessar conta</span></a>
    <span>|</span>
    <button type="button" class="btn btn-warning" (click)="logout()">
      <i class="fa fa-user-times" aria-hidden="true"></i>
      <span class="hidden-sm">Encerrar venda</span>
    </button>
    <a class="btn btn-danger" (click)="sairTelevendas()">
      <i class="fa fa-trash" aria-hidden="true"></i>
      <span class="hidden-sm">Encerrar acesso</span>
    </a>

    <div class="{{ layoutMenuTelevendas() }}">
      <ng-container *ngTemplateOutlet="tmpBtnMinhasListas"></ng-container>
      <ng-container *ngTemplateOutlet="tmpBtnOfertasExclusivas"></ng-container>
      <ng-container *ngTemplateOutlet="tmpBtnIntegracao"></ng-container>
      <ng-container *ngTemplateOutlet="tmpBtnCd"></ng-container>
    </div>
  </div>
</ng-template>

<nav class="navbar navbar-default navbar-static-top hidden-xs hidden-sm vip-navbar">
  <div class="container">
    <div class="row">
      <div class="w100">
        <div id="navbar" class="navbar-collapse collapse" aria-expanded="false">
          <div class="navbar-form">
            <ng-container *ngIf="mostrarLogin">
              <ng-container *ngTemplateOutlet="tmpLogin"></ng-container>
            </ng-container>

            <ng-container *ngIf="mostrarMenuCliente">
              <ng-container *ngTemplateOutlet="tmpClienteMenu"></ng-container>
            </ng-container>

            <ng-container *ngIf="mostrarTelevendas">
              <ng-container *ngTemplateOutlet="tmpTelevendas"></ng-container>
            </ng-container>

            <ng-container *ngIf="mostrarMenuClienteTelevendas">
              <ng-container *ngTemplateOutlet="tmpClienteMenuTelevendas"></ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<nav class="vip-navbar vip-navbar-xs navbar navbar-default navbar-fixed-top">
  <button (click)="toggleMenu()" class="hamburger-wrapper" [ngClass]="{ 'alert-menu': haOfertasExclusivas }" *ngIf="!showBack()">
    <span class="hamburger">
      <span>Mostrar/Ocultar menu</span>
    </span>
  </button>

  <a class="busca-toggle" *ngIf="showBack()" (click)="goBack()">
    <i class="fa fa-arrow-left"></i>
  </a>
  <div class="title-nav text-center" *ngIf="!isSearching" routerLink="/">
    <span *ngIf="!televendas">{{ filial.nome }}</span>
    <span *ngIf="televendas">Televendas</span>
  </div>
  <ng-container *ngIf="isSearching">
    <div class="busca-nav">
      <form class="form" (submit)="realizarBusca()">
        <button type="submit" class="btn btn-search">
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
        <input type="text" class="form-control" [(ngModel)]="q" id="q" name="q" [placeholder]="placeholder" autocomplete="off" />
        <button class="btn btn-close-search" (click)="toggleSearch($event)">
          <i class="fa fa-close" aria-hidden="true"></i>
        </button>
      </form>
    </div>
  </ng-container>
  <ng-container *ngIf="!isSearching">
    <a href="javascript:void(0)" class="busca-toggle" aria-label="Mostrar campo de busca" (click)="toggleSearch($event)">
      <i class="fa fa-search" aria-hidden="true" *ngIf="!isSearching"></i>
      <i class="fa fa-times-circle" aria-hidden="true" *ngIf="isSearching"></i>
    </a>
  </ng-container>
  <a href="javascript:void(0)" class="carrinho-nav" routerLink="/{{ em_processo ? 'checkout/pagamento' : 'checkout/carrinho' }}">
    <i class="fa fa-shopping-basket" aria-hidden="true"></i>
    <span class="badge">{{ totalItensCarrinho }}</span>
  </a>
</nav>

<div class="sidemenu-overlay" (click)="toggleMenu()"></div>
<div class="sidemenu">
  <div class="sidemenu__area-cliente" *ngIf="clienteLogado && !televendas">
    <div class="user-info">
      <span class="fa fa-user user-icon"></span>Olá,
      <label>{{ firstName }}</label>
    </div>
    <!-- Destaques avaliar usabilidade -->
    <!-- <div class="sidemenu__area-cliente--btn-group">
            <button class="btn" routerLink="/minha-cont>Conta</button>
            <button class="btn" routerLink="/produtos/ofertas/tags/{{ tagOfertaExclusiva?.slug }}" *ngIf="haOfertasExclusiva>Ofertas Exclusivas</button>
        </div> -->
  </div>

  <ul class="nav">
    <div class="sidemenu__area-cliente text-center" *ngIf="!clienteLogado && !televendas">
      <button (click)="openLoginModal()" class="btn btn-block" (click)="fecharMenu()">
        Entrar
      </button>
      <button (click)="openCreateAccountModal()" class="btn btn-block" (click)="fecharMenu()">
        Criar conta
      </button>
    </div>

    <div class="sidemenu__area-cliente text-center" *ngIf="televendas && !clienteLogado">
      <p>Selecione um cliente para realizar a compra</p>
      <div class="sidemenu__area-cliente--btn-group">
        <button class="btn" (click)="sairTelevendas(); fecharMenu()">
          Encerrar acesso
        </button>
        <button routerLink="televendas" class="btn" (click)="fecharMenu()">
          Selecionar
        </button>
      </div>
    </div>

    <div class="sidemenu__area-cliente text-center" *ngIf="televendas && clienteLogado">
      <label>Efetuando compra para {{ firstName }}!</label>
      <button class="btn btn-success btn-xs btn-block" routerLink="/minha-conta">
        <i class="fa fa-user" aria-hidden="true"></i><span> Acessar conta</span>
      </button>
      <button class="btn btn-warning btn-xs btn-block" (click)="logout()">
        <i class="fa fa-user-times" aria-hidden="true"></i> Encerrar venda
      </button>
      <button class="btn btn-danger btn-xs btn-block" (click)="sairTelevendas()">
        <i class="fa fa-trash" aria-hidden="true"></i> Encerrar acesso
      </button>
    </div>

    <li class="dropdown">
      <a href="javascript:void(0);" routerLink="/" class="dropdown-toggle" (click)="fecharMenu()"><span class="fa fa-home fa-fw"></span> Início</a>
    </li>

    <li class="dropdown" *ngIf="clienteLogado">
      <a routerLink="/minha-conta" class="dropdown-toggle">
        <span class="fa fa-user fa-fw"></span> Minha conta</a>
    </li>

    <li class="dropdown" *ngIf="clienteLogado && haOfertasExclusivas">
      <a routerLink="/produtos/ofertas/tags/{{ tagOfertaExclusiva?.slug }}" class="dropdown-toggle alert-menu">
        <span class="fa fa-usd fa-fw"></span> {{ tagOfertaExclusiva?.descricao }}</a>
    </li>

    <li class="dropdown" *ngIf="clienteLogado">
      <a href="javascript:void(0);" routerLink="/minhas-listas" class="dropdown-toggle">
        <span class="fa fa-list fa-fw"></span> Minhas Listas</a>
    </li>

    <li class="dropdown" *ngIf="iframe">
      <a href="javascript:void(0);" routerLink="integracao" class="dropdown-toggle">
        <span  class="fa fa-flag-o"></span> {{ iframe?.descricao_botao }}</a>
    </li>

    <ng-container *ngIf="visibilidadeCd">
      <li class="dropdown">
        <a routerLink="/" class="dropdown-toggle" (click)="abrirSelecaoLoja()">
          <span class="fa fa-map-marker fa-fw"></span> {{ nomeSite }}
        </a>
      </li>
    </ng-container>

    <ng-container *ngIf="mostrarSaldoCashback">
      <li class="dropdown">
        <a routerLink="/minha-conta/cashback" class="dropdown-toggle">
          <span class="fa fa-money fa-fw"></span> Cashback
        </a>
      </li>
    </ng-container>

    <li class="dropdown {{ dropDownOpenedClass('meus-favoritos') }}" *ngIf="haFavoritos()">
      <a href="javascript:void(0)" class="dropdown-toggle" (click)="openDropdownMenu('meus-favoritos')"><span class="fa fa-heart fa-fw"></span> Meus Favoritos
        <span class="caret"></span></a>
      <ul class="dropdown-menu">
        <li *ngFor="let favorito of favoritos">
          <a routerLink="/produtos/meus-favoritos/{{ favorito.link }}" (click)="fecharMenu()">
            <span class="badge">{{ favorito.quantidade }}</span>
            {{ favorito.descricao | capitalize }}
          </a>
        </li>
      </ul>
    </li>

    <hr />

    <li class="dropdown" *ngIf="haCampanhaFidelidade">
      <a routerLink="/sorteios" class="dropdown-toggle sorteios">
        <i class="fa vp-clover" aria-hidden="true"></i>
        Sorteios
      </a>
    </li>

    <li class="dropdown {{ dropDownOpenedClass('departamentos') }}">
      <a href="javascript:void(0)" class="dropdown-toggle" (click)="openDropdownMenu('departamentos')"><span class="fa fa-sitemap fa-fw"></span> Departamentos
        <span class="caret"></span></a>
      <ul class="dropdown-menu">
        <li *ngFor="let departamento of departamentos">
          <a routerLink="departamento/{{ departamento.link }}" (click)="fecharMenu()">
            {{ departamento.descricao | capitalize }}
          </a>
        </li>
      </ul>
    </li>

    <li class="dropdown {{ dropDownOpenedClass('mais-vendidos') }}">
      <a href="javascript:void(0)" class="dropdown-toggle" (click)="openDropdownMenu('mais-vendidos')"><span class="fa fa-line-chart fa-fw"></span> Mais Vendidos
        <span class="caret"></span></a>
      <ul class="dropdown-menu">
        <li *ngFor="let departamento of departamentos">
          <a routerLink="mais-vendidos/{{ departamento.link }}" (click)="fecharMenu()">
            {{ departamento.descricao | capitalize }}
          </a>
        </li>
      </ul>
    </li>

    <li class="dropdown {{ dropDownOpenedClass('ofertas') }}" *ngIf="haOfertas()">
      <a href="javascript:void(0)" class="dropdown-toggle" (click)="openDropdownMenu('ofertas')"><span class="fa fa-usd fa-fw"></span> Ofertas
        <span class="caret"></span></a>
      <ul class="dropdown-menu">
        <ng-container *ngFor="let departamento of departamentos">
          <li *ngIf="departamento.total_ofertas > 0">
            <a routerLink="/produtos/ofertas/departamento/{{
                departamento.link
              }}" (click)="fecharMenu()">
              <span class="badge">{{ departamento.total_ofertas }}</span>
              {{ departamento.descricao | capitalize }}
            </a>
          </li>
        </ng-container>
      </ul>
    </li>

    <li class="dropdown {{ dropDownOpenedClass('colecoes') }}" *ngIf="haColecoes()">
      <a href="javascript:void(0)" class="dropdown-toggle" (click)="openDropdownMenu('colecoes')"><span class="fa fa-cubes fa-fw"></span> Coleções
        <span class="caret"></span></a>
      <ul class="dropdown-menu">
        <li *ngFor="let colecao of colecoes">
          <a routerLink="colecoes/{{ colecao.id }}/{{ colecao.slug }}" (click)="fecharMenu()">
            {{ colecao.descricao | capitalize }}
          </a>
        </li>
      </ul>
    </li>

    <li class="dropdown {{ dropDownOpenedClass('combos') }}" *ngIf="haCombos()">
      <a href="javascript:void(0)" class="dropdown-toggle" (click)="openDropdownMenu('combos')"><span class="fa fa-asterisk fa-fw"></span> Combos
        <span class="caret"></span></a>
      <ul class="dropdown-menu">
        <ng-container *ngFor="let combo of combos">
          <li *ngIf="combo.total > 0">
            <a routerLink="/produtos/combos/{{ combo.categoria_id }}/{{
                combo.slug
              }}" (click)="fecharMenu()">
              <span class="badge">{{ combo.total }}</span>
              {{ combo.descricao | capitalize }}
            </a>
          </li>
        </ng-container>
      </ul>
    </li>

    <li class="dropdown {{ dropDownOpenedClass('receitas') }}" *ngIf="haReceitas()">
      <a href="javascript:void(0)" class="dropdown-toggle" (click)="openDropdownMenu('receitas')"><span class="fa fa-book fa-fw"></span> Receitas
        <span class="caret"></span></a>
      <ul class="dropdown-menu">
        <li *ngFor="let receita of receitas">
          <a routerLink="receitas/{{ receita.id }}/{{ receita.slug }}" (click)="fecharMenu()">
            {{ receita.descricao | capitalize }}
          </a>
        </li>
      </ul>
    </li>

    <li class="dropdown {{ dropDownOpenedClass('institucional') }}" *ngIf="viewService.isInAppMode()">
      <a href="javascript:void(0)" class="dropdown-toggle" (click)="openDropdownMenu('institucional')"><span class="fa fa-info-circle fa-fw"></span> Institucional
        <span class="caret"></span></a>
      <app-lista-paginas-link [paginas]="paginas" [ulClass]="'dropdown-menu'"></app-lista-paginas-link>
    </li>

    <li class="dropdown" *ngIf="clienteLogado && !televendas && !viewService.isInAppMode()">
      <a href="javascript:void(0);" (click)="logout()" class="dropdown-toggle">
        <span class="fa fa-user-times fa-fw"></span> Sair</a>
    </li>
  </ul>
</div>
