import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ParametroService {
    constructor(private httpClient: HttpClient) { }

    public valorMinimoCompra: number = 0;
    public tipoEntregaPadrao: number | null = null;
    public formaFaturamento = { valorFormaFaturamento: '', valorFaturamentoPadrao: '' };

    buscarValorMinimoPermitido() {
        const URI = 'compras/valor_minimo_permitido';
        return this.httpClient
            .get(`${environment.apiUrl}/loja/${URI}`)
            .toPromise()
            .then((res: any) => (this.valorMinimoCompra = res.data));
    }



    buscarTipoEntregaPadrao() {
        const URI = 'compras/entrega/tipo_entrega_padrao';
        return this.httpClient
            .get(`${environment.apiUrl}/loja/${URI}`)
            .toPromise()
            .then((res: any) => {
                this.tipoEntregaPadrao = !isNaN(parseInt(res.data)) ? parseInt(res.data) : null;
            });
    }

    buscarFormaFaturamento() {
        const URI = 'compras/checkout/forma_faturamento';
        return this.httpClient
            .get(`${environment.apiUrl}/loja/${URI}`)
            .toPromise()
            .then((res: any) => {
                this.formaFaturamento = res.data;
            });
    }

    async buscarValidacaoPin() {
        const URI = 'clientes/pin_ativo';
        const response = await this.httpClient.get<{data:{
            pin_ativo: boolean
        }}>(`${environment.apiUrl}/loja/${URI}`)
            .toPromise()
        return response.data;
    }
}
