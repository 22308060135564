import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CepService } from '../../ceps/shared/cep.service';
import { AlterarLojaComponent } from '../alterar-loja/alterar-loja.component';
import { AlterarLojaService } from './alterar-loja.service';
import { MensagemAlteracaoCd } from './MensagemAlteracaoCd';
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal";
import * as i2 from "angular-2-local-storage";
import * as i3 from "./alterar-loja.service";
import * as i4 from "@angular/common/http";
import * as i5 from "../../ceps/shared/cep.service";
export class CentroDistribuicaoService {
    constructor(modalService, lsService, alterarLojaService, httpClient, cepService) {
        this.modalService = modalService;
        this.lsService = lsService;
        this.alterarLojaService = alterarLojaService;
        this.httpClient = httpClient;
        this.cepService = cepService;
        this._visibilidadeCd$ = new Subject();
        this.visibilidadeCd$ = this._visibilidadeCd$.asObservable();
        this._selecionarLoja$ = new Subject();
        this.selecionarLoja$ = this._selecionarLoja$.asObservable();
        this._alterarParaCd$ = new Subject();
        this.alterarParaCd$ = this._alterarParaCd$.asObservable();
        this._cdSelecionado = {};
        this._possuiVarios = false;
        this._loadingModalSelectCd = false;
        this.alterarLojaService.depoisSelecionarLoja$.subscribe((infoCd) => {
            if (infoCd.lojaAlterada) {
                this._cdSelecionado = infoCd.lojaDestino;
                this._alterarParaCd$.next(infoCd);
            }
            this._selecionarLoja$.next(infoCd);
        });
    }
    get cdSelecionado() {
        if (!('id' in this._cdSelecionado)) {
            this._cdSelecionado = this.lsService.get('loja');
        }
        return this._cdSelecionado;
    }
    get id() {
        return this.cdSelecionado.id;
    }
    get lojaJaSelecionada() {
        return this.lsService.keys().indexOf('cdSelecionado') > -1;
    }
    get cdConfirmado() {
        return ((this._possuiVarios && this.lsService.get('cdConfirmado')) ||
            !this._possuiVarios);
    }
    get possuiVarios() {
        return this._possuiVarios;
    }
    get loadingModalSelectCd() {
        return this._loadingModalSelectCd;
    }
    set loadingModalSelectCd(value) {
        this._loadingModalSelectCd = value;
    }
    abrirSelecionarLoja(ignoreBackdrop = false) {
        return this.modalService.show(AlterarLojaComponent, {
            keyboard: !ignoreBackdrop,
            ignoreBackdropClick: ignoreBackdrop,
            class: 'modal-loja-selector',
        });
    }
    countCds() {
        const URI = `loja/centros_distribuicoes/filial/${environment.filialId}/possui_varios`;
        this.httpClient
            .get(`${environment.apiUrl}/${URI}`)
            .subscribe((res) => {
            this._possuiVarios = res.data;
            this._visibilidadeCd$.next(this._possuiVarios);
        });
    }
    buscaInfoCd(cdId) {
        const URI = `loja/centros_distribuicoes/${cdId}`;
        return this.httpClient.get(`${environment.apiUrl}/${URI}`);
    }
    buscaEAlteraCd(cdId) {
        const buscaCd$ = this.buscaInfoCd(cdId).subscribe((res) => {
            buscaCd$.unsubscribe();
            this.alterarLojaService.salvarLojaSelecionada(res.data);
        });
    }
    buscaEAlteraCdPorPontoRetirada(tipoBusca, codigoBusca) {
        if (tipoBusca == 'cep') {
            this.cepService.validaCepCd(codigoBusca).subscribe((req) => {
                if (req.data.length === 1) {
                    const cd = req.data[0];
                    return this.alterarLojaService.salvarLojaSelecionada(cd);
                }
                return alert('Não possuímos CD para retirada no endereço selecionado.');
            });
        }
        else if (tipoBusca == 'codigo_ibge') {
            return alert('A busca por código IBGE ainda não se encontra disponível');
        }
    }
    buscaEAlteraCdPorAreaEntrega(tipoBusca, codigoBusca) {
        if (tipoBusca == 'cep') {
            this.cepService.validaCepCd(codigoBusca).subscribe((req) => {
                if (req.data.length === 1) {
                    const cd = req.data[0];
                    return this.alterarLojaService.salvarLojaSelecionada(cd);
                }
                return alert('Não realizamos entrega no endereço selecionado.');
            });
        }
        else if (tipoBusca == 'codigo_ibge') {
            return alert('A busca por código IBGE ainda não se encontra disponível');
        }
    }
    alterarParaCd(cd, montarCarrinho = true) {
        this.alterarLojaService.salvarLojaSelecionada(cd, montarCarrinho);
    }
    buscaCdPadrao(cdId) {
        let urlComCd = '';
        if (cdId) {
            urlComCd = `/atual/${cdId}`;
        }
        const URI = `centros_distribuicoes/filial/${environment.filialId}${urlComCd}/padrao`;
        return this.httpClient.get(`${environment.apiUrl}/loja/${URI}`);
    }
    exibirSelecaoCdAoIniciar() {
        const URI = `centros_distribuicoes/exibir_selecao`;
        return this.httpClient
            .get(`${environment.apiUrl}/loja/${URI}`)
            .pipe(take(1))
            .subscribe((res) => {
            if (res.data && this._possuiVarios) {
                this.abrirSelecionarLoja(true);
            }
        });
    }
    confirmarAlteracaoCd(msg = MensagemAlteracaoCd.MENSAGEM_PADRAO) {
        return this.alterarLojaService.confirmarAlteracaoCd(msg);
    }
}
CentroDistribuicaoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CentroDistribuicaoService_Factory() { return new CentroDistribuicaoService(i0.ɵɵinject(i1.BsModalService), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.AlterarLojaService), i0.ɵɵinject(i4.HttpClient), i0.ɵɵinject(i5.CepService)); }, token: CentroDistribuicaoService, providedIn: "root" });
