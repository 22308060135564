import { Component, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/clientes/shared/cliente.service';

@Component({
  selector: 'app-aceite-cookie',
  templateUrl: './aceite-cookie.component.html',
  styleUrls: ['./aceite-cookie.component.scss']
})
export class AceiteCookieComponent implements OnInit {
  public autorizadoUsarCookie: boolean;

  constructor(private clienteService: ClienteService) {}

  public ngOnInit() {
    this.validarExibicaoAceiteCookie()
  }

  public validarExibicaoAceiteCookie() {
    const clienteLogado = this.clienteService.logged();
    const autorizadoUsarCookieLocal = this.clienteService.getAutorizadoUsarCookieLocal();
    if (autorizadoUsarCookieLocal) {
      this.autorizadoUsarCookie = autorizadoUsarCookieLocal;
    } else if (clienteLogado) {
      this.buscarClienteAceitouUsoCookie();
    } else {
      this.buscarClienteAceitouUsoCookieAfterLogin();
    }
  }

  public buscarClienteAceitouUsoCookieAfterLogin() {
    this.clienteService.afterLogin$.subscribe(() => {
      this.buscarClienteAceitouUsoCookie();
    });
  }

  public buscarClienteAceitouUsoCookie() {
    this.clienteService.buscarAceiteUsoCookie().subscribe((res: any) => {
      this.autorizadoUsarCookie = res.data;
      this.clienteService.salvarAutorizacaoUsoCookie(res.data);
    })
  }

  public aceitarUsoCookie() {
    this.clienteService.aceitarUsoCookie();
    this.clienteService.salvarAutorizacaoUsoCookie();
    this.autorizadoUsarCookie = true;
  }
}
