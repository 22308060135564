<div class="escolha-modal">
  <div class="form-group">
    <p class="texto-escolha">
      Há um carrinho de compras vinculado ao seu usuário.
      Deseja manter o carrinho existente?
    </p>
  </div>
  <button type="submit" class="btn btn-success btn-sm" (click)="confirmar(true)"><i class="fa fa-check-square"
      aria-hidden="true">&nbsp;</i>Manter existente</button>
  <button type="submit" class="btn btn-primary btn-sm pull-right" (click)="confirmar(false)"><i
      class="fa fa-minus-square" aria-hidden="true">&nbsp;</i>Manter novo</button>
</div>
