/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./previsao-entrega.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./previsao-entrega.component";
import * as i4 from "ngx-bootstrap/modal";
import * as i5 from "../shared/tipo-entrega.service";
var styles_PrevisaoEntregaComponent = [i0.styles];
var RenderType_PrevisaoEntregaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrevisaoEntregaComponent, data: {} });
export { RenderType_PrevisaoEntregaComponent as RenderType_PrevisaoEntregaComponent };
function View_PrevisaoEntregaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "tipo-entrega-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["\u2022 ", ": "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.tipo_entrega.descricao; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.proxima_data.substr(0, 19), "dd/MM/yyyy")); _ck(_v, 4, 0, currVal_1); }); }
export function View_PrevisaoEntregaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "modal-title text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aten\u00E7\u00E3o"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "tipo-entrega-itens"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrevisaoEntregaComponent_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Continuar"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tipoEntregaService.disponibilidade.tipoEntregaDisponibilidade; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tipoEntregaService.disponibilidade.textoPrevisaoEntrega; _ck(_v, 6, 0, currVal_0); }); }
export function View_PrevisaoEntregaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PrevisaoEntregaComponent_0, RenderType_PrevisaoEntregaComponent)), i1.ɵdid(1, 114688, null, 0, i3.PrevisaoEntregaComponent, [i4.BsModalService, i5.TipoEntregaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrevisaoEntregaComponentNgFactory = i1.ɵccf("ng-component", i3.PrevisaoEntregaComponent, View_PrevisaoEntregaComponent_Host_0, {}, {}, []);
export { PrevisaoEntregaComponentNgFactory as PrevisaoEntregaComponentNgFactory };
