import { BsModalService } from 'ngx-bootstrap';
import { Component, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

declare let window: any;

@Component({
  styleUrls: ['./criar-conta-modal.component.css'],
  templateUrl: './criar-conta-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CriarContaModalComponent {
  public modalLevel: number = 1;
  public closeEvent$: Subject<any> = new Subject();

  public constructor(private modalService: BsModalService) { }

  public closeButton() {
    this.closeEvent$.next(true);
    this.close();
  }

  public close() {
    this.modalService._hideModal(this.modalLevel);
  }
}
