import { Component, OnInit } from '@angular/core';
import { ProdutoService } from 'src/app/produtos/shared/produto.service';
import { CarrinhoService } from '../carrinho/shared/carrinho.service';
import { CarrinhoItem } from '../carrinho/shared/item-carrinho';

@Component({
  selector: 'app-card-adicionar-carrinho',
  templateUrl: './card-adicionar-carrinho.component.html',
  styleUrls: ['./card-adicionar-carrinho.component.css'],
  providers: [ProdutoService]
})
export class CardAdicionarCarrinhoComponent implements OnInit {
  public ultimoItem: CarrinhoItem = null;
  public ultimoItemTimeout: any = null;

  constructor(private carrinhoService: CarrinhoService) {}

  public ngOnInit() {
    this.carrinhoService.afterAddCarrinho$.subscribe(
      (res: { success: boolean; data: CarrinhoItem }) => {
        if (this.mostrarItem(res)) {
          this.ultimoItem = res.data;
          clearTimeout(this.ultimoItemTimeout);

          this.ultimoItemTimeout = setTimeout(() => {
            this.ultimoItem = null;
          }, 4000);
        }
      }
    );
  }

  private mostrarItem(res) {
    return (
      'success' in res &&
      res.success &&
      'data' in res &&
      'mercadoria_id' in res.data
    );
  }
}
