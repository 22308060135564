/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banners.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../../../node_modules/@ngu/carousel/ngu-carousel.ngfactory";
import * as i5 from "@ngu/carousel";
import * as i6 from "./banners.component";
import * as i7 from "./shared/banner.service";
import * as i8 from "../../shared/services/imagem.service";
var styles_BannersComponent = [i0.styles];
var RenderType_BannersComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BannersComponent, data: {} });
export { RenderType_BannersComponent as RenderType_BannersComponent };
function View_BannersComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "img", [["class", "img-responsive"]], [[8, "src", 4], [8, "alt", 0]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError(_v.parent.parent.context.$implicit.banner_imagens, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_2 = "img-responsive"; var currVal_3 = ("banner-size-" + _v.context.$implicit.largura); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getUrl(_v.context.$implicit); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.descricao, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BannersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "banner-link"]], [[8, "href", 4], [8, "target", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.countCLick(_v.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannersComponent_4)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.banner_imagens; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.link; var currVal_1 = i1.ɵinlineInterpolate(1, "", ((_v.parent.context.$implicit.nova_janela == false) ? "_self" : "_blank"), ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_BannersComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "img", [["class", "img-responsive"]], [[8, "src", 4], [8, "alt", 0]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError(_v.parent.parent.context.$implicit.banner_imagens, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_2 = "img-responsive"; var currVal_3 = ("banner-size-" + _v.context.$implicit.largura); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getUrl(_v.context.$implicit); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.descricao, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BannersComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "banner-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.countCLick(_v.parent.context.$implicit.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannersComponent_6)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", ((_v.parent.context.$implicit.nova_janela == false) ? "_self" : "_blank"), ""); var currVal_3 = _v.parent.context.$implicit.link_banner; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.banner_imagens; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_BannersComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "img", [["class", "img-responsive"]], [[8, "src", 4], [8, "alt", 0]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onError(_v.parent.parent.context.$implicit.banner_imagens, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_2 = "img-responsive"; var currVal_3 = ("banner-size-" + _v.context.$implicit.largura); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getUrl(_v.context.$implicit); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.descricao, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BannersComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "banner-link"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.countCLick(_v.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannersComponent_8)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.banner_imagens; _ck(_v, 3, 0, currVal_0); }, null); }
function View_BannersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "tile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannersComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannersComponent_5)), i1.ɵdid(7, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannersComponent_7)), i1.ɵdid(9, 16384, null, 0, i2.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], null, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.link_externo; _ck(_v, 3, 0, currVal_0); var currVal_1 = "s"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "n"; _ck(_v, 7, 0, currVal_2); }, null); }
function View_BannersComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 3).moveTo(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit == i1.ɵnov(_v.parent, 3).activePoint); _ck(_v, 0, 0, currVal_0); }); }
function View_BannersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "container"], ["style", "position: relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "ngu-carousel", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.pauseTimeout() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.resumeTimeout() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_NguCarousel_0, i4.RenderType_NguCarousel)), i1.ɵdid(3, 5750784, [[1, 4], ["carousel", 4]], 3, i5.NguCarousel, [i1.ElementRef, i1.Renderer2, i1.IterableDiffers, i1.PLATFORM_ID, i1.ChangeDetectorRef], { inputs: [0, "inputs"], dataSource: [1, "dataSource"] }, null), i1.ɵqud(603979776, 2, { _defDirec: 1 }), i1.ɵqud(335544320, 3, { nextBtn: 0 }), i1.ɵqud(335544320, 4, { prevBtn: 0 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_BannersComponent_2)), i1.ɵdid(8, 16384, [[2, 4]], 0, i5.ɵg, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(9, 0, null, 2, 3, "ul", [["NguCarouselPoint", ""], ["class", "point"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i5.ɵf, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannersComponent_9)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, [[4, 0]], 0, 2, "button", [["NguCarouselPrev", ""], ["class", "leftRs"]], null, null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i5.ɵe, [], null, null), (_l()(), i1.ɵted(-1, null, ["<"])), (_l()(), i1.ɵeld(16, 0, [[3, 0]], 1, 2, "button", [["NguCarouselNext", ""], ["class", "rightRs"]], null, null, null, null, null)), i1.ɵdid(17, 16384, null, 0, i5.ɵd, [], null, null), (_l()(), i1.ɵted(-1, null, [">"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.carouselConfig; var currVal_1 = _co.banners; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 3).pointNumbers; _ck(_v, 12, 0, currVal_2); }, null); }
export function View_BannersComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { carousel: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BannersComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show_banners; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BannersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banners", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BannersComponent_0, RenderType_BannersComponent)), i1.ɵdid(1, 245760, null, 0, i6.BannersComponent, [i7.BannerService, i8.ImageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BannersComponentNgFactory = i1.ɵccf("app-banners", i6.BannersComponent, View_BannersComponent_Host_0, {}, {}, []);
export { BannersComponentNgFactory as BannersComponentNgFactory };
