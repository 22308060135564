import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClienteService } from '../../clientes/shared/cliente.service';
import { LocalStorageService } from 'angular-2-local-storage';
import * as firebase from 'firebase/app';
import 'firebase/messaging';

@Injectable()
export class FirebaseService {
  public messaging: firebase.messaging.Messaging;

  constructor(
    private http: HttpClient,
    private clienteService: ClienteService,
    private localStorageService: LocalStorageService
  ) {}

  public initialize() {
    const appId = FILIAL.comunicacao.firebase_web_id;
    let app: any = false;
    if (appId) {
      app = firebase.initializeApp({
        apiKey: 'AIzaSyAhu0ynfx9VjvJlYEXT8FO2ZoZjhEpXUhw',
        authDomain: 'vipcommerce-5da29.firebaseapp.com',
        databaseURL: 'https://vipcommerce-5da29.firebaseio.com',
        projectId: 'vipcommerce-5da29',
        storageBucket: 'vipcommerce-5da29.appspot.com',
        messagingSenderId: '236048170419',
        appId
      });
      this.initializeFCM();
    }
    return app;
  }

  public initializeFCM() {
    this.messaging = firebase.messaging();
    navigator.serviceWorker.ready.then(swr => {
      if (swr) {
        navigator.serviceWorker.addEventListener('message', event => {
          this.onPostMessage(event);
        });
        this.messaging.useServiceWorker(swr);
        this.subscribeTokenRefresh();
        let browser = this.getBrowser();
        if (browser === 'Firefox') {
          this.requestPermissionOnClick();
        } else {
          this.checkPermission();
        }
      }
    });
  }

  private requestPermissionOnClick() {
    $('body').on('click', () => {
      this.checkPermission();
      $('body').off('click');
    });
  }

  private getBrowser() {
    var userAgent = navigator.userAgent;
    if (userAgent.indexOf('Chrome') != -1) return 'Chrome';
    if (userAgent.indexOf('Safari') != -1) return 'Safari';
    if (userAgent.indexOf('Firefox') != -1) return 'Firefox';
    if (userAgent.indexOf('Opera') != -1) return 'Opera';
    if (userAgent.indexOf('OPR') != -1) return 'Opera';
    if (userAgent.indexOf('Edge') != -1) return 'Edge';
    if (userAgent.indexOf('MSIE') != -1) return 'Explorer';
    return false;
  }
  private checkPermission() {
    if (Notification.permission !== 'denied') {
      this.getAndSaveToken().then((token: any) => {
        if (token) {
          this.subscribeLoginLogout();
          if (this.clienteService.logged()) {
            this.linkToken(token);
          }
        }
      });
    }
  }

  private subscribeTokenRefresh() {
    this.messaging.onTokenRefresh(() => {
      this.getAndSaveToken();
    });
  }

  public getAndSaveToken() {
    return this.messaging
      .getToken()
      .then((token: any) => {
        if (token) {
          return this.saveToken(token);
        }
        return false;
      })
      .catch(error => {
        console.log('Notifications have been blocked.');
      });
  }

  public subscribeLoginLogout() {
    this.clienteService.afterLogin$.subscribe(() => {
      this.messaging.getToken().then(token => {
        this.linkToken(token);
      });
    });
    this.clienteService.afterLogout$.subscribe(() => {
      this.messaging.getToken().then(token => {
        this.unlinkToken(token);
      });
    });
  }

  private onPostMessage(event) {
    const message = event && event.data ? event.data : {};
    switch (message.type) {
      case 'NOTIFICATION_READ':
        if (message.data && message.data.id)
          return this.setNotificationRead(message.data.id);
    }
  }

  public saveToken(token: any) {
    let body = {
      token,
      platform: 'web'
    };
    return this.http
      .post(`/notificacoes/save_token`, body)
      .toPromise()
      .then((callback: any) => {
        if (callback && callback.success) {
          this.localStorageService.set('webPushToken', token);
          return token;
        }
      });
  }

  private linkToken(token: string) {
    let body = {
      registrationId: token,
      plataforma: 'web'
    };
    return this.http.post('/clientes/registrar-dispositivo', body).toPromise();
  }

  private unlinkToken(token: string) {
    let body = {
      registrationId: token,
      plataforma: 'web'
    };
    return this.http.post('/clientes/remover-dispositivo', body).toPromise();
  }

  private setNotificationRead(notificationId) {
    let body = { notificationId };
    if (this.clienteService.logged()) {
      return this.http
        .post('/notificacoes/set_notification_read_by_client', body)
        .toPromise();
    } else {
      return this.http
        .post('/notificacoes/set_notification_read', body)
        .toPromise();
    }
  }
}
