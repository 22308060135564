import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class MinhasListasService {
  public selectedId: number;
  public service: string = '/minha-listas';
  public sharedService: string = '/minha-lista-clientes';
  public productService: string = '/minha-lista-produtos';
  public invitedService: string = '/minha-lista-convidados';
  public carrinhoFacilService: string = '/minha-lista-carrinho-facil';

  constructor(private httpClient: HttpClient) {}

  public get(method?: string, page?: string): Promise<Object> {
    let url: string = this.service;
    let params: any = {};
    if (method) {
      url += '/' + method;
    }
    if (page) {
      params.page = page;
    }
    return this.httpClient.get(url, { params }).toPromise();
  }

  public getAll() {
    return this.httpClient.get(this.service + '/all').toPromise();
  }

  public delete(id: number) {
    return this.httpClient.delete(this.service + '/delete/' + id).toPromise();
  }

  public edit(id: number, nome: string) {
    return this.httpClient
      .post(this.service + '/edit/' + id, { nome })
      .toPromise();
  }

  public add(nome: string) {
    return this.httpClient.post(this.service + '/add', { nome }).toPromise();
  }

  public exit(id: number) {
    return this.httpClient
      .delete(this.sharedService + '/exit/' + id)
      .toPromise();
  }

  public deleteProduct(id: string) {
    return this.httpClient
      .delete(this.productService + '/delete/' + id)
      .toPromise();
  }

  public editProduct(id: string, quantidade: number) {
    return this.httpClient
      .post(this.productService + '/edit/' + id, { quantidade })
      .toPromise();
  }

  public addProduct(product: any) {
    return this.httpClient
      .post(this.productService + '/add', product)
      .toPromise();
  }

  public addMultiProducts(body: any) {
    return this.httpClient
      .post(this.productService + '/addMulti', body)
      .toPromise();
  }

  public addToCart(produtos: any) {
    return this.httpClient
      .post(this.service + '/addToCart', { produtos })
      .toPromise();
  }

  public getUsers(id: any) {
    return this.httpClient.get(this.sharedService + '/users/' + id).toPromise();
  }

  public getInvitedUsers(id: any) {
    return this.httpClient
      .get(this.invitedService + '/invitedUsers/' + id)
      .toPromise();
  }

  public deleteUser(id: any) {
    return this.httpClient
      .delete(this.sharedService + '/delete/' + id)
      .toPromise();
  }

  public deleteInvitedUser(id: any) {
    return this.httpClient
      .delete(this.invitedService + '/delete/' + id)
      .toPromise();
  }

  public getListaProdutosCarrinhoFacil(id: string) {
    return this.httpClient.get(`${this.carrinhoFacilService}/items/${id}`);
  }

  buscaMinhasListas(cdId: number) {
    const URI = `listas/personalizadas/filial/${environment.filialId}/centro_distribuicao/${cdId}/minhas_listas`;
    return this.httpClient.get<any>(`${environment.apiUrl}/loja/${URI}`);
  }

  buscaListasCompartilhadas(cdId: number) {
    const URI = `listas/personalizadas/filial/${environment.filialId}/centro_distribuicao/${cdId}/compartilhadas`;
    return this.httpClient.get<any>(`${environment.apiUrl}/loja/${URI}`);
  }

  buscaInfoLista(listaId: number, cdId: number) {
    const URI = `listas/personalizadas/${listaId}/filial/${environment.filialId}/centro_distribuicao/${cdId}`;
    return this.httpClient.get<any>(`${environment.apiUrl}/loja/${URI}`);
  }

  convertParams(params) {
    if (!params || Object.keys(params).length === 0) {
      return '';
    }

    const queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    return `?${queryString}`;
  }

  buscaItensLista(listaId: number, cdId: number, params?) {
    const query = this.convertParams(params);
    const URI = `listas/personalizadas/${listaId}/filial/${environment.filialId}/centro_distribuicao/${cdId}/itens`;
    return this.httpClient.get<any>(`${environment.apiUrl}/loja/${URI}${query}`);
  }
}
