<div>
    <div class="modal-header">
        <h4 class="modal-title text-center">Atenção</h4>
    </div>
    <div class="modal-body">
        <div [innerHtml]="tipoEntregaService.disponibilidade.textoPrevisaoEntrega"></div>
        <div class="tipo-entrega-itens">
            <div *ngFor="let tipoEntrega of tipoEntregaService.disponibilidade.tipoEntregaDisponibilidade;">
                <span class="tipo-entrega-label">• {{tipoEntrega.tipo_entrega.descricao}}:  </span>
                <span>{{tipoEntrega.proxima_data.substr(0,19) | date: 'dd/MM/yyyy' }}</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="close()">Continuar</button>
    </div>
</div>
