import { Injectable } from '@angular/core';
import { Texto } from './textos';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class TextoService {

    private _id: number;
    private _slug: string;
    public service: string = '/textos';
    public textos: Texto[] = [];
    public texto: Texto = <Texto> {};

    constructor(private httpClient: HttpClient) {
    }

    getId(): number {
        return this._id;
    }

    setId(value: number) {
        this._id = value;
    }

    getSlug(): string {
        return this._slug;
    }

    setSlug(value: string) {
        this._slug = value;
    }

    view(id: number) {
        return this.httpClient.get(this.service + '/view/' + environment.filialId + '/' + id).toPromise()
            .then((data: any) => this.texto = data.texto);
    }

    detalhe() {
        return this.httpClient.get(this.service + '/view/' + environment.filialId + '/' + this.getId()).toPromise()
            .then((data: any) => this.texto = data.texto);
    }

    slug() {
        return this.httpClient.get(this.service + '/slug/' + this.getSlug()).toPromise()
            .then((data: any) => this.texto = data.texto);
    }

}
