import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';

@Component({
  templateUrl: './observacao-produto-modal.component.html',
  styleUrls: ['./observacao-produto-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ObservacaoProdutoModalComponent {

  @Input() descricao: string;
  @Input() observacao: string;

  constructor(private modalService: BsModalService) {}

  fechar() {
    this.modalService._hideModal(1);
  }

}
