import { MinhasListasService } from './../../../shared/services/minhas-listas.service';
import { BsModalService } from 'ngx-bootstrap';
import { MinhasListas } from './../../interfaces/minhas-listas';
import { Component, OnInit } from '@angular/core';

@Component({
  styleUrls: ['./minhas-listas-edit.component.css'],
  templateUrl: './minhas-listas-edit.component.html'
})
export class MinhasListasEditComponent implements OnInit {
  public list: MinhasListas = <MinhasListas>{};
  public title: string;
  public edit: boolean = false;
  public new: boolean = false;
  public modalLevel: number = 1;
  public onHide: Function;
  public showSuccessMessage: boolean = true;

  public constructor(
    private modalService: BsModalService,
    private minhasListasService: MinhasListasService
  ) {}

  public ngOnInit() {}

  public close(response?: any) {
    this.modalService._hideModal(this.modalLevel);
    this.onHide(response);
  }

  public save() {
    let promise: Promise<any>;
    if (this.new) {
      promise = this.minhasListasService.add(this.list.nome);
    } else {
      promise = this.minhasListasService.edit(
        this.list.lista_id,
        this.list.nome
      );
    }
    promise.then((response: any) => {
      if (response.success) {
        this.close(response);
      }
      if (this.showSuccessMessage || !response.success) {
        alert(response.message);
      }
    });
  }
}
