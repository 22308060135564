<div class="spinner clearfix {{ class == 1 ? 'pull-left' : 'pull-right' }}">
  <div class="pull-left spinner-info" *ngIf="preco_venda">
    {{ preco_venda | price }}
  </div>
  <div class="iterator pull-left">
    <div
      class="fa fa-caret-up pull-left"
      aria-hidden="true"
      (click)="mais()"
    ></div>
    <div
      class="fa fa-caret-down pull-left"
      aria-hidden="true"
      (click)="menos()"
    ></div>
  </div>
  <div class="input pull-left">
    <input
      type="number"
      min="1"
      max="9999"
      class="spinner-number"
      (change)="atual()"
      (keyup.enter)="comprar()"
      [(ngModel)]="quantidade"
    />
  </div>
  <div class="pull-right spinner-info" *ngIf="total_preco">
    {{ total_preco | price }}
  </div>
</div>
