<ng-template #card let-produtos>
  <ng-container *ngFor="let produto of produtos; let i = index">
    <div
      class="col-xs-6 col-sm-6 col-md-6 col-lg-6 product {{
        produto.classe_oferta
      }}"
    >
      <div class="thumbnail ">
        <div class="border-promotion">
          <app-tag-promotion [oferta]="produto?.oferta"></app-tag-promotion>

          <app-produto-imagem
            [produto]="produto"
            [tamanho]="144"
            [classe]="'center-block image-product'"
            preventLink="true"
          ></app-produto-imagem>

          <app-tag-preco
            *ngIf="produto.disponivel"
            [produto]="produto"
          ></app-tag-preco>

          <div class="clearfix">&nbsp;</div>

          <div class="caption">
            <p
              class="text-success description center-block text-center hidden-xs"
            >
              <a title="{{ produto.descricao }}">{{
                produto.descricao | slice: 0:50
              }}</a>
            </p>
            <p
              class="text-success description center-block text-center visible-xs"
            >
              <a title="{{ produto.descricao }}">{{
                produto.descricao | slice: 0:35
              }}</a>
            </p>
            <div class="select-btn">
              <button
                type="button"
                (click)="select(produto)"
                class="btn btn-primary button"
              >
                <span>SELECIONAR</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<div class="modal-minhas-listas-replace-options">
  <i
    class="fa fa-close modal-minhas-listas-replace-options__close"
    aria-hidden="true"
    (click)="close()"
  ></i>
  <header class="title">
    <h4>{{ title }}</h4>
    <h5>Selecione um dos produtos abaixo</h5>
  </header>
  <section>
    <div>
      <div class="row vip-products">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <ng-container *ngIf="(compare$ | async)?.data; let produtos">
              <ng-container
                *ngTemplateOutlet="card; context: { $implicit: produtos }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="(similares$ | async)?.data; let produtos">
              <ng-container
                *ngTemplateOutlet="card; context: { $implicit: produtos }"
              ></ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
