import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { CashBackExtratoResponse } from './CashbackExtratoResponse';
import { CashbackSaldoResponse } from './CashbackSaldoResponse';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CashBackResponse } from './interfaces/CashBackResponse';

@Injectable()
export class MinhaContaCashbackService {
  public service = '/cashback';

  private needUpdateData: BehaviorSubject<boolean>;

  constructor(private httpClient: HttpClient) {
    this.needUpdateData = new BehaviorSubject<boolean>(false);
  }

  setNeedUpdateValue(newValue): void {
    this.needUpdateData.next(newValue);
  }

  getNeedUpdateValue(): Observable<boolean> {
    return this.needUpdateData.asObservable();
  }

  saldo(): Observable<CashbackSaldoResponse> {
    const client = this.httpClient.get<CashbackSaldoResponse>(
      `${this.service}/saldo/`
    );
    this.setNeedUpdateValue(false);
    return client;
  }

  extrato(): Observable<CashBackExtratoResponse> {
    return this.httpClient.get<CashBackExtratoResponse>(
      `${this.service}/extrato/`
    );
  }

  async alterarSenha(novaSenha: string): Promise<CashBackResponse> {
    return this.httpClient
      .post<CashBackResponse>(`${this.service}/alterar-senha/`, {
        nova_senha: novaSenha,
      })
      .toPromise();
  }

  utilizarSaldo(compraId, valorUtilizado): Observable<CashBackResponse> {
    return this.httpClient.post<CashBackResponse>(
      `${this.service}/utilizar-saldo/`,
      {
        compra_id: compraId,
        vlr_utilizado: valorUtilizado,
      }
    );
  }
}
