import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { TipoEntregaService } from '../shared/tipo-entrega.service';

@Component({
    styleUrls: ['./previsao-entrega.component.css'],
    templateUrl: './previsao-entrega.component.html'
})
export class PrevisaoEntregaComponent implements OnInit {

    public constructor(
        private modalService: BsModalService,
        public tipoEntregaService: TipoEntregaService
    ) {}

    public ngOnInit() {}

    public close() {
        this.modalService._hideModal(1);
    }
}
