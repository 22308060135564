<div class="form-group">
  <ng-container *ngIf="!customLabel">
    <label for="cep">Informe seu CEP</label>
    <a
      href="https://buscacepinter.correios.com.br/app/endereco/index.php"
      target="_blank"
      class="pull-right"
      >Não sei meu CEP
      <i
        class="fa fa-external-link"
        aria-hidden="true"
        style="font-size: 10px"
      ></i
    ></a>
  </ng-container>

  <input
    type="text"
    id="cep"
    placeholder="CEP"
    [(ngModel)]="cep"
    (ngModelChange)="change()"
    class="form-control"
    name="cep"
    [mask]="'99999-999'"
    maxlength="9"
    required
  />
</div>
