import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TabsBar } from './shared/tabs-bar';
import { TabsBarService } from './shared/tabs-bar.service';

@Component({
    selector: 'app-tabs-bar',
    templateUrl: './tabs-bar.component.html',
    styleUrls: ['./tabs-bar.component.css']
})
export class TabsBarComponent implements OnInit {
    public filter: boolean = false;
    public show_tabs_bar: boolean = false;
    public links: TabsBar[];
    public categorias: any;
    public departamento: any;
    public marcas: any = [];
    public marcaAtiva: any = null;

    constructor(
        private tabsBarService: TabsBarService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.tabsBarService.alteraLinks$.subscribe(links => {
            this.links = links;
        });

        this.tabsBarService.visibilidade$.subscribe(visibilidade => {
            this.show_tabs_bar = visibilidade;
        });

        this.tabsBarService.filtro$.subscribe(filtrar => {
            this.filter = filtrar;
        });

        this.tabsBarService.alteraMarcas$.subscribe(marcas => {
            this.marcas = marcas;
            this.associarMarcaAtiva();
        });

        this.links = [{ titulo: 'Página inicial' }];

        this.router.events.subscribe(data => {
            if (data instanceof NavigationEnd) {
                this.marcaAtiva = null;
            }
        });

        this.associarMarcaAtiva();
    }

    associarMarcaAtiva() {
        this.route.queryParams.subscribe((queryParams: any) => {
            if ('marca' in queryParams && this.marcas instanceof Array) {
                for (let i = 0; i < this.marcas.length; i++) {
                    if (this.marcas[i].slug == queryParams['marca']) {
                        this.marcaAtiva = this.marcas[i];
                        break;
                    }
                }
            }
        });
    }

    navegar(pageParams?: any, reset?: any) {
        this.route.queryParams
            .subscribe((params: any) => {
                let queryParams = Object.assign({}, params, pageParams);
                if (reset != undefined) {
                    this.router.navigate([], {
                        queryParams:
                            params.q != undefined ? { page: 1, q: params.q } : params.tagId != undefined ? { tagId: params.tagId, page: 1 } : { page: 1 }
                    });
                } else {
                    this.router.navigate([], { queryParams: queryParams });
                }
            })
            .unsubscribe();
    }
}
