import { ProdutosPatrocinadosService } from './produtos-patrocinados.service';
import * as i0 from "@angular/core";
import * as i1 from "./produtos-patrocinados.service";
export class CampanhaPatrocinadoService {
    constructor(produtosPatrocinadosService) {
        this.produtosPatrocinadosService = produtosPatrocinadosService;
        this.produtosPatrocinadosService.adsAtivo$.subscribe((adsAtivo) => {
            this.adsAtivo = adsAtivo;
        });
    }
    clique(produto) {
        if (this.adsAtivo &&
            produto.anunciado &&
            produto.urls_eventos_ads &&
            produto.urls_eventos_ads.url_click) {
            const url = produto.urls_eventos_ads.url_click;
            navigator.sendBeacon(url);
        }
    }
    impressao(produtos) {
        if (this.adsAtivo)
            produtos.forEach((produto) => {
                if (produto.anunciado &&
                    produto.urls_eventos_ads &&
                    produto.urls_eventos_ads.url_impressao) {
                    const url = produto.urls_eventos_ads.url_impressao;
                    navigator.sendBeacon(url);
                }
            });
    }
    visualizarProduto(produto) {
        if (this.adsAtivo &&
            produto.anunciado &&
            produto.urls_eventos_ads &&
            produto.urls_eventos_ads.url_visualizacao) {
            const url = produto.urls_eventos_ads.url_visualizacao;
            navigator.sendBeacon(url);
        }
    }
}
CampanhaPatrocinadoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CampanhaPatrocinadoService_Factory() { return new CampanhaPatrocinadoService(i0.ɵɵinject(i1.ProdutosPatrocinadosService)); }, token: CampanhaPatrocinadoService, providedIn: "root" });
