import { Injectable } from '@angular/core';
import { Receita } from './receitas';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReceitaService {
  public service: string = '/receitas';

  public receitas: Receita[] = [];
  public receita: Receita = <Receita>{};

  constructor(private httpClient: HttpClient) {}

  index() {
    return this.httpClient
      .get(this.service + '/index/' + environment.filialId)
      .toPromise()
      .then((data: any) => {
        this.receitas = data.receitas;
        window.dispatchEvent(new Event('atualizarReceitas'));
      });
  }

  view(slug: string) {
    return this.httpClient
      .get(this.service + '/view/' + environment.filialId + '/' + slug)
      .toPromise()
      .then((data: any) => {
        this.receita = data.receita;
      });
  }

  receitaPorId(receitaId: number): Observable<{ data: Receita }> {
    const URI = `receitas/${receitaId}`;
    return this.httpClient.get<{ data: Receita }>(
      `${environment.apiUrl}/loja/${URI}`
    );
  }
}
